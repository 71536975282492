import { BaseDto } from "../baseDto"
/* OrganisationResourceBaseDto generated at 2025-01-13 11:53:04.840 +11:00*/
export class OrganisationResourceBaseDto extends BaseDto {
	OrganisationResourceId?: string;
	OrganisationId?: string;
	OrganisationNo?: string;
	OrganisationName?: string;
	ResourceName?: string;
	SystemResourceType?: string;
	ResourceAction?: string;
	SystemResourceId?: string;
	EnvironmentCode?: string;
	VersionNo?: number;
	ParamDomainTypeName?: string;
	ParamValueJSON?: string;
	Message?: string;
	StatusCode?: string;
}
