import { BaseDto } from "../baseDto"
/* DbResourceVersionBaseDto generated at 2025-01-13 11:53:04.690 +11:00*/
export class DbResourceVersionBaseDto extends BaseDto {
	DbResourceVersionId?: string;
	SystemResourceId?: string;
	ResourceDbType?: string;
	Version?: number;
	ApplyDate?: Date;
	DateCompleted?: Date;
	UpgradeDbScript?: string;
	StatusCode?: string;
}
