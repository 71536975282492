import { BaseDto } from "../baseDto"
/* WARLProgramBaseDto generated at 2025-01-13 11:53:05.131 +11:00*/
export class WARLProgramBaseDto extends BaseDto {
	WebARLProgramId?: string;
	ProgramCode?: string;
	Topic?: string;
	ProgramDescription?: string;
	ProgramStartDate?: Date;
	ProgramEndDate?: Date;
	CommentStartDate?: Date;
	CommentEndDate?: Date;
	Material?: string;
	ProgramRegion?: string;
	ProgramStatus?: string;
	StatusCode?: string;
}
