import { BaseDto } from "../baseDto"
/* OrganisationActivityBaseDto generated at 2025-01-13 11:53:04.817 +11:00*/
export class OrganisationActivityBaseDto extends BaseDto {
	OrganisationActivityId?: string;
	OrganisationId?: string;
	ActivityPeriod?: Date;
	RequestedProject?: number;
	RequestedCmpnt?: number;
	CompleteProject?: number;
	CompleteCmpnt?: number;
	IncompleteProject?: number;
	IncompleteCmpnt?: number;
	ErrorProject?: number;
	RequestedDownloadProject?: number;
	RequestedDownloadCmpnt?: number;
	FirstDownloadProject?: number;
	FirstDownloadCmpnt?: number;
	SubDownloadProject?: number;
	SubDownloadCmpnt?: number;
}
