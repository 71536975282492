import { BaseDto } from "../baseDto"
/* InboundDataArchiveBaseDto generated at 2025-01-13 11:53:04.765 +11:00*/
export class InboundDataArchiveBaseDto extends BaseDto {
	InboundDataArchiveId?: string;
	InboundDataId?: string;
	ARLFileId?: string;
	DataClassificationCode?: string;
	ReferenceId1?: string;
	ReferenceId2?: string;
	ReferenceId3?: string;
	SequenceNo?: number;
	FileData?: string;
	ValidationErrors?: string;
	ErrorMessage?: string;
	DateProcessed?: Date;
	StatusCode?: string;
}
