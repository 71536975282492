import { BaseDto } from "../baseDto"
/* ARLFileBaseDto generated at 2025-01-13 11:53:04.656 +11:00*/
export class ARLFileBaseDto extends BaseDto {
	ARLFileId?: string;
	ARLM_FileId?: string;
	FileClassificationCode?: string;
	OriginalFilename?: string;
	Filename?: string;
	FileURL?: string;
	SystemResourceId?: string;
	OrganisationId?: string;
	ProductId?: string;
	ProductComponentId?: string;
	ProjectId?: string;
	ProjectItemId?: string;
	ARLAssessmentReportId?: string;
	VersionNo?: number;
	FileData?: string;
	StatusCode?: string;
}
