import { Injectable, OnDestroy } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { ActionResult, ApplicationInfo, Guid, SignalRMessageDto } from "../../model/shared";
import { environment } from 'src/environments/environment';
import { DomService } from "./dom.service";
import { UtilityService } from "./utility.service";
import * as signalR from "@microsoft/signalr";
import { ApiService } from "./api.service";
import { HttpParams } from "@angular/common/http";
@Injectable({
    providedIn: "root"
})
export class SignalRService implements OnDestroy {
    private utilSvc: UtilityService = new UtilityService();
    private appInfo: ApplicationInfo = new ApplicationInfo();
    private domSvc: DomService = new DomService();
    private hubConnection?: signalR.HubConnection;
    private enabled: boolean = false;
    private connected: boolean = false;
    private setConnectionClosed: boolean = false;

    public isStopSignalR: boolean = false;
    public onConnected = new Subject<any>();
    public onDisconnected = new Subject<any>();
    public onReceivedMessage = new Subject<any>();
    public onSendMessageError = new Subject<any>();
    public onIsAuthenticated = new Subject<boolean>();
    public contextInstance: string = "";

    private delayConnectTimer: any = null;
    private readyToConnectSubscription: any;
    private readyToConnectSubject = new Subject<boolean>();
    private isStarting: boolean = false;

    private delaySendTimer: any = null;
    private msgQueue: SignalRMessageDto[] = [];
    private delayReceivedTimer: any = null;
    private receivedMsgQueue: SignalRMessageDto[] = [];
    constructor(public apiSvc: ApiService) {
        try {
            this.appInfo = environment;
            this.readyToConnectSubscription = this.readyToConnectSubject.subscribe(result1 => {
                if (this.Enabled) {
                    this.Connect().subscribe(result => {
                        this.connected = true;
                        this.onConnected.next(result);
                    }, err => {
                        this.ConnectionClosed();
                    });
                }
            }, err => {
                console.log("onReadyToConnect ", err);
            });
            this.DelaySendMessage();
            this.DelayReceivedMessage();
        }
        catch (e) {
            console.error(e);
        }
    }
    ngOnDestroy() {
        try {
            if (this.readyToConnectSubscription) {
                this.readyToConnectSubscription.unsubscribe();
            }
        } catch (ex) {
            console.error(ex);
        }
    }
    get IsConnected(): boolean {
        return this.connected;
    }
    /*  @summary Defines what happens when this service is enabled/disabled 
    */
    get Enabled(): boolean {
        if (this.enabled) {
            return true;
        }
        else {
            return false;
        }
    }
    set Enabled(value: boolean) {
        if (this.enabled !== value) {
            if (value) {
                this.enabled = true;
                this.readyToConnectSubject.next(true);
            } else {
                this.enabled = false;
                this.Stop();
                try {
                    this.ConnectionClosed();
                } catch (ex) { }
            }
        }
    }
    private ConnectionClosed() {
        try {
            this.connected = false;
            this.onDisconnected.next(true);
        }
        catch (e) {
            console.error("Connection close error", e);
        }
        /*  If the enabled flag is true, delay and try connecting again.
            To fully disconnect set enabled flag to false.
        */
        if (this.Enabled) this.DelayConnection();
    }
    private Connect(): Observable<any> {
        let that = this;
        return new Observable(observer => {
            try {
                this.hubConnection = new signalR.HubConnectionBuilder().withUrl(this.appInfo.SignalService, { httpClient: new CustomHttpClient() }).build();
                //this.hubConnection = new signalR.HubConnectionBuilder().withUrl(this.appInfo.SignalService).build();
                /*  Specify / override the SignalR Closed connection function if the server stopped. */
                if (!this.setConnectionClosed) {
                    this.hubConnection.onclose(closedEvent => {
                        that.ConnectionClosed();
                    });
                    this.setConnectionClosed = true;
                }
                if (!this.isStarting) {
                    this.isStarting = true;
                    this.hubConnection.start().then(function (item: any) {
                        try {
                            that.isStopSignalR = false;
                            that.isStarting = false;
                            that.connected = true;
                            that.hubConnection?.on("ReceivedServerMessage", (data: any) => { that.ReceivedServerMessage(data); });
                            that.SendMessage(Guid.newGuid(), "Connection", "Connected", null).subscribe(resp => {
                                that.domSvc.LastConnectionDate = new Date();
                                observer.next(item);
                            }, error => {
                                console.log('Error when sending connected message: ' + error);
                                observer.error(error);
                            });
                        } catch (e) {
                            console.log('Error on start connection: ', e);
                            that.ConnectionClosed();
                            observer.error(e);
                        }
                    }).catch(function (startErr: any) {
                        that.isStarting = false;
                        console.log('Error while starting connection: ' + startErr);
                        observer.error(startErr);
                    });
                } else {
                    this.ConnectionClosed();
                    observer.error("Is Starting");
                }
            } catch (ex) {
                this.isStarting = false;
                this.ConnectionClosed();
                observer.error(ex);
            }
        });
    }
    /*  @summary Stop and trigger ConnectionClosed */
    Disconnect() {
        try {
            this.connected = false;
            this.Stop();
            this.ConnectionClosed();
        }
        catch (e) { }
    }
    private Stop() {
        this.isStopSignalR = true;
        if (this.hubConnection) {
            this.hubConnection?.stop();
        }
    }

    /*  @summary Try to connect later. */
    private DelayConnection() {
        let that = this;
        if (this.delayConnectTimer) {
            clearTimeout(this.delayConnectTimer);
            this.delayConnectTimer = undefined;
        }
        if (this.Enabled) {
            this.delayConnectTimer = setTimeout(function () {
                if (that.delayConnectTimer) {
                    clearTimeout(that.delayConnectTimer);
                    that.delayConnectTimer = undefined;
                    that.readyToConnectSubject.next(true);
                }
            }, 5000);
        }
    }
    SendMessage(clientMessageId: string = "", messageType: string = "", action: string = "", data: any = null): Observable<SignalRMessageDto> {
        let that = this;
        return new Observable(observer => {
            try {
                var lvPath = "";
                if (location.href) {
                    lvPath = location.href;
                }
                var sm = new SignalRMessageDto({
                    RT: this.domSvc.RefreshToken, CMD: clientMessageId,
                    MT: messageType, AT: action, MD: new Date(),
                    Data: JSON.stringify(data), EMsg: ""
                });
                if (messageType=="Connection" && action == "Ticks" && this.msgQueue && this.msgQueue.length) {
                    this.msgQueue = this.msgQueue.filter(x => !(x.MT == "Connection" && x.AT == "Ticks"));
                }
                this.msgQueue.push(sm);
                observer.next(sm);
            } catch (e) {
                observer.error(e);
            }
        });
    };
    private DelaySendMessage() {
        let that = this;
        if (this.delaySendTimer) {
            clearTimeout(this.delaySendTimer);
            this.delaySendTimer = undefined;
        }
        this.delaySendTimer = setTimeout(function () {
            if (that.delaySendTimer) {
                clearTimeout(that.delaySendTimer);
                that.delaySendTimer = undefined;
                that.SendMessageFromQueue();
            }
        }, 500);
    }
    async SendMessageFromQueue() {
        var BreakException = {};
        try {
            var localQueue: SignalRMessageDto[] = [];
            if (this.contextInstance == "ANZPAC" && this.domSvc.ANZPACSRMessage) {
                try {
                    localQueue = JSON.parse(this.domSvc.ANZPACSRMessage);
                } catch (er) { }
                if (this.msgQueue && this.msgQueue.length) {
                    this.msgQueue.forEach(async x => {
                        localQueue = localQueue.filter(y => y.CMD != x.CMD);
                        localQueue.push(x);
                    });
                }
                this.msgQueue = localQueue;
            } else if (this.contextInstance == "Admin" && this.domSvc.AdminSRMessage) {
                try {
                    localQueue = JSON.parse(this.domSvc.AdminSRMessage);
                } catch (er) { }
                if (this.msgQueue && this.msgQueue.length) {
                    this.msgQueue.forEach(async x => {
                        localQueue = localQueue.filter(y => y.CMD != x.CMD);
                        localQueue.push(x);
                    });
                }
                this.msgQueue = localQueue;
            } else if (this.domSvc.SRMessage) {
                try {
                    localQueue = JSON.parse(this.domSvc.SRMessage);
                } catch (er) { }
                if (this.msgQueue && this.msgQueue.length) {
                    this.msgQueue.forEach(async x => {
                        localQueue = localQueue.filter(y => y.CMD != x.CMD);
                        if (x.MT == "Connection" && x.AT == "Ticks") {
                            localQueue = localQueue.filter(y => !(y.MT == "Connection" && y.AT == "Ticks"));
                        }
                        if (x.MT == "Connection" && x.AT == "Connected") {
                            localQueue = localQueue.filter(y => !(y.MT == "Connection" && y.AT == "Connected"));
                        }
                        localQueue.push(x);
                    });
                }
                this.msgQueue = localQueue;
            }
            if (this.msgQueue && this.msgQueue.length) {
                this.msgQueue.forEach(async x => {
                    if (!x.IsSent) {
                        x.RT = this.domSvc.RefreshToken;
                        x = <SignalRMessageDto>await this.ActualSendMessage(x);
                        if (!x.IsSent) {
                            x = <SignalRMessageDto>await this.SendMessageOverHttp(x);
                            if (!x.IsSent) {
                                throw BreakException;
                            }
                        }
                    }
                });
            }
        } catch (e) {
            console.log("Queue", e);
        }
        try {
            if (this.msgQueue && this.msgQueue.length) {
                this.msgQueue.forEach(x => {
                    if (x.IsSent) {
                        x.IsEventTriggered = false;
                        this.receivedMsgQueue = this.receivedMsgQueue.filter(y => y.CMD != x.CMD);
                        this.receivedMsgQueue.push(x);
                    }
                });
                this.msgQueue = this.msgQueue.filter(x => !x.IsSent);
                if (this.contextInstance == "ANZPAC") {
                    if (this.msgQueue && this.msgQueue.length) {
                        this.domSvc.ANZPACSRMessage = JSON.stringify(this.msgQueue);
                    } else {
                        this.domSvc.ANZPACSRMessage = "";
                    }
                } else if (this.contextInstance == "Admin") {
                    if (this.msgQueue && this.msgQueue.length) {
                        this.domSvc.AdminSRMessage = JSON.stringify(this.msgQueue);
                    } else {
                        this.domSvc.AdminSRMessage = "";
                    }
                } else {
                    if (this.msgQueue && this.msgQueue.length) {
                        this.domSvc.SRMessage = JSON.stringify(this.msgQueue);
                    } else {
                        this.domSvc.SRMessage = "";
                    }
                }
            }
        } catch (e) {
            console.log("Clean", e);
        }
        this.DelaySendMessage();
    }
    async ActualSendMessage(sm: SignalRMessageDto) {
        let that = this;
        return new Promise((resolve, reject) => {
            try {
                if (that.connected) {
                    that.hubConnection?.invoke("SendMessage", sm).then((resp: any) => {
                        try {
                            if (resp && sm) {
                                sm.EMsg = resp.eMsg;
                                if (resp.isS) {
                                    sm.IsSent = true;
                                    sm.IsS = resp.isS;
                                    sm.RT = resp.rt;
                                    sm.CMD = resp.cmd;
                                    sm.MT = resp.mt;
                                    sm.AT = resp.at;
                                    sm.MD = resp.md;
                                    sm.Data = resp.data;
                                }
                                if (sm.MT != "Connection") {
                                    that.domSvc.LastMessageDate = new Date();
                                }
                                if (!resp.rt && sm.MT != "Connection") {
                                    that.onIsAuthenticated.next(false);
                                } else {
                                    that.onIsAuthenticated.next(true);
                                }
                            }
                        } catch (ex1) {
                            console.log("Sent", ex1);
                        }
                        resolve(sm);
                    }).catch((e1: any) => {
                        try {
                            if (sm) {
                                sm.EMsg = (e1) ? e1.message : "";
                                if (sm.EMsg == "Cannot send data if the connection is not in the 'Connected' State.") {
                                    that.Disconnect();
                                }
                                sm.IsSent = false;
                            }
                        } catch (ex2) {
                            console.log("Send", ex2);
                        }
                        that.onSendMessageError.next(sm);
                        resolve(sm);
                    });
                } else {
                    /* ConnectionId is not found. Disable Hub */
                    that.Disconnect();
                    if (sm) {
                        sm.EMsg = "Not connected";
                        sm.IsSent = false;
                    }
                    that.onSendMessageError.next(sm);
                    resolve(sm);
                }
            } catch (ex) {
                if (sm) {
                    sm.IsSent = false;
                }
                resolve(sm);
            }
        });
    }
    async SendMessageOverHttp(sm: SignalRMessageDto) {
        let that = this;
        return new Promise((resolve, reject) => {
            try {
                let httpParams: HttpParams = new HttpParams();
                that.apiSvc.post("/shared/SendMessage", sm, httpParams).subscribe((response: ActionResult) => {
                    let resp = response.Data as SignalRMessageDto;
                    sm.EMsg = resp.EMsg;
                    if (resp.IsS) {
                        sm.IsSent = true;
                        sm.IsS = resp.IsS;
                        sm.RT = resp.RT;
                        sm.CMD = resp.CMD;
                        sm.MT = resp.MT;
                        sm.AT = resp.AT;
                        sm.MD = resp.MD;
                        sm.Data = resp.Data;
                    }
                    if (sm.MT != "Connection") {
                        that.domSvc.LastMessageDate = new Date();
                    }
                    if (!resp.RT && sm.MT != "Connection") {
                        that.onIsAuthenticated.next(false);
                    } else {
                        that.onIsAuthenticated.next(true);
                    }
                    resolve(sm);
                }, respErr => {
                    if (sm) {
                        sm.IsSent = true;
                    }
                    resolve(sm);
                });
            } catch (ex) {
                if (sm) {
                    sm.IsSent = false;
                }
                resolve(sm);
            }
        });
    }
    private DelayReceivedMessage() {
        let that = this;
        if (this.delayReceivedTimer) {
            clearTimeout(this.delayReceivedTimer);
            this.delayReceivedTimer = undefined;
        }
        this.delayReceivedTimer = setTimeout(function () {
            if (that.delayReceivedTimer) {
                clearTimeout(that.delayReceivedTimer);
                that.delayReceivedTimer = undefined;
                that.SendReceivedMessageFromQueue();
            }
        }, 500);
    }
    async SendReceivedMessageFromQueue() {
        try {
            if (this.receivedMsgQueue && this.receivedMsgQueue.length) {
                this.receivedMsgQueue.forEach(x => {
                    if (!x.IsEventTriggered) {
                        this.ReceivedServerMessage(x);
                        x.IsEventTriggered = true;
                    }
                });
            }
        } catch (e) {
        }
        if (this.receivedMsgQueue && this.receivedMsgQueue.length) {
            this.receivedMsgQueue = this.receivedMsgQueue.filter(x => !x.IsEventTriggered);
        }
        this.DelayReceivedMessage();
    }
    /*  @summary Called by the server to send the message to the server user 
        when the server received a message from a client.
        Send a handshake in response to confirm received.
    */
    private ReceivedServerMessage(item: any) {
        let that = this;
        try {
            this.utilSvc.fixDates(item, (item.TMZ) ? item.TMZ : "UTC", true);
            that.onReceivedMessage.next(item);
        } catch (e) {
            this.onReceivedMessage.error(e);
        }
    }
}
export class CustomHttpClient extends signalR.DefaultHttpClient {
    private domSvc: DomService = new DomService();
    private utilSvc: UtilityService = new UtilityService();
    constructor() {
        super(console);
    }
    public override send(request: signalR.HttpRequest): Promise<signalR.HttpResponse> {
        if (this.utilSvc.dateSecondsDiff(new Date(), this.domSvc.Expires) > 0) {
            request.headers = { "AuthorizationToken": this.domSvc.RefreshToken };
        } else {
            //this.domSvc.credentialCleanUp();
        }
        return super.send(request);
    }
}