import { BaseDto } from "../baseDto"
/* WARLUpdateLogBaseDto generated at 2025-01-13 11:53:05.147 +11:00*/
export class WARLUpdateLogBaseDto extends BaseDto {
	WebARLUpdateLogId?: string;
	Material?: string;
	UpdateDescription?: string;
	ProgramRegion?: string;
	ProgramStatus?: string;
	GoLiveDate?: Date;
	DisplayGoLiveDateText?: string;
	PREPUpdateTimeline?: string;
	ArtworkUpdateTimeline?: string;
	StatusCode?: string;
}
