import { BaseDto } from "../baseDto"
/* ARLAdministrationBaseDto generated at 2025-01-13 11:53:04.636 +11:00*/
export class ARLAdministrationBaseDto extends BaseDto {
	ARLAdministrationId?: string;
	RequestSource?: string;
	EnvironmentCode?: string;
	DateRequested?: Date;
	DateCompleted?: Date;
	DateAdminRequested?: Date;
	DateAdminCompleted?: Date;
	OrganisationId?: string;
	ContactId?: string;
	PREP_OrganisationId?: number;
	PREP_UserId?: number;
	Org_ReferenceId?: string;
	AdminRequestJSON?: string;
	AdminResponseJSON?: string;
	ARLAdminRequestJSON?: string;
	ARLAdminResponseJSON?: string;
	ExceptionJSON?: string;
	RetryCount?: number;
	StatusCode?: string;
}
