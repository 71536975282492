import { BaseDto } from "../baseDto"
/* OrganisationActivityDetailBaseDto generated at 2025-01-13 11:53:04.823 +11:00*/
export class OrganisationActivityDetailBaseDto extends BaseDto {
	OrganisationActivityDetailId?: string;
	OrganisationActivityId?: string;
	OrganisationId?: string;
	ActivityDate?: Date;
	ActivityType?: string;
	ReferenceId?: string;
	ProjectId?: string;
	NoOfCmpnt?: number;
	PREP_ProjectId?: number;
	ProjectStatus?: string;
	ActionStatus?: string;
}
