import { BaseDto } from "../baseDto"
/* IpAddressRangeLocationBaseDto generated at 2025-01-13 11:53:04.790 +11:00*/
export class IpAddressRangeLocationBaseDto extends BaseDto {
	IpAddressRangeLocationId?: string;
	FromIpRange?: number;
	ToIpRange?: number;
	City?: string;
	Region?: string;
	PostCode?: string;
	CountryCode?: string;
	CountryName?: string;
	LatitudeValue?: number;
	LongitudeValue?: number;
	TimeOffset?: string;
	TimezoneCode?: string;
	TimezoneName?: string;
	StatusCode?: string;
}
