import { BaseDto } from "../baseDto"
/* SequenceNoBaseDto generated at 2025-01-13 11:53:04.988 +11:00*/
export class SequenceNoBaseDto extends BaseDto {
	SequenceNoId?: string;
	SequenceType?: string;
	ReferenceName?: string;
	LastSequenceNo?: number;
	Value?: string;
	StatusCode?: string;
}
