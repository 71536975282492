import { BaseDto } from "../baseDto"
/* ProjectAttributeBaseDto generated at 2025-01-13 11:53:04.909 +11:00*/
export class ProjectAttributeBaseDto extends BaseDto {
	ProjectAttributeId?: string;
	ProjectId?: string;
	ProjectItemId?: string;
	ProjectItemGroupId?: string;
	ObjectAttributeVersionId?: string;
	AttributeJSON?: string;
	StatusCode?: string;
}
