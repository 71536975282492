import { BaseDto } from "../baseDto"
/* SystemModelFieldBaseDto generated at 2025-01-13 11:53:05.090 +11:00*/
export class SystemModelFieldBaseDto extends BaseDto {
	SystemModelFieldId?: string;
	SystemModelId?: string;
	Sequence?: number;
	AttributeName?: string;
	DisplayName?: string;
	AttributeDescription?: string;
	DataType?: string;
	MaxLength?: number;
	Precision?: number;
	Scale?: number;
	IsNullable?: boolean;
	IsPrimaryKey?: boolean;
	IsUsed?: boolean;
	JSONUsed?: string;
	MapSystemModelId?: string;
	MapSystemModelFieldId?: string;
	MapAttributeName?: string;
	OrgDbVersion?: number;
	StatusCode?: string;
}
