import { BaseDto } from "../baseDto"
/* ProjectItemGroupBaseDto generated at 2025-01-13 11:53:04.930 +11:00*/
export class ProjectItemGroupBaseDto extends BaseDto {
	ProjectItemGroupId?: string;
	ProjItemGroupName?: string;
	ProjItemGroupDescription?: string;
	ProjectId?: string;
	Org_ProjectId?: string;
	Org_ProjectItemGroupId?: string;
	StatusCode?: string;
}
