import { BaseDto } from "../baseDto"
/* ARLOrganisationProjectBaseDto generated at 2025-01-13 11:53:04.656 +11:00*/
export class ARLOrganisationProjectBaseDto extends BaseDto {
	ARLOrganisationProjectId?: string;
	OrganisationId?: string;
	PREP_OrganisationId?: number;
	PREP_ProjectId?: number;
	ARLRequestId?: string;
	ARLProjectId?: string;
	DateRequested?: Date;
	DateCompleted?: Date;
	EnvironmentCode?: string;
	ProductMarkets?: string;
	IsArchived?: boolean;
	StatusCode?: string;
}
