import { BaseDto } from "../baseDto"
/* WARLSubmissionBaseDto generated at 2025-01-13 11:53:05.147 +11:00*/
export class WARLSubmissionBaseDto extends BaseDto {
	WebARLSubmissionId?: string;
	SubmissionNo?: string;
	SubmissionType?: string;
	Stakeholder?: string;
	SubmissionDescription?: string;
	SubmissionLongDesc?: string;
	SubmissionMaterial?: string;
	SubmissionRegion?: string;
	SubmissionStatus?: string;
	CommentStartDate?: Date;
	CommentEndDate?: Date;
	DateApproved?: Date;
	DisplayDateApprovedText?: string;
	DateExpiry?: Date;
	EmailContent?: string;
	FormURL?: string;
	SubmissionURL?: string;
	StatusCode?: string;
}
