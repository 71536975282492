import { BaseDto } from "../baseDto"
/* ARLAssessmentReportBaseDto generated at 2025-01-13 11:53:04.640 +11:00*/
export class ARLAssessmentReportBaseDto extends BaseDto {
	ARLAssessmentReportId?: string;
	ProjectId?: string;
	ProjectVersionNo?: number;
	AssessmentReportId?: number;
	AssessmentReportDate?: Date;
	AssessmentLocationCode?: string;
	PREP_LocationId?: number;
	ReportTypeCode?: string;
	AssessmentReport?: string;
	StatusCode?: string;
}
