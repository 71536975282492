import { BaseDto } from "../baseDto"
/* ObjectAttributeVersionBaseDto generated at 2025-01-13 11:53:04.806 +11:00*/
export class ObjectAttributeVersionBaseDto extends BaseDto {
	ObjectAttributeVersionId?: string;
	ObjectAttributeId?: string;
	ObjAttrVersionNo?: number;
	ObjAttrJSON?: string;
	IsUsed?: boolean;
	StatusCode?: string;
}
