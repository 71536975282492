import { BaseDto } from "../baseDto"
/* RegisteredFileBaseDto generated at 2025-01-13 11:53:04.957 +11:00*/
export class RegisteredFileBaseDto extends BaseDto {
	RegisteredFileId?: string;
	SystemResourceId?: string;
	FileTypeCode?: string;
	OriginalFilename?: string;
	StorageFilename?: string;
	StorageFolder?: string;
	FileSize?: number;
	ImageHeight?: number;
	ImageWidth?: number;
	ActiveLinks?: number;
	DateLastAccessed?: Date;
	ProcessException?: string;
	DataClassName?: string;
	DataJSON?: string;
	UrlLink?: string;
	StatusCode?: string;
}
