import { BaseDto } from "../baseDto"
/* EmailFileBaseDto generated at 2025-01-13 11:53:04.717 +11:00*/
export class EmailFileBaseDto extends BaseDto {
	FileId?: string;
	RegisteredFileId?: string;
	EmailMessageId?: string;
	Sequence?: number;
	Filename?: string;
	External_ReferenceId?: string;
	StatusCode?: string;
}
