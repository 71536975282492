import { BaseDto } from "../baseDto"
/* SFARLAnnualReportBaseDto generated at 2025-01-13 11:53:05.007 +11:00*/
export class SFARLAnnualReportBaseDto extends BaseDto {
	Id?: string;
	OwnerId?: string;
	IsDeleted?: boolean;
	Name?: string;
	CreatedDate?: Date;
	CreatedById?: string;
	LastModifiedDate?: Date;
	LastModifiedById?: string;
	SystemModstamp?: Date;
	LastViewedDate?: Date;
	LastReferencedDate?: Date;
	AI_Q1__c?: string;
	AI_Q2__c?: string;
	ARL_Q0__c?: string;
	Aus_Q1__c?: number;
	Aus_Q2__c?: number;
	Aus_Q3__c?: number;
	Aus_Q4__c?: number;
	Aus_Q5__c?: number;
	Aus_Q6__c?: number;
	Aus_Q7__c?: number;
	Due_Date__c?: Date;
	Member_Account__c?: string;
	NZ_Q1__c?: number;
	NZ_Q2__c?: number;
	NZ_Q3__c?: number;
	NZ_Q4__c?: number;
	NZ_Q5__c?: number;
	NZ_Q6__c?: number;
	NZ_Q7__c?: number;
	Name_Equals_Naming_Convention__c?: boolean;
	Naming_Convention__c?: string;
	Status__c?: string;
	no_additional_information__c?: boolean;
	submitted__c?: boolean;
	submitted_by__c?: string;
	submitted_on__c?: Date;
	tcs_signed__c?: boolean;
	tcs_signed_by__c?: string;
	tcs_signed_on__c?: Date;
	Aus_evidence__c?: string;
	NZ_evidence__c?: string;
	PDF_stored_on__c?: Date;
}
