import { BaseDto } from "../baseDto"
/* InboundDataBaseDto generated at 2025-01-13 11:53:04.757 +11:00*/
export class InboundDataBaseDto extends BaseDto {
	InboundDataId?: string;
	ARLFileId?: string;
	DataClassificationCode?: string;
	ReferenceId1?: string;
	ReferenceId2?: string;
	ReferenceId3?: string;
	SequenceNo?: number;
	FileData?: string;
	ValidationErrors?: string;
	ErrorMessage?: string;
	DateProcessed?: Date;
	StatusCode?: string;
}
