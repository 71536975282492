import { Observable, of, map, switchMap } from "rxjs";
import { catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { ApiService, AppContextService } from "../../../core/service";
import { ActionResult, DataResult } from "src/app/core/model/shared";
import { UserDto } from "src/app/core/model/feature/apcomodel";
import { HttpParams } from "@angular/common/http";
//import { HttpParams } from "@angular/common/http";
//import { ActionResult, Constants, DataResult, AuthKeyDto, UserDto, UserAccessDto, AppAccess } from './../../../core/model';

@Injectable({
    providedIn: "root"
})
export class AuthenticationService {
    private apiSvc: ApiService;
    constructor(private appCtx: AppContextService) {
        this.apiSvc = this.appCtx.apiSvc;
    }
    checkAuthenticated(componentName: string = ""): Observable<boolean> {
        return new Observable(observer => {
            if (this.appCtx.domSvc.Expires && this.appCtx.utilSvc.dateSecondsDiff(new Date().getUTCDate(), this.appCtx.domSvc.Expires) > 60 && this.appCtx.domSvc.Authorisation) {
                observer.next(true);
            } else {
                this.apiSvc.checkAuthenticated(componentName).subscribe(resp => {
                    if (resp) {
                        observer.next(true);
                    } else {
                        observer.next(false);
                    }
                });
            }
        });
    }
    sso(requestCode: string): Observable<boolean> {
        let that = this;
        return new Observable(observer => {
            let httpParams: HttpParams = new HttpParams();
            let data: any = encodeURIComponent(requestCode);
            this.apiSvc.tokenPost("/Authentication/SSO", data, httpParams, null, false).subscribe((response: any) => {
                if (response) {
                    observer.next(true);
                }
                else {
                    observer.next(false);
                }
            }, err => {
                observer.error(err);
            });
        });
    }
    getRequestCode(requestcode: string = ""): Observable<boolean> {
        let that = this;
        return new Observable(observer => {
            let httpParams: HttpParams = new HttpParams()
                .append("requestcode", requestcode);
            this.apiSvc.getAuthorizeService("/Authentication/GetRequstCode", httpParams).subscribe((resp: DataResult<boolean>) => {
                observer.next(resp.IsSuccess);
            }, error => {
                observer.error(error);
            });
        });
    }
    confirmUser(user: UserDto): Observable<boolean> {
        let that = this;
        return new Observable(observer => {
            let httpParams: HttpParams = new HttpParams();
            let data: UserDto = {
                Username: user.Username,
                RequestCode: user.RequestCode,
            };
            this.apiSvc.tokenPost("/Authentication/ConfirmUser", data, httpParams, null, false).subscribe((response: any) => {
                if (response) {
                    observer.next(true);
                }
                else {
                    observer.next(false);
                }
            }, err => {
                observer.error(err);
            });
        });
    }
    forgotPassword(username: string = ""): Observable<ActionResult> {
        let that = this;
        return new Observable(observer => {
            let httpParams: HttpParams = new HttpParams()
                .append("username", username);
            this.apiSvc.getAuthorizeService("/Authentication/ForgotPassword", httpParams).subscribe(resp => {
                resp = this.appCtx.utilSvc.parseSubJsonFields(resp) as ActionResult;
                observer.next(resp.IsSuccess);
            }, error => {
                observer.error(error);
            });
        });
    }
    resetPassword(user: UserDto): Observable<boolean> {
        let that = this;
        return new Observable(observer => {
            let httpParams: HttpParams = new HttpParams();
            let data: UserDto = {
                Username: user.Username,
                Password: user.Password,
                RequestCode: user.RequestCode,
                VerificationCode: user.VerificationCode
            };
            this.apiSvc.tokenPost("/Authentication/ResetPassword", data, httpParams, null, false).subscribe((response: any) => {
                if (response) {
                    observer.next(true);
                }
                else {
                    observer.next(false);
                }
            }, err => {
                observer.error(err);
            });
        });
    }
    changePassword(user: UserDto): Observable<boolean> {
        let that = this;
        return new Observable(observer => {
            // if (user && user.NewPassword && this.appCtx.platformContext.DeviceInfo) {
            //     this.apiSvc.getSession().subscribe((keyResult: AuthKeyDto) => {
            //         let tUser: UserDto = new UserDto();
            //         if (user.UserPassword) {
            //             tUser.UserPassword = this.appCtx.utilSvc.AESEncrypt(user.UserPassword, keyResult.KeyValue, this.appCtx.platformContext.DeviceInfo.UUId);
            //         }
            //         tUser.NewPassword = this.appCtx.utilSvc.AESEncrypt(user.NewPassword, keyResult.KeyValue, this.appCtx.platformContext.DeviceInfo.UUId);
            //         let data: any = {
            //             "ChangeUser": tUser,
            //             "KeyId": keyResult.AuthKeyId
            //         };
            //         this.apiSvc.postAuthorizeService("/ChangePassword", data).subscribe(resp => {
            //             resp = this.appCtx.utilSvc.parseSubJsonFields(resp) as ActionResult;
            //             if (resp.IsSuccess) {
            //                 resp.IsSuccess = true;
            //             } else {
            //                 resp.IsSuccess = false;
            //             }
            //             observer.next(resp.IsSuccess);
            //         }, err => {
            //             observer.error(err);
            //         });
            //     }, err => {
            //         observer.error(err);
            //     });
            // } else {
            //     observer.error("Change password error.");
            // }
        });
    }
    setEnvironmentCode(environmentCode: string = ""): Observable<ActionResult> {
        let that = this;
        return new Observable(observer => {
            let httpParams: HttpParams = new HttpParams();
            let data: string = environmentCode;
            this.apiSvc.post("/Authentication/SetEnvironmentCode", data, httpParams, null, false).subscribe((resp: ActionResult) => {
                observer.next(resp);
            }, error => {
                observer.error(error);
            });
        });
    }
    redeemANZPACAuthorization(id: string, code: string): Observable<boolean> {
        let that = this;
        return new Observable(observer => {
            this.apiSvc.redeemAuthorization(id, code).subscribe(data => {
                this.apiSvc.refreshToken(this.appCtx.domSvc.RefreshToken).subscribe(response => {
                    observer.next(this.appCtx.Authenticated);
                }, respErr => { observer.error(respErr); })
            }, err => { observer.error(err); });
        });
    }
}
