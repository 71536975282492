import { BaseDto } from "../baseDto"
/* UserRoleBaseDto generated at 2025-01-13 11:53:05.123 +11:00*/
export class UserRoleBaseDto extends BaseDto {
	UserRoleId?: string;
	UserId?: string;
	OrganisationId?: string;
	RoleTypeCode?: string;
	RoleCode?: string;
	StatusCode?: string;
}
