import { BaseDto } from "../baseDto"
/* IPAccessBaseDto generated at 2025-01-13 11:53:04.773 +11:00*/
export class IPAccessBaseDto extends BaseDto {
	IPAccessId?: string;
	IPAccessTypeCode?: string;
	UserId?: string;
	IPAddress?: string;
	AccessStatusCode?: string;
	EnvCode?: string;
	StatusCode?: string;
}
