import { DomainValueDto } from "../feature/apcomodel/DomainValueDto";
//import { ConstantDomainValue } from './constant-domain-value';
export class Constants {
    public static UiInputFormats = {    
        date: "YYYY-MM-DD",
        dateDisplay: "MMM D, YYYY HH:mm:ss",
        datetimeLocal: "YYYY-MM-DDTHH:mm:ss"
    };
    public static DeviceAccess = {    
        Storage: "Storage",
        Camera: "Camera",
        Audio: "Audio",
        Location: "Location"
    };
    public static GuidEmpty: string = "00000000-0000-0000-0000-000000000000";
    public static FileChunkSize: number = 1024000;
    public static BlankImage: string = "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==";
    //public static DateDomainUpdated = ""
    public static Months = {Values : [
        new DomainValueDto({ CodeValue: "1", NameValue: "Jan" }),
        new DomainValueDto({ CodeValue: "2", NameValue: "Feb" }),
        new DomainValueDto({ CodeValue: "3", NameValue: "Mar" }),
        new DomainValueDto({ CodeValue: "4", NameValue: "Apr" }),
        new DomainValueDto({ CodeValue: "5", NameValue: "May" }),
        new DomainValueDto({ CodeValue: "6", NameValue: "Jun" }),
        new DomainValueDto({ CodeValue: "7", NameValue: "Jul" }),
        new DomainValueDto({ CodeValue: "8", NameValue: "Aug" }),
        new DomainValueDto({ CodeValue: "9", NameValue: "Sep" }),
        new DomainValueDto({ CodeValue: "10", NameValue: "Oct" }),
        new DomainValueDto({ CodeValue: "11", NameValue: "Nov" }),
        new DomainValueDto({ CodeValue: "12", NameValue: "Dec" })
    ]};
    public static GeneralStatus = {
        Active: "Active",
        Cancelled: "Cancelled",
        Disabled: "Disabled",
        Inactive: "Inactive",
        New: "New",
        Pending: "Pending",
        Processed: "Processed",
        Recycled: "Recycled",
        Removed: "Removed",
        Values : [
            new DomainValueDto({ CodeValue: "New", NameValue: "New", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "Pending", NameValue: "Pending", DisplayValue: "", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "Processed", NameValue: "Processed", DisplayValue: "", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "Active", NameValue: "Active", DisplayValue: "", SequenceNumber: 4}),
            new DomainValueDto({ CodeValue: "Inactive", NameValue: "Inactive", DisplayValue: "", SequenceNumber: 5}),
            new DomainValueDto({ CodeValue: "Cancelled", NameValue: "Cancelled", DisplayValue: "", SequenceNumber: 6}),
            new DomainValueDto({ CodeValue: "Recycled", NameValue: "Recycled", DisplayValue: "", SequenceNumber: 7}),
            new DomainValueDto({ CodeValue: "Removed", NameValue: "Removed", DisplayValue: "", SequenceNumber: 8}),
            new DomainValueDto({ CodeValue: "Disabled", NameValue: "Disabled", DisplayValue: "", SequenceNumber: 9})            
        ]
    };
    public static DomainType = {
        AmazonConfigParams: "AmazonConfigParams",
        ANZPAC2023ActivityOutcomeType: "ANZPAC2023ActivityOutcomeType",
        ANZPAC2023ActivityType: "ANZPAC2023ActivityType",
        ANZPAC2023AnnualTurnover: "ANZPAC2023AnnualTurnover",
        ANZPAC2023BOActivityOutcomeType: "ANZPAC2023BOActivityOutcomeType",
        ANZPAC2023Currency: "ANZPAC2023Currency",
        ANZPAC2023Geographies: "ANZPAC2023Geographies",
        ANZPAC2023IndustrySector: "ANZPAC2023IndustrySector",
        ANZPAC2023OpportunityType: "ANZPAC2023OpportunityType",
        ANZPAC2023OutcomeType: "ANZPAC2023OutcomeType",
        ANZPAC2023RecoverabilityType: "ANZPAC2023RecoverabilityType",
        ANZPAC2023RecyclabilityEvidenceSelectionType: "ANZPAC2023RecyclabilityEvidenceSelectionType",
        ANZPAC2023RecycledContentType: "ANZPAC2023RecycledContentType",
        ANZPAC2023SupporterMemberCategory: "ANZPAC2023SupporterMemberCategory",
        ANZPACANZPACAR2023: "ANZPACANZPACAR_2023",
        ANZPACAR: "ANZPACAR",
        ANZPACAR2023BO: "ANZPACAR2023_BO",
        ANZPACAR2023BOBLM: "ANZPACAR2023_BO_BLM",
        ANZPACAR2023BOT1: "ANZPACAR2023_BO_T1",
        ANZPACAR2023BOT1S1: "ANZPACAR2023_BO_T1_S1",
        ANZPACAR2023BOT1S2: "ANZPACAR2023_BO_T1_S2",
        ANZPACAR2023BOT2: "ANZPACAR2023_BO_T2",
        ANZPACAR2023BOT2S1: "ANZPACAR2023_BO_T2_S1",
        ANZPACAR2023BOT2S3: "ANZPACAR2023_BO_T2_S3",
        ANZPACAR2023BOT2S4: "ANZPACAR2023_BO_T2_S4",
        ANZPACAR2023BOT3: "ANZPACAR2023_BO_T3",
        ANZPACAR2023BOT3S1: "ANZPACAR2023_BO_T3_S1",
        ANZPACAR2023BOT4: "ANZPACAR2023_BO_T4",
        ANZPACAR2023BOT4S1: "ANZPACAR2023_BO_T4_S1",
        ANZPACAR2023RAU: "ANZPACAR2023_RAU",
        ANZPACAR2023RAUBLM: "ANZPACAR2023_RAU_BLM",
        ANZPACAR2023RAUT3: "ANZPACAR2023_RAU_T3",
        ANZPACAR2023RAUT3S1: "ANZPACAR2023_RAU_T3_S1",
        ANZPACAR2023RAUT3S2: "ANZPACAR2023_RAU_T3_S2",
        ANZPACAR2023RAUT3S3: "ANZPACAR2023_RAU_T3_S3",
        ANZPACAR2023RAUT4: "ANZPACAR2023_RAU_T4",
        ANZPACAR2023RAUT4S1: "ANZPACAR2023_RAU_T4_S1",
        ANZPACAR2023RMS: "ANZPACAR2023_RMS",
        ANZPACAR2023RMSBLM: "ANZPACAR2023_RMS_BLM",
        ANZPACAR2023RMST3: "ANZPACAR2023_RMS_T3",
        ANZPACAR2023RMST3S1: "ANZPACAR2023_RMS_T3_S1",
        ANZPACAR2023RMST3S3: "ANZPACAR2023_RMS_T3_S3",
        ANZPACAR2023RMST4: "ANZPACAR2023_RMS_T4",
        ANZPACAR2023RMST4S1: "ANZPACAR2023_RMS_T4_S1",
        ANZPACAR2023SM: "ANZPACAR2023_SM",
        ANZPACAR2023SMBLM: "ANZPACAR2023_SM_BLM",
        ANZPACAR2023SMT1: "ANZPACAR2023_SM_T1",
        ANZPACAR2023SMT1S1: "ANZPACAR2023_SM_T1_S1",
        ANZPACAR2023SMT2: "ANZPACAR2023_SM_T2",
        ANZPACAR2023SMT2S5: "ANZPACAR2023_SM_T2_S5",
        ANZPACAR2023AccuracyLevel: "ANZPACAR2023AccuracyLevel",
        ARLImage: "ARLImage",
        ARLImageText: "ARLImageText",
        ARLMProductStatus: "ARLMProductStatus",
        ARLMProjectStatus: "ARLMProjectStatus",
        ARLRequestSources: "ARLRequestSources",
        AttributeDataSource: "AttributeDataSource",
        AttributeType: "AttributeType",
        AuthenticationClaim: "AuthenticationClaim",
        AuthorisationType: "AuthorisationType",
        CMSPageContentType: "CMSPageContentType",
        DatabaseActionStatus: "DatabaseActionStatus",
        DataType: "DataType",
        EmailAccountConfiguration: "EmailAccountConfiguration",
        EmailDirection: "EmailDirection",
        EmailMessageStatus: "EmailMessageStatus",
        EmailNotificationTemplate: "EmailNotificationTemplate",
        FileClassificationCode: "FileClassificationCode",
        JobTaskType: "JobTaskType",
        ObjectType: "ObjectType",
        PackagingComponentType: "PackagingComponentType",
        PackagingItemType: "PackagingItemType",
        PlasticAdditive: "PlasticAdditive",
        PREPBondingType: "PREPBondingType",
        PREPCarbonBlack: "PREPCarbonBlack",
        PREPGaugeType: "PREPGaugeType",
        PREPInkType: "PREPInkType",
        PREPPrimaryMaterial: "PREPPrimaryMaterial",
        PREPPrimaryMaterialType: "PREPPrimaryMaterialType",
        PREPProductResidue: "PREPProductResidue",
        PREPProjectCategory: "PREPProjectCategory",
        PREPProjectMarkets: "PREPProjectMarkets",
        PREPRigidity: "PREPRigidity",
        PREPSecondaryMaterial: "PREPSecondaryMaterial",
        PREPSecondaryMaterialType: "PREPSecondaryMaterialType",
        PREPSoftPlasticPolymer: "PREPSoftPlasticPolymer",
        PREPSoftPlasticType: "PREPSoftPlasticType",
        PREPStatusCode: "PREPStatusCode",
        PrimaryMeansOfElimination: "PrimaryMeansOfElimination",
        ProjectActionStatus: "ProjectActionStatus",
        ProjectImportExportColumns: "ProjectImportExportColumns",
        ProjectStatus: "ProjectStatus",
        ScheduledTask: "ScheduledTask",
        ScheduledTaskAction: "ScheduledTaskAction",
        ScheduleOccursEveryType: "ScheduleOccursEveryType",
        ScheduleRecurDayOfWeek: "ScheduleRecurDayOfWeek",
        ScheduleRecurType: "ScheduleRecurType",
        ScheduleRecurWeekType: "ScheduleRecurWeekType",
        ScheduleStatusCode: "ScheduleStatusCode",
        ScheduleType: "ScheduleType",
        SQLConnectionString: "SQLConnectionString",
        SystemConfigurations: "SystemConfigurations",
        SystemDatabaseType: "SystemDatabaseType",
        SystemDataIntegrations: "SystemDataIntegrations",
        SystemEnvironment: "SystemEnvironment",
        SystemModelType: "SystemModelType",
        SystemResourceType: "SystemResourceType",
        SystemStorageType: "SystemStorageType",
        UOMArea: "UOM_Area",
        UOMCapacity: "UOM_Capacity",
        UOMLength: "UOM_Length",
        UOMWeight: "UOM_Weight",
        UserRole: "UserRole",
        UserRoleType: "UserRoleType",
        WProgramMaterialType: "WProgramMaterialType",
        WProgramRegion: "WProgramRegion",
        WProgramStatus: "WProgramStatus",
        WSubmissionStatus: "WSubmissionStatus",
        WSubmissionType: "WSubmissionType"
    };
	public static AmazonConfigParams = {
        AmazonS3Endpoint: "AmazonS3Endpoint",
        AmazonS3AccessKey: "AmazonS3AccessKey",
        AmazonS3SecretAccessKey: "AmazonS3SecretAccessKey",
        Values : [
            new DomainValueDto({ CodeValue: "AmazonS3Endpoint", NameValue: "AmazonS3Endpoint", DisplayValue: "AmazonS3Endpoint", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "AmazonS3AccessKey", NameValue: "AmazonS3AccessKey", DisplayValue: "AmazonS3AccessKey", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "AmazonS3SecretAccessKey", NameValue: "AmazonS3SecretAccessKey", DisplayValue: "AmazonS3SecretAccessKey", SequenceNumber: 3 })            
        ]
    };
	public static ANZPAC2023ActivityOutcomeType = {
        Successful: "ANZPAC2023ActivityOutcomeTypeSuccessful",
        Unsuccessful: "Unsuccessful",
        Values : [
            new DomainValueDto({ CodeValue: "ANZPAC2023ActivityOutcomeTypeSuccessful", NameValue: "Successful", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "Unsuccessful", NameValue: "Unsuccessful", DisplayValue: "", SequenceNumber: 2 })            
        ]
    };
	public static ANZPAC2023ActivityType = {
        Progressmade: "ANZPAC2023ActivityTypeProgressmade",
        Plannedactions: "ANZPAC2023ActivityTypePlannedactions",
        Values : [
            new DomainValueDto({ CodeValue: "ANZPAC2023ActivityTypeProgressmade", NameValue: "Progress made", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "ANZPAC2023ActivityTypePlannedactions", NameValue: "Planned actions", DisplayValue: "", SequenceNumber: 2 })            
        ]
    };
	public static ANZPAC2023AnnualTurnover = {
        Under50Million: "Under50Million",
        From50MTo250M: "From50MTo250M",
        From250MTo500M: "From250MTo500M",
        From500MTo1B: "From500MTo1B",
        GreaterThan1B: "GreaterThan1B",
        Values : [
            new DomainValueDto({ CodeValue: "Under50Million", NameValue: "Under50Million", DisplayValue: "Under $50 Million", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "From50MTo250M", NameValue: "From50MTo250M", DisplayValue: "$50 Million - $250 Million", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "From250MTo500M", NameValue: "From250MTo500M", DisplayValue: "$250 Million - $500 Million", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "From500MTo1B", NameValue: "From500MTo1B", DisplayValue: "$500 Million - $1 Billion", SequenceNumber: 4}),
            new DomainValueDto({ CodeValue: "GreaterThan1B", NameValue: "GreaterThan1B", DisplayValue: "Greater than $1 Billion", SequenceNumber: 5 })            
        ]
    };
	public static ANZPAC2023BOActivityOutcomeType = {
        Progressmade: "ProgressMade",
        Plannedactions: "PlannedAction",
        Values : [
            new DomainValueDto({ CodeValue: "ProgressMade", NameValue: "Progress made", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "PlannedAction", NameValue: "Planned actions", DisplayValue: "", SequenceNumber: 2 })            
        ]
    };
	public static ANZPAC2023Currency = {
        AUD: "CurrencyAUD",
        NZD: "CurrencyNZD",
        Values : [
            new DomainValueDto({ CodeValue: "CurrencyAUD", NameValue: "AUD", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "CurrencyNZD", NameValue: "NZD", DisplayValue: "", SequenceNumber: 2 })            
        ]
    };
	public static ANZPAC2023Geographies = {
        Australia: "GeographyAustralia",
        NewZealand: "GeographyNewZealand",
        PacificIslands: "GeographyPacificIslands",
        AustraliaNewZealand: "GeographyAustraliaNewZealand",
        AustraliaPacificIslands: "GeographyAustraliaPacificIslands",
        NewZealandPacificIslands: "GeographyNewZealandPacificIslands",
        AustraliaNewZealandPacificIslands: "GeographyAustraliaNewZealPacificIslands",
        Values : [
            new DomainValueDto({ CodeValue: "GeographyAustralia", NameValue: "Australia", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "GeographyNewZealand", NameValue: "New Zealand", DisplayValue: "", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "GeographyPacificIslands", NameValue: "Pacific Islands", DisplayValue: "", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "GeographyAustraliaNewZealand", NameValue: "Australia & New Zealand", DisplayValue: "", SequenceNumber: 4}),
            new DomainValueDto({ CodeValue: "GeographyAustraliaPacificIslands", NameValue: "Australia & Pacific Islands", DisplayValue: "", SequenceNumber: 5}),
            new DomainValueDto({ CodeValue: "GeographyNewZealandPacificIslands", NameValue: "New Zealand & Pacific Islands", DisplayValue: "", SequenceNumber: 6}),
            new DomainValueDto({ CodeValue: "GeographyAustraliaNewZealPacificIslands", NameValue: "Australia, New Zealand & Pacific Islands", DisplayValue: "", SequenceNumber: 7 })            
        ]
    };
	public static ANZPAC2023IndustrySector = {
        AgricultureNurseries: "AgricultureAndNurseries",
        AirlineAccommodationTourism: "AirlineAndAccommodationAndTourism",
        ChemicalsHardwareMachinery: "ChemicalsAndHardwareAndMachinery",
        FoodBeverage: "FoodAndBeverage",
        Electronics: "Electronics",
        GeneralMerchandiseApparel: "GeneralMerchandiseAndApparel",
        HealthcareScientific: "HealthcareAndScientific",
        LogisticsWarehousing: "LogisticsAndWarehousing",
        PackagingManufacturersSuppliers: "PackagingAndManufacturersAndSuppliers",
        Telecommunications: "Telecommunications",
        Tobacco: "Tobacco",
        WasteRecycling: "WasteAndRecycling",
        Otherpleasespecify: "Other",
        Values : [
            new DomainValueDto({ CodeValue: "AgricultureAndNurseries", NameValue: "Agriculture & Nurseries", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "AirlineAndAccommodationAndTourism", NameValue: "Airline, Accommodation & Tourism", DisplayValue: "", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "ChemicalsAndHardwareAndMachinery", NameValue: "Chemicals, Hardware & Machinery", DisplayValue: "", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "FoodAndBeverage", NameValue: "Food & Beverage", DisplayValue: "", SequenceNumber: 4}),
            new DomainValueDto({ CodeValue: "Electronics", NameValue: "Electronics", DisplayValue: "", SequenceNumber: 5}),
            new DomainValueDto({ CodeValue: "GeneralMerchandiseAndApparel", NameValue: "General Merchandise & Apparel", DisplayValue: "", SequenceNumber: 6}),
            new DomainValueDto({ CodeValue: "HealthcareAndScientific", NameValue: "Healthcare & Scientific", DisplayValue: "", SequenceNumber: 7}),
            new DomainValueDto({ CodeValue: "LogisticsAndWarehousing", NameValue: "Logistics & Warehousing", DisplayValue: "", SequenceNumber: 8}),
            new DomainValueDto({ CodeValue: "PackagingAndManufacturersAndSuppliers", NameValue: "Packaging Manufacturers & Suppliers", DisplayValue: "", SequenceNumber: 9}),
            new DomainValueDto({ CodeValue: "Telecommunications", NameValue: "Telecommunications", DisplayValue: "", SequenceNumber: 10}),
            new DomainValueDto({ CodeValue: "Tobacco", NameValue: "Tobacco", DisplayValue: "", SequenceNumber: 11}),
            new DomainValueDto({ CodeValue: "WasteAndRecycling", NameValue: "Waste & Recycling", DisplayValue: "", SequenceNumber: 12}),
            new DomainValueDto({ CodeValue: "Other", NameValue: "Other (please specify)", DisplayValue: "", SequenceNumber: 13 })            
        ]
    };
	public static ANZPAC2023OpportunityType = {
        Opportunity: "Opportunity",
        Constraint: "Constraint",
        Values : [
            new DomainValueDto({ CodeValue: "Opportunity", NameValue: "Opportunity", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "Constraint", NameValue: "Constraint", DisplayValue: "", SequenceNumber: 2 })            
        ]
    };
	public static ANZPAC2023OutcomeType = {
        Progressmade: "ANZPAC2023OutcomeTypeProgressmade",
        Plannedactions: "ANZPAC2023OutcomeTypePlannedactions",
        Values : [
            new DomainValueDto({ CodeValue: "ANZPAC2023OutcomeTypeProgressmade", NameValue: "Progress made", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "ANZPAC2023OutcomeTypePlannedactions", NameValue: "Planned actions", DisplayValue: "", SequenceNumber: 2 })            
        ]
    };
	public static ANZPAC2023RecoverabilityType = {
        Reusable: "ANZPAC2023RecoverabilityTypeReusable",
        Recyclable: "ANZPAC2023RecoverabilityTypeRecyclable",
        Compostable: "ANZPAC2023RecoverabilityTypeCompostable",
        Values : [
            new DomainValueDto({ CodeValue: "ANZPAC2023RecoverabilityTypeReusable", NameValue: "Reusable", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "ANZPAC2023RecoverabilityTypeRecyclable", NameValue: "Recyclable", DisplayValue: "", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "ANZPAC2023RecoverabilityTypeCompostable", NameValue: "Compostable", DisplayValue: "", SequenceNumber: 3 })            
        ]
    };
	public static ANZPAC2023RecyclabilityEvidenceSelectionType = {
        Values : [
        ]
    };
	public static ANZPAC2023RecycledContentType = {
        Preconsumer: "Pre-consumer",
        Postconsumer: "Post-consumer",
        Values : [
            new DomainValueDto({ CodeValue: "Pre-consumer", NameValue: "Pre-consumer", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "Post-consumer", NameValue: "Post-consumer", DisplayValue: "", SequenceNumber: 2 })            
        ]
    };
	public static ANZPAC2023SupporterMemberCategory = {
        Academia: "Academia",
        Communitygroup: "Communitygroup",
        NngovernmentorganisationNGO: "ANZPAC2023SupporterMemberCategoryNGO",
        Industryassociation: "Industryassociation",
        Government: "Government",
        NonforprofitNFT: "ANZPAC2023SupporterMemberCategoryNFT",
        Otherpleasespecify: "ANZPAC2023SupporterMemberCategoryOther",
        Values : [
            new DomainValueDto({ CodeValue: "Academia", NameValue: "Academia", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "Communitygroup", NameValue: "Community group", DisplayValue: "", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "ANZPAC2023SupporterMemberCategoryNGO", NameValue: "Non-governmental organisation (NGO)", DisplayValue: "", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "Industryassociation", NameValue: "Industry association", DisplayValue: "", SequenceNumber: 4}),
            new DomainValueDto({ CodeValue: "Government", NameValue: "Government", DisplayValue: "", SequenceNumber: 5}),
            new DomainValueDto({ CodeValue: "ANZPAC2023SupporterMemberCategoryNFT", NameValue: "Not-for-profit (NFP)", DisplayValue: "", SequenceNumber: 6}),
            new DomainValueDto({ CodeValue: "ANZPAC2023SupporterMemberCategoryOther", NameValue: "Other (please specify)", DisplayValue: "", SequenceNumber: 7 })            
        ] 
    };
	public static ANZPACANZPACAR2023 = {
        BrandOwners: "BrandOwners",
        RawMaterialSuppliers: "RawMaterialSuppliers",
        RecyclersAndAfterUse: "RecyclersAndAfterUse",
        SupporterMembers: "SupporterMembers",
        Values : [
            new DomainValueDto({ CodeValue: "BrandOwners", NameValue: "Brand Owners", DisplayValue: "Brand Owners", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "RawMaterialSuppliers", NameValue: "Raw Material Suppliers", DisplayValue: "Raw Material Suppliers", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "RecyclersAndAfterUse", NameValue: "Recyclers And After Use", DisplayValue: "Recyclers And After Use", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "SupporterMembers", NameValue: "Supporter Members", DisplayValue: "Supporter Members", SequenceNumber: 4 })            
        ]
    };
	public static ANZPACAR = {
        ANZPACANZPACAR2023: "ANZPACANZPACAR_2023",
        ANZPACANZPACAR2024: "ANZPACANZPACAR_2024",
        ANZPACANZPACAR2025: "ANZPACANZPACAR_2025",
        Values : [
            new DomainValueDto({ CodeValue: "ANZPACANZPACAR_2023", NameValue: "ANZPACANZPACAR_2023", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "ANZPACANZPACAR_2024", NameValue: "ANZPACANZPACAR_2024", DisplayValue: "", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "ANZPACANZPACAR_2025", NameValue: "ANZPACANZPACAR_2025", DisplayValue: "", SequenceNumber: 3 })            
        ]
    };
	public static ANZPACAR2023BO = {
        ANZPACAR2023BOT1: "ANZPACAR2023_BO_T1",
        ANZPACAR2023BOT2: "ANZPACAR2023_BO_T2",
        ANZPACAR2023BOT3: "ANZPACAR2023_BO_T3",
        ANZPACAR2023BOT4: "ANZPACAR2023_BO_T4",
        Values : [
            new DomainValueDto({ CodeValue: "ANZPACAR2023_BO_T1", NameValue: "ANZPACAR2023_BO_T1", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "ANZPACAR2023_BO_T2", NameValue: "ANZPACAR2023_BO_T2", DisplayValue: "", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "ANZPACAR2023_BO_T3", NameValue: "ANZPACAR2023_BO_T3", DisplayValue: "", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "ANZPACAR2023_BO_T4", NameValue: "ANZPACAR2023_BO_T4", DisplayValue: "", SequenceNumber: 4 })            
        ]
    };
	public static ANZPACAR2023BOBLM = {
        AR2023BOBLMItem1: "AR2023_BO_BLM_Item1",
        AR2023BOBLMItem2: "AR2023_BO_BLM_Item2",
        AR2023BOBLMItem3: "AR2023_BO_BLM_Item3",
        AR2023BOBLMItem4: "AR2023_BO_BLM_Item4",
        AR2023BOBLMItem5: "AR2023_BO_BLM_Item5",
        AR2023BOBLMItem6: "AR2023_BO_BLM_Item6",
        AR2023BOBLMItem7: "AR2023_BO_BLM_Item7",
        AR2023BOBLMItem8: "AR2023_BO_BLM_Item8",
        AR2023BOBLMItem9: "AR2023_BO_BLM_Item9",
        AR2023BOBLMItem10: "AR2023_BO_BLM_Item10",
        AR2023BOBLMItem11: "AR2023_BO_BLM_Item11",
        AR2023BOBLMItem12: "AR2023_BO_BLM_Item12",
        AR2023BOBLMItem13: "AR2023_BO_BLM_Item13",
        AR2023BOBLMItem14: "AR2023_BO_BLM_Item14",
        AR2023BOBLMItem15: "AR2023_BO_BLM_Item15",
        AR2023BOBLMItem16: "AR2023_BO_BLM_Item16",
        Values : [
            new DomainValueDto({ CodeValue: "AR2023_BO_BLM_Item1", NameValue: "AR2023_BO_BLM_Item1", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "AR2023_BO_BLM_Item2", NameValue: "AR2023_BO_BLM_Item2", DisplayValue: "", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "AR2023_BO_BLM_Item3", NameValue: "AR2023_BO_BLM_Item3", DisplayValue: "", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "AR2023_BO_BLM_Item4", NameValue: "AR2023_BO_BLM_Item4", DisplayValue: "", SequenceNumber: 4}),
            new DomainValueDto({ CodeValue: "AR2023_BO_BLM_Item5", NameValue: "AR2023_BO_BLM_Item5", DisplayValue: "", SequenceNumber: 5}),
            new DomainValueDto({ CodeValue: "AR2023_BO_BLM_Item6", NameValue: "AR2023_BO_BLM_Item6", DisplayValue: "", SequenceNumber: 6}),
            new DomainValueDto({ CodeValue: "AR2023_BO_BLM_Item7", NameValue: "AR2023_BO_BLM_Item7", DisplayValue: "", SequenceNumber: 7}),
            new DomainValueDto({ CodeValue: "AR2023_BO_BLM_Item8", NameValue: "AR2023_BO_BLM_Item8", DisplayValue: "", SequenceNumber: 8}),
            new DomainValueDto({ CodeValue: "AR2023_BO_BLM_Item9", NameValue: "AR2023_BO_BLM_Item9", DisplayValue: "", SequenceNumber: 9}),
            new DomainValueDto({ CodeValue: "AR2023_BO_BLM_Item10", NameValue: "AR2023_BO_BLM_Item10", DisplayValue: "", SequenceNumber: 10}),
            new DomainValueDto({ CodeValue: "AR2023_BO_BLM_Item11", NameValue: "AR2023_BO_BLM_Item11", DisplayValue: "", SequenceNumber: 11}),
            new DomainValueDto({ CodeValue: "AR2023_BO_BLM_Item12", NameValue: "AR2023_BO_BLM_Item12", DisplayValue: "", SequenceNumber: 12}),
            new DomainValueDto({ CodeValue: "AR2023_BO_BLM_Item13", NameValue: "AR2023_BO_BLM_Item13", DisplayValue: "", SequenceNumber: 13}),
            new DomainValueDto({ CodeValue: "AR2023_BO_BLM_Item14", NameValue: "AR2023_BO_BLM_Item14", DisplayValue: "", SequenceNumber: 14}),
            new DomainValueDto({ CodeValue: "AR2023_BO_BLM_Item15", NameValue: "AR2023_BO_BLM_Item15", DisplayValue: "", SequenceNumber: 15}),
            new DomainValueDto({ CodeValue: "AR2023_BO_BLM_Item16", NameValue: "AR2023_BO_BLM_Item16", DisplayValue: "", SequenceNumber: 16 })            
        ]
    };
	public static ANZPACAR2023BOT1 = {
        ANZPACAR2023BOT1S1: "ANZPACAR2023_BO_T1_S1",
        ANZPACAR2023BOT1S2: "ANZPACAR2023_BO_T1_S2",
        Values : [
            new DomainValueDto({ CodeValue: "ANZPACAR2023_BO_T1_S1", NameValue: "ANZPACAR2023_BO_T1_S1", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "ANZPACAR2023_BO_T1_S2", NameValue: "ANZPACAR2023_BO_T1_S2", DisplayValue: "", SequenceNumber: 2 })            
        ]
    };
	public static ANZPACAR2023BOT1S1 = {
        AR2023BOT1S1Item1: "AR2023_BO_T1_S1_Item1",
        AR2023BOT1S1Item2: "AR2023_BO_T1_S1_Item2",
        AR2023BOT1S1Item3: "AR2023_BO_T1_S1_Item3",
        AR2023BOT1S1Item4: "AR2023_BO_T1_S1_Item4",
        AR2023BOT1S1Item5: "AR2023_BO_T1_S1_Item5",
        AR2023BOT1S1Item6: "AR2023_BO_T1_S1_Item6",
        AR2023BOT1S1Item7: "AR2023_BO_T1_S1_Item7",
        AR2023BOT1S1Item8: "AR2023_BO_T1_S1_Item8",
        Values : [
            new DomainValueDto({ CodeValue: "AR2023_BO_T1_S1_Item1", NameValue: "AR2023_BO_T1_S1_Item1", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "AR2023_BO_T1_S1_Item2", NameValue: "AR2023_BO_T1_S1_Item2", DisplayValue: "", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "AR2023_BO_T1_S1_Item3", NameValue: "AR2023_BO_T1_S1_Item3", DisplayValue: "", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "AR2023_BO_T1_S1_Item4", NameValue: "AR2023_BO_T1_S1_Item4", DisplayValue: "", SequenceNumber: 4}),
            new DomainValueDto({ CodeValue: "AR2023_BO_T1_S1_Item5", NameValue: "AR2023_BO_T1_S1_Item5", DisplayValue: "", SequenceNumber: 5}),
            new DomainValueDto({ CodeValue: "AR2023_BO_T1_S1_Item6", NameValue: "AR2023_BO_T1_S1_Item6", DisplayValue: "", SequenceNumber: 6}),
            new DomainValueDto({ CodeValue: "AR2023_BO_T1_S1_Item7", NameValue: "AR2023_BO_T1_S1_Item7", DisplayValue: "", SequenceNumber: 7}),
            new DomainValueDto({ CodeValue: "AR2023_BO_T1_S1_Item8", NameValue: "AR2023_BO_T1_S1_Item8", DisplayValue: "", SequenceNumber: 8 })            
        ]
    };
	public static ANZPACAR2023BOT1S2 = {
        AR2023BOT1S2Item1: "AR2023_BO_T1_S2_Item1",
        AR2023BOT1S2Item2: "AR2023_BO_T1_S2_Item2",
        AR2023BOT1S2Item3: "AR2023_BO_T1_S2_Item3",
        AR2023BOT1S2Item4: "AR2023_BO_T1_S2_Item4",
        AR2023BOT1S2Item5: "AR2023_BO_T1_S2_Item5",
        AR2023BOT1S2Item6: "AR2023_BO_T1_S2_Item6",
        AR2023BOT1S2Item7: "AR2023_BO_T1_S2_Item7",
        AR2023BOT1S2Item8: "AR2023_BO_T1_S2_Item8",
        AR2023BOT1S2Item9: "AR2023_BO_T1_S2_Item9",
        Values : [
            new DomainValueDto({ CodeValue: "AR2023_BO_T1_S2_Item1", NameValue: "AR2023_BO_T1_S2_Item1", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "AR2023_BO_T1_S2_Item2", NameValue: "AR2023_BO_T1_S2_Item2", DisplayValue: "", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "AR2023_BO_T1_S2_Item3", NameValue: "AR2023_BO_T1_S2_Item3", DisplayValue: "", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "AR2023_BO_T1_S2_Item4", NameValue: "AR2023_BO_T1_S2_Item4", DisplayValue: "", SequenceNumber: 4}),
            new DomainValueDto({ CodeValue: "AR2023_BO_T1_S2_Item5", NameValue: "AR2023_BO_T1_S2_Item5", DisplayValue: "", SequenceNumber: 5}),
            new DomainValueDto({ CodeValue: "AR2023_BO_T1_S2_Item6", NameValue: "AR2023_BO_T1_S2_Item6", DisplayValue: "", SequenceNumber: 6}),
            new DomainValueDto({ CodeValue: "AR2023_BO_T1_S2_Item7", NameValue: "AR2023_BO_T1_S2_Item7", DisplayValue: "", SequenceNumber: 7}),
            new DomainValueDto({ CodeValue: "AR2023_BO_T1_S2_Item8", NameValue: "AR2023_BO_T1_S2_Item8", DisplayValue: "", SequenceNumber: 8}),
            new DomainValueDto({ CodeValue: "AR2023_BO_T1_S2_Item9", NameValue: "AR2023_BO_T1_S2_Item9", DisplayValue: "", SequenceNumber: 9 })            
        ]
    };
	public static ANZPACAR2023BOT2 = {
        ANZPACAR2023BOT2S1: "ANZPACAR2023_BO_T2_S1",
        ANZPACAR2023BOT2S3: "ANZPACAR2023_BO_T2_S3",
        ANZPACAR2023BOT2S4: "ANZPACAR2023_BO_T2_S4",
        Values : [
            new DomainValueDto({ CodeValue: "ANZPACAR2023_BO_T2_S1", NameValue: "ANZPACAR2023_BO_T2_S1", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "ANZPACAR2023_BO_T2_S3", NameValue: "ANZPACAR2023_BO_T2_S3", DisplayValue: "", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "ANZPACAR2023_BO_T2_S4", NameValue: "ANZPACAR2023_BO_T2_S4", DisplayValue: "", SequenceNumber: 4 })            
        ]
    };
	public static ANZPACAR2023BOT2S1 = {
        AR2023BOT2S1Item1: "AR2023_BO_T2_S1_Item1",
        AR2023BOT2S1Item2: "AR2023_BO_T2_S1_Item2",
        AR2023BOT2S1Item3: "AR2023_BO_T2_S1_Item3",
        AR2023BOT2S1Item4: "AR2023_BO_T2_S1_Item4",
        Values : [
            new DomainValueDto({ CodeValue: "AR2023_BO_T2_S1_Item1", NameValue: "AR2023_BO_T2_S1_Item1", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "AR2023_BO_T2_S1_Item2", NameValue: "AR2023_BO_T2_S1_Item2", DisplayValue: "", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "AR2023_BO_T2_S1_Item3", NameValue: "AR2023_BO_T2_S1_Item3", DisplayValue: "", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "AR2023_BO_T2_S1_Item4", NameValue: "AR2023_BO_T2_S1_Item4", DisplayValue: "", SequenceNumber: 4 })            
        ]
    };
	public static ANZPACAR2023BOT2S3 = {
        AR2023BOT2S3Item1: "AR2023_BO_T2_S3_Item1",
        AR2023BOT2S3Item2: "AR2023_BO_T2_S3_Item2",
        AR2023BOT2S3Item3: "AR2023_BO_T2_S3_Item3",
        AR2023BOT2S3Item5: "AR2023_BO_T2_S3_Item5",
        Values : [
            new DomainValueDto({ CodeValue: "AR2023_BO_T2_S3_Item1", NameValue: "AR2023_BO_T2_S3_Item1", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "AR2023_BO_T2_S3_Item2", NameValue: "AR2023_BO_T2_S3_Item2", DisplayValue: "", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "AR2023_BO_T2_S3_Item3", NameValue: "AR2023_BO_T2_S3_Item3", DisplayValue: "", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "AR2023_BO_T2_S3_Item5", NameValue: "AR2023_BO_T2_S3_Item5", DisplayValue: "", SequenceNumber: 4 })            
        ]
    };
	public static ANZPACAR2023BOT2S4 = {
        AR2023BOT2S4Item1: "AR2023_BO_T2_S4_Item1",
        AR2023BOT2S4Item2: "AR2023_BO_T2_S4_Item2",
        AR2023BOT2S4Item3: "AR2023_BO_T2_S4_Item3",
        AR2023BOT2S4Item4: "AR2023_BO_T2_S4_Item4",
        AR2023BOT2S4Item5: "AR2023_BO_T2_S4_Item5",
        AR2023BOT2S4Item6: "AR2023_BO_T2_S4_Item6",
        AR2023BOT2S4Item7: "AR2023_BO_T2_S4_Item7",
        AR2023BOT2S4Item9: "AR2023_BO_T2_S4_Item9",
        Values : [
            new DomainValueDto({ CodeValue: "AR2023_BO_T2_S4_Item1", NameValue: "AR2023_BO_T2_S4_Item1", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "AR2023_BO_T2_S4_Item2", NameValue: "AR2023_BO_T2_S4_Item2", DisplayValue: "", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "AR2023_BO_T2_S4_Item3", NameValue: "AR2023_BO_T2_S4_Item3", DisplayValue: "", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "AR2023_BO_T2_S4_Item4", NameValue: "AR2023_BO_T2_S4_Item4", DisplayValue: "", SequenceNumber: 4}),
            new DomainValueDto({ CodeValue: "AR2023_BO_T2_S4_Item5", NameValue: "AR2023_BO_T2_S4_Item5", DisplayValue: "", SequenceNumber: 5}),
            new DomainValueDto({ CodeValue: "AR2023_BO_T2_S4_Item6", NameValue: "AR2023_BO_T2_S4_Item6", DisplayValue: "", SequenceNumber: 6}),
            new DomainValueDto({ CodeValue: "AR2023_BO_T2_S4_Item7", NameValue: "AR2023_BO_T2_S4_Item7", DisplayValue: "", SequenceNumber: 7}),
            new DomainValueDto({ CodeValue: "AR2023_BO_T2_S4_Item9", NameValue: "AR2023_BO_T2_S4_Item9", DisplayValue: "", SequenceNumber: 8 })            
        ]
    };
	public static ANZPACAR2023BOT3 = {
        ANZPACAR2023BOT3S1: "ANZPACAR2023_BO_T3_S1",
        Values : [
            new DomainValueDto({ CodeValue: "ANZPACAR2023_BO_T3_S1", NameValue: "ANZPACAR2023_BO_T3_S1", DisplayValue: "", SequenceNumber: 1 })            
        ]
    };
	public static ANZPACAR2023BOT3S1 = {
        AR2023BOT3S1Item1: "AR2023_BO_T3_S1_Item1",
        AR2023BOT3S1Item2: "AR2023_BO_T3_S1_Item2",
        AR2023BOT3S1Item3: "AR2023_BO_T3_S1_Item3",
        AR2023BOT3S1Item4: "AR2023_BO_T3_S1_Item4",
        AR2023BOT3S1Item5: "AR2023_BO_T3_S1_Item5",
        Values : [
            new DomainValueDto({ CodeValue: "AR2023_BO_T3_S1_Item1", NameValue: "AR2023_BO_T3_S1_Item1", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "AR2023_BO_T3_S1_Item2", NameValue: "AR2023_BO_T3_S1_Item2", DisplayValue: "", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "AR2023_BO_T3_S1_Item3", NameValue: "AR2023_BO_T3_S1_Item3", DisplayValue: "", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "AR2023_BO_T3_S1_Item4", NameValue: "AR2023_BO_T3_S1_Item4", DisplayValue: "", SequenceNumber: 4}),
            new DomainValueDto({ CodeValue: "AR2023_BO_T3_S1_Item5", NameValue: "AR2023_BO_T3_S1_Item5", DisplayValue: "", SequenceNumber: 5 })            
        ]
    };
	public static ANZPACAR2023BOT4 = {
        ANZPACAR2023BOT4S1: "ANZPACAR2023_BO_T4_S1",
        Values : [
            new DomainValueDto({ CodeValue: "ANZPACAR2023_BO_T4_S1", NameValue: "ANZPACAR2023_BO_T4_S1", DisplayValue: "", SequenceNumber: 1 })            
        ]
    };
	public static ANZPACAR2023BOT4S1 = {
        AR2023BOT4S1item1: "AR2023_BO_T4_S1_item1",
        AR2023BOT4S1item2: "AR2023_BO_T4_S1_item2",
        AR2023BOT4S1item3: "AR2023_BO_T4_S1_item3",
        AR2023BOT4S1item4: "AR2023_BO_T4_S1_item4",
        Values : [
            new DomainValueDto({ CodeValue: "AR2023_BO_T4_S1_item1", NameValue: "AR2023_BO_T4_S1_item1", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "AR2023_BO_T4_S1_item2", NameValue: "AR2023_BO_T4_S1_item2", DisplayValue: "", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "AR2023_BO_T4_S1_item3", NameValue: "AR2023_BO_T4_S1_item3", DisplayValue: "", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "AR2023_BO_T4_S1_item4", NameValue: "AR2023_BO_T4_S1_item4", DisplayValue: "", SequenceNumber: 4 })            
        ]
    };
	public static ANZPACAR2023RAU = {
        ANZPACAR2023RAUT3: "ANZPACAR2023_RAU_T3",
        ANZPACAR2023RAUT4: "ANZPACAR2023_RAU_T4",
        Values : [
            new DomainValueDto({ CodeValue: "ANZPACAR2023_RAU_T3", NameValue: "ANZPACAR2023_RAU_T3", DisplayValue: "", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "ANZPACAR2023_RAU_T4", NameValue: "ANZPACAR2023_RAU_T4", DisplayValue: "", SequenceNumber: 4 })            
        ]
    };
	public static ANZPACAR2023RAUBLM = {
        AR2023RAUBLMItem1: "AR2023_RAU_BLM_Item1",
        AR2023RAUBLMItem2: "AR2023_RAU_BLM_Item2",
        AR2023RAUBLMItem3: "AR2023_RAU_BLM_Item3",
        AR2023RAUBLMItem4: "AR2023_RAU_BLM_Item4",
        AR2023RAUBLMItem5: "AR2023_RAU_BLM_Item5",
        AR2023RAUBLMItem6: "AR2023_RAU_BLM_Item6",
        AR2023RAUBLMItem7: "AR2023_RAU_BLM_Item7",
        AR2023RAUBLMItem8: "AR2023_RAU_BLM_Item8",
        AR2023RAUBLMItem9: "AR2023_RAU_BLM_Item9",
        AR2023RAUBLMItem10: "AR2023_RAU_BLM_Item10",
        AR2023RAUBLMItem11: "AR2023_RAU_BLM_Item11",
        AR2023RAUBLMItem12: "AR2023_RAU_BLM_Item12",
        AR2023RAUBLMItem13: "AR2023_RAU_BLM_Item13",
        AR2023RAUBLMItem17: "AR2023_RAU_BLM_Item17",
        AR2023RAUBLMItem18: "AR2023_RAU_BLM_Item18",
        AR2023RAUBLMItem19: "AR2023_RAU_BLM_Item19",
        Values : [
            new DomainValueDto({ CodeValue: "AR2023_RAU_BLM_Item1", NameValue: "AR2023_RAU_BLM_Item1", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "AR2023_RAU_BLM_Item2", NameValue: "AR2023_RAU_BLM_Item2", DisplayValue: "", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "AR2023_RAU_BLM_Item3", NameValue: "AR2023_RAU_BLM_Item3", DisplayValue: "", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "AR2023_RAU_BLM_Item4", NameValue: "AR2023_RAU_BLM_Item4", DisplayValue: "", SequenceNumber: 4}),
            new DomainValueDto({ CodeValue: "AR2023_RAU_BLM_Item5", NameValue: "AR2023_RAU_BLM_Item5", DisplayValue: "", SequenceNumber: 5}),
            new DomainValueDto({ CodeValue: "AR2023_RAU_BLM_Item6", NameValue: "AR2023_RAU_BLM_Item6", DisplayValue: "", SequenceNumber: 6}),
            new DomainValueDto({ CodeValue: "AR2023_RAU_BLM_Item7", NameValue: "AR2023_RAU_BLM_Item7", DisplayValue: "", SequenceNumber: 7}),
            new DomainValueDto({ CodeValue: "AR2023_RAU_BLM_Item8", NameValue: "AR2023_RAU_BLM_Item8", DisplayValue: "", SequenceNumber: 8}),
            new DomainValueDto({ CodeValue: "AR2023_RAU_BLM_Item9", NameValue: "AR2023_RAU_BLM_Item9", DisplayValue: "", SequenceNumber: 9}),
            new DomainValueDto({ CodeValue: "AR2023_RAU_BLM_Item10", NameValue: "AR2023_RAU_BLM_Item10", DisplayValue: "", SequenceNumber: 10}),
            new DomainValueDto({ CodeValue: "AR2023_RAU_BLM_Item11", NameValue: "AR2023_RAU_BLM_Item11", DisplayValue: "", SequenceNumber: 11}),
            new DomainValueDto({ CodeValue: "AR2023_RAU_BLM_Item12", NameValue: "AR2023_RAU_BLM_Item12", DisplayValue: "", SequenceNumber: 12}),
            new DomainValueDto({ CodeValue: "AR2023_RAU_BLM_Item13", NameValue: "AR2023_RAU_BLM_Item13", DisplayValue: "", SequenceNumber: 13}),
            new DomainValueDto({ CodeValue: "AR2023_RAU_BLM_Item17", NameValue: "AR2023_RAU_BLM_Item17", DisplayValue: "", SequenceNumber: 17}),
            new DomainValueDto({ CodeValue: "AR2023_RAU_BLM_Item18", NameValue: "AR2023_RAU_BLM_Item18", DisplayValue: "", SequenceNumber: 18}),
            new DomainValueDto({ CodeValue: "AR2023_RAU_BLM_Item19", NameValue: "AR2023_RAU_BLM_Item19", DisplayValue: "", SequenceNumber: 19 })            
        ]
    };
	public static ANZPACAR2023RAUT3 = {
        ANZPACAR2023RAUT3S1: "ANZPACAR2023_RAU_T3_S1",
        ANZPACAR2023RAUT3S2: "ANZPACAR2023_RAU_T3_S2",
        ANZPACAR2023RAUT3S3: "ANZPACAR2023_RAU_T3_S3",
        Values : [
            new DomainValueDto({ CodeValue: "ANZPACAR2023_RAU_T3_S1", NameValue: "ANZPACAR2023_RAU_T3_S1", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "ANZPACAR2023_RAU_T3_S2", NameValue: "ANZPACAR2023_RAU_T3_S2", DisplayValue: "", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "ANZPACAR2023_RAU_T3_S3", NameValue: "ANZPACAR2023_RAU_T3_S3", DisplayValue: "", SequenceNumber: 3 })            
        ]
    };
	public static ANZPACAR2023RAUT3S1 = {
        AR2023RMST3S1Item1: "AR2023_RMS_T3_S1_Item1",
        AR2023RMST3S1Item2: "AR2023_RMS_T3_S1_Item2",
        AR2023RMST3S1Item3: "AR2023_RMS_T3_S1_Item3",
        Values : [
            new DomainValueDto({ CodeValue: "AR2023_RMS_T3_S1_Item1", NameValue: "AR2023_RMS_T3_S1_Item1", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "AR2023_RMS_T3_S1_Item2", NameValue: "AR2023_RMS_T3_S1_Item2", DisplayValue: "", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "AR2023_RMS_T3_S1_Item3", NameValue: "AR2023_RMS_T3_S1_Item3", DisplayValue: "", SequenceNumber: 3 })            
        ]
    };
	public static ANZPACAR2023RAUT3S2 = {
        AR2023RMST3S2Item1: "AR2023_RMS_T3_S2_Item1",
        AR2023RMST3S2Item2: "AR2023_RMS_T3_S2_Item2",
        AR2023RMST3S2Item3: "AR2023_RMS_T3_S2_Item3",
        AR2023RMST3S2Item4: "AR2023_RMS_T3_S2_Item4",
        Values : [
            new DomainValueDto({ CodeValue: "AR2023_RMS_T3_S2_Item1", NameValue: "AR2023_RMS_T3_S2_Item1", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "AR2023_RMS_T3_S2_Item2", NameValue: "AR2023_RMS_T3_S2_Item2", DisplayValue: "", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "AR2023_RMS_T3_S2_Item3", NameValue: "AR2023_RMS_T3_S2_Item3", DisplayValue: "", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "AR2023_RMS_T3_S2_Item4", NameValue: "AR2023_RMS_T3_S2_Item4", DisplayValue: "", SequenceNumber: 4 })            
        ]
    };
	public static ANZPACAR2023RAUT3S3 = {
        AR2023RMST3S3Item1: "AR2023_RMS_T3_S3_Item1",
        AR2023RMST3S3Item2: "AR2023_RMS_T3_S3_Item2",
        AR2023RMST3S3Item3: "AR2023_RMS_T3_S3_Item3",
        AR2023RMST3S3Item4: "AR2023_RMS_T3_S3_Item4",
        AR2023RMST3S3Item6: "AR2023_RMS_T3_S3_Item6",
        Values : [
            new DomainValueDto({ CodeValue: "AR2023_RMS_T3_S3_Item1", NameValue: "AR2023_RMS_T3_S3_Item1", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "AR2023_RMS_T3_S3_Item2", NameValue: "AR2023_RMS_T3_S3_Item2", DisplayValue: "", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "AR2023_RMS_T3_S3_Item3", NameValue: "AR2023_RMS_T3_S3_Item3", DisplayValue: "", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "AR2023_RMS_T3_S3_Item4", NameValue: "AR2023_RMS_T3_S3_Item4", DisplayValue: "", SequenceNumber: 4}),
            new DomainValueDto({ CodeValue: "AR2023_RMS_T3_S3_Item6", NameValue: "AR2023_RMS_T3_S3_Item6", DisplayValue: "", SequenceNumber: 5 })            
        ]
    };
	public static ANZPACAR2023RAUT4 = {
        ANZPACAR2023RAUT4S1: "ANZPACAR2023_RAU_T4_S1",
        Values : [
            new DomainValueDto({ CodeValue: "ANZPACAR2023_RAU_T4_S1", NameValue: "ANZPACAR2023_RAU_T4_S1", DisplayValue: "", SequenceNumber: 1 })            
        ]
    };
	public static ANZPACAR2023RAUT4S1 = {
        AR2023RMST4S1Item1: "AR2023_RMS_T4_S1_Item1",
        AR2023RMST4S1Item2: "AR2023_RMS_T4_S1_Item2",
        Values : [
            new DomainValueDto({ CodeValue: "AR2023_RMS_T4_S1_Item1", NameValue: "AR2023_RMS_T4_S1_Item1", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "AR2023_RMS_T4_S1_Item2", NameValue: "AR2023_RMS_T4_S1_Item2", DisplayValue: "", SequenceNumber: 2 })            
        ]
    };
	public static ANZPACAR2023RMS = {
        ANZPACAR2023RMST3: "ANZPACAR2023_RMS_T3",
        ANZPACAR2023RMST4: "ANZPACAR2023_RMS_T4",
        Values : [
            new DomainValueDto({ CodeValue: "ANZPACAR2023_RMS_T3", NameValue: "ANZPACAR2023_RMS_T3", DisplayValue: "", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "ANZPACAR2023_RMS_T4", NameValue: "ANZPACAR2023_RMS_T4", DisplayValue: "", SequenceNumber: 4 })            
        ]
    };
	public static ANZPACAR2023RMSBLM = {
        AR2023RMSBLMItem1: "AR2023_RMS_BLM_Item1",
        AR2023RMSBLMItem2: "AR2023_RMS_BLM_Item2",
        AR2023RMSBLMItem3: "AR2023_RMS_BLM_Item3",
        AR2023RMSBLMItem4: "AR2023_RMS_BLM_Item4",
        AR2023RMSBLMItem5: "AR2023_RMS_BLM_Item5",
        AR2023RMSBLMItem6: "AR2023_RMS_BLM_Item6",
        AR2023RMSBLMItem7: "AR2023_RMS_BLM_Item7",
        AR2023RMSBLMItem8: "AR2023_RMS_BLM_Item8",
        AR2023RMSBLMItem9: "AR2023_RMS_BLM_Item9",
        AR2023RMSBLMItem10: "AR2023_RMS_BLM_Item10",
        Values : [
            new DomainValueDto({ CodeValue: "AR2023_RMS_BLM_Item1", NameValue: "AR2023_RMS_BLM_Item1", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "AR2023_RMS_BLM_Item2", NameValue: "AR2023_RMS_BLM_Item2", DisplayValue: "", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "AR2023_RMS_BLM_Item3", NameValue: "AR2023_RMS_BLM_Item3", DisplayValue: "", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "AR2023_RMS_BLM_Item4", NameValue: "AR2023_RMS_BLM_Item4", DisplayValue: "", SequenceNumber: 4}),
            new DomainValueDto({ CodeValue: "AR2023_RMS_BLM_Item5", NameValue: "AR2023_RMS_BLM_Item5", DisplayValue: "", SequenceNumber: 5}),
            new DomainValueDto({ CodeValue: "AR2023_RMS_BLM_Item6", NameValue: "AR2023_RMS_BLM_Item6", DisplayValue: "", SequenceNumber: 6}),
            new DomainValueDto({ CodeValue: "AR2023_RMS_BLM_Item7", NameValue: "AR2023_RMS_BLM_Item7", DisplayValue: "", SequenceNumber: 7}),
            new DomainValueDto({ CodeValue: "AR2023_RMS_BLM_Item8", NameValue: "AR2023_RMS_BLM_Item8", DisplayValue: "", SequenceNumber: 8}),
            new DomainValueDto({ CodeValue: "AR2023_RMS_BLM_Item9", NameValue: "AR2023_RMS_BLM_Item9", DisplayValue: "", SequenceNumber: 9}),
            new DomainValueDto({ CodeValue: "AR2023_RMS_BLM_Item10", NameValue: "AR2023_RMS_BLM_Item10", DisplayValue: "", SequenceNumber: 10 })            
        ]
    };
	public static ANZPACAR2023RMST3 = {
        ANZPACAR2023RMST3S1: "ANZPACAR2023_RMS_T3_S1",
        ANZPACAR2023RMST3S3: "ANZPACAR2023_RMS_T3_S3",
        Values : [
            new DomainValueDto({ CodeValue: "ANZPACAR2023_RMS_T3_S1", NameValue: "ANZPACAR2023_RMS_T3_S1", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "ANZPACAR2023_RMS_T3_S3", NameValue: "ANZPACAR2023_RMS_T3_S3", DisplayValue: "", SequenceNumber: 3 })            
        ]
    };
	public static ANZPACAR2023RMST3S1 = {
        AR2023RMST3S1Item1: "AR2023_RMS_T3_S1_Item1",
        AR2023RMST3S1Item2: "AR2023_RMS_T3_S1_Item2",
        AR2023RMST3S1Item3: "AR2023_RMS_T3_S1_Item3",
        AR2023RMST3S1Item4: "AR2023_RMS_T3_S1_Item4",
        AR2023RMST3S1Item5: "AR2023_RMS_T3_S1_Item5",
        Values : [
            new DomainValueDto({ CodeValue: "AR2023_RMS_T3_S1_Item1", NameValue: "AR2023_RMS_T3_S1_Item1", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "AR2023_RMS_T3_S1_Item2", NameValue: "AR2023_RMS_T3_S1_Item2", DisplayValue: "", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "AR2023_RMS_T3_S1_Item3", NameValue: "AR2023_RMS_T3_S1_Item3", DisplayValue: "", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "AR2023_RMS_T3_S1_Item4", NameValue: "AR2023_RMS_T3_S1_Item4", DisplayValue: "", SequenceNumber: 4}),
            new DomainValueDto({ CodeValue: "AR2023_RMS_T3_S1_Item5", NameValue: "AR2023_RMS_T3_S1_Item5", DisplayValue: "", SequenceNumber: 5 })            
        ]
    };
	public static ANZPACAR2023RMST3S3 = {
        AR2023RMST3S3Item1: "AR2023_RMS_T3_S3_Item1",
        AR2023RMST3S3Item2: "AR2023_RMS_T3_S3_Item2",
        AR2023RMST3S3Item3: "AR2023_RMS_T3_S3_Item3",
        AR2023RMST3S3Item4: "AR2023_RMS_T3_S3_Item4",
        AR2023RMST3S3Item5: "AR2023_RMS_T3_S3_Item5",
        Values : [
            new DomainValueDto({ CodeValue: "AR2023_RMS_T3_S3_Item1", NameValue: "AR2023_RMS_T3_S3_Item1", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "AR2023_RMS_T3_S3_Item2", NameValue: "AR2023_RMS_T3_S3_Item2", DisplayValue: "", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "AR2023_RMS_T3_S3_Item3", NameValue: "AR2023_RMS_T3_S3_Item3", DisplayValue: "", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "AR2023_RMS_T3_S3_Item4", NameValue: "AR2023_RMS_T3_S3_Item4", DisplayValue: "", SequenceNumber: 4}),
            new DomainValueDto({ CodeValue: "AR2023_RMS_T3_S3_Item5", NameValue: "AR2023_RMS_T3_S3_Item5", DisplayValue: "", SequenceNumber: 6 })            
        ]
    };
	public static ANZPACAR2023RMST4 = {
        ANZPACAR2023RMST4S1: "ANZPACAR2023_RMS_T4_S1",
        Values : [
            new DomainValueDto({ CodeValue: "ANZPACAR2023_RMS_T4_S1", NameValue: "ANZPACAR2023_RMS_T4_S1", DisplayValue: "", SequenceNumber: 1 })            
        ]
    };
	public static ANZPACAR2023RMST4S1 = {
        AR2023RMST4S1Item1: "AR2023_RMS_T4_S1_Item1",
        AR2023RMST4S1Item2: "AR2023_RMS_T4_S1_Item2",
        AR2023RMST4S1Item3: "AR2023_RMS_T4_S1_Item3",
        AR2023RMST4S1Item4: "AR2023_RMS_T4_S1_Item4",
        Values : [
            new DomainValueDto({ CodeValue: "AR2023_RMS_T4_S1_Item1", NameValue: "AR2023_RMS_T4_S1_Item1", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "AR2023_RMS_T4_S1_Item2", NameValue: "AR2023_RMS_T4_S1_Item2", DisplayValue: "", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "AR2023_RMS_T4_S1_Item3", NameValue: "AR2023_RMS_T4_S1_Item3", DisplayValue: "", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "AR2023_RMS_T4_S1_Item4", NameValue: "AR2023_RMS_T4_S1_Item4", DisplayValue: "", SequenceNumber: 4 })            
        ]
    };
	public static ANZPACAR2023SM = {
        ANZPACAR2023SMT1: "ANZPACAR2023_SM_T1",
        ANZPACAR2023SMT2: "ANZPACAR2023_SM_T2",
        Values : [
            new DomainValueDto({ CodeValue: "ANZPACAR2023_SM_T1", NameValue: "ANZPACAR2023_SM_T1", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "ANZPACAR2023_SM_T2", NameValue: "ANZPACAR2023_SM_T2", DisplayValue: "", SequenceNumber: 2 })            
        ]
    };
	public static ANZPACAR2023SMBLM = {
        AR2023SMBLMItem1: "AR2023_SM_BLM_Item1",
        AR2023SMBLMItem2: "AR2023_SM_BLM_Item2",
        AR2023SMBLMItem3: "AR2023_SM_BLM_Item3",
        AR2023SMBLMItem4: "AR2023_SM_BLM_Item4",
        AR2023SMBLMItem5: "AR2023_SM_BLM_Item5",
        AR2023SMBLMItem6: "AR2023_SM_BLM_Item6",
        AR2023SMBLMItem7: "AR2023_SM_BLM_Item7",
        AR2023SMBLMItem8: "AR2023_SM_BLM_Item8",
        AR2023SMBLMItem9: "AR2023_SM_BLM_Item9",
        AR2023SMBLMItem10: "AR2023_SM_BLM_Item10",
        AR2023SMBLMItem11: "AR2023_SM_BLM_Item11",
        AR2023SMBLMItem12: "AR2023_SM_BLM_Item12",
        AR2023SMBLMItem13: "AR2023_SM_BLM_Item13",
        AR2023SMBLMItem17: "AR2023_SM_BLM_Item17",
        AR2023SMBLMItem18: "AR2023_SM_BLM_Item18",
        AR2023SMBLMItem19: "AR2023_SM_BLM_Item19",
        Values : [
            new DomainValueDto({ CodeValue: "AR2023_SM_BLM_Item1", NameValue: "AR2023_SM_BLM_Item1", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "AR2023_SM_BLM_Item2", NameValue: "AR2023_SM_BLM_Item2", DisplayValue: "", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "AR2023_SM_BLM_Item3", NameValue: "AR2023_SM_BLM_Item3", DisplayValue: "", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "AR2023_SM_BLM_Item4", NameValue: "AR2023_SM_BLM_Item4", DisplayValue: "", SequenceNumber: 4}),
            new DomainValueDto({ CodeValue: "AR2023_SM_BLM_Item5", NameValue: "AR2023_SM_BLM_Item5", DisplayValue: "", SequenceNumber: 5}),
            new DomainValueDto({ CodeValue: "AR2023_SM_BLM_Item6", NameValue: "AR2023_SM_BLM_Item6", DisplayValue: "", SequenceNumber: 6}),
            new DomainValueDto({ CodeValue: "AR2023_SM_BLM_Item7", NameValue: "AR2023_SM_BLM_Item7", DisplayValue: "", SequenceNumber: 7}),
            new DomainValueDto({ CodeValue: "AR2023_SM_BLM_Item8", NameValue: "AR2023_SM_BLM_Item8", DisplayValue: "", SequenceNumber: 8}),
            new DomainValueDto({ CodeValue: "AR2023_SM_BLM_Item9", NameValue: "AR2023_SM_BLM_Item9", DisplayValue: "", SequenceNumber: 9}),
            new DomainValueDto({ CodeValue: "AR2023_SM_BLM_Item10", NameValue: "AR2023_SM_BLM_Item10", DisplayValue: "", SequenceNumber: 10}),
            new DomainValueDto({ CodeValue: "AR2023_SM_BLM_Item11", NameValue: "AR2023_SM_BLM_Item11", DisplayValue: "", SequenceNumber: 11}),
            new DomainValueDto({ CodeValue: "AR2023_SM_BLM_Item12", NameValue: "AR2023_SM_BLM_Item12", DisplayValue: "", SequenceNumber: 12}),
            new DomainValueDto({ CodeValue: "AR2023_SM_BLM_Item13", NameValue: "AR2023_SM_BLM_Item13", DisplayValue: "", SequenceNumber: 13}),
            new DomainValueDto({ CodeValue: "AR2023_SM_BLM_Item17", NameValue: "AR2023_SM_BLM_Item17", DisplayValue: "", SequenceNumber: 17}),
            new DomainValueDto({ CodeValue: "AR2023_SM_BLM_Item18", NameValue: "AR2023_SM_BLM_Item18", DisplayValue: "", SequenceNumber: 18}),
            new DomainValueDto({ CodeValue: "AR2023_SM_BLM_Item19", NameValue: "AR2023_SM_BLM_Item19", DisplayValue: "", SequenceNumber: 19 })            
        ]
    };
	public static ANZPACAR2023SMT1 = {
        ANZPACAR2023SMT1S1: "ANZPACAR2023_SM_T1_S1",
        Values : [
            new DomainValueDto({ CodeValue: "ANZPACAR2023_SM_T1_S1", NameValue: "ANZPACAR2023_SM_T1_S1", DisplayValue: "", SequenceNumber: 1 })            
        ]
    };
	public static ANZPACAR2023SMT1S1 = {
        AR2023SMT1S1Item1: "AR2023_SM_T1_S1_Item1",
        AR2023SMT1S1Item12: "AR2023_SM_T1_S1_Item2",
        AR2023SMT1S1Item3: "AR2023_SM_T1_S1_Item3",
        AR2023SMT1S1Item4: "AR2023_SM_T1_S1_Item4",
        AR2023SMT1S1Item5: "AR2023_SM_T1_S1_Item5",
        AR2023SMT1S1Item6: "AR2023_SM_T1_S1_Item6",
        AR2023SMT1S1Item7: "AR2023_SM_T1_S1_Item7",
        AR2023SMT1S1Item8: "AR2023_SM_T1_S1_Item8",
        AR2023SMT1S1Item9: "AR2023_SM_T1_S1_Item9",
        Values : [
            new DomainValueDto({ CodeValue: "AR2023_SM_T1_S1_Item1", NameValue: "AR2023_SM_T1_S1_Item1", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "AR2023_SM_T1_S1_Item2", NameValue: "AR2023_SM_T1_S1_Item12", DisplayValue: "", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "AR2023_SM_T1_S1_Item3", NameValue: "AR2023_SM_T1_S1_Item3", DisplayValue: "", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "AR2023_SM_T1_S1_Item4", NameValue: "AR2023_SM_T1_S1_Item4", DisplayValue: "", SequenceNumber: 4}),
            new DomainValueDto({ CodeValue: "AR2023_SM_T1_S1_Item5", NameValue: "AR2023_SM_T1_S1_Item5", DisplayValue: "", SequenceNumber: 5}),
            new DomainValueDto({ CodeValue: "AR2023_SM_T1_S1_Item6", NameValue: "AR2023_SM_T1_S1_Item6", DisplayValue: "", SequenceNumber: 6}),
            new DomainValueDto({ CodeValue: "AR2023_SM_T1_S1_Item7", NameValue: "AR2023_SM_T1_S1_Item7", DisplayValue: "", SequenceNumber: 7}),
            new DomainValueDto({ CodeValue: "AR2023_SM_T1_S1_Item8", NameValue: "AR2023_SM_T1_S1_Item8", DisplayValue: "", SequenceNumber: 8}),
            new DomainValueDto({ CodeValue: "AR2023_SM_T1_S1_Item9", NameValue: "AR2023_SM_T1_S1_Item9", DisplayValue: "", SequenceNumber: 9 })            
        ]
    };
	public static ANZPACAR2023SMT2 = {
        ANZPACAR2023SMT2S1: "ANZPACAR2023_SM_T2_S1",
        ANZPACAR2023SMT2S5: "ANZPACAR2023_SM_T2_S5",
        Values : [
            new DomainValueDto({ CodeValue: "ANZPACAR2023_SM_T2_S1", NameValue: "ANZPACAR2023_SM_T2_S1", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "ANZPACAR2023_SM_T2_S5", NameValue: "ANZPACAR2023_SM_T2_S5", DisplayValue: "", SequenceNumber: 2 })            
        ]
    };
	public static ANZPACAR2023SMT2S5 = {
        AR2023SMT2S5Item1: "AR2023_SM_T2_S5_Item1",
        AR2023SMT2S5Item2: "AR2023_SM_T2_S5_Item2",
        AR2023SMT2S5Item3: "AR2023_SM_T2_S5_Item3",
        Values : [
            new DomainValueDto({ CodeValue: "AR2023_SM_T2_S5_Item1", NameValue: "AR2023_SM_T2_S5_Item1", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "AR2023_SM_T2_S5_Item2", NameValue: "AR2023_SM_T2_S5_Item2", DisplayValue: "", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "AR2023_SM_T2_S5_Item3", NameValue: "AR2023_SM_T2_S5_Item3", DisplayValue: "", SequenceNumber: 3 })            
        ]
    };
	public static ANZPACAR2023AccuracyLevel = {
        Lowguess: "ANZPACAR2023AccuracyLevelLow",
        Mediuminformedestimate: "ANZPACAR2023AccuracyLevelMedium",
        Highmeasured: "ANZPACAR2023AccuracyLevelHigh",
        Values : [
            new DomainValueDto({ CodeValue: "ANZPACAR2023AccuracyLevelLow", NameValue: "Low (guess)", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "ANZPACAR2023AccuracyLevelMedium", NameValue: "Medium (informed estimate)", DisplayValue: "", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "ANZPACAR2023AccuracyLevelHigh", NameValue: "High (measured)", DisplayValue: "", SequenceNumber: 3 })            
        ]
    };
	public static ARLImage = {
        Recyclable: "Recyclable",
        Recyclablewithlostvalue: "RecyclableWithLostValue",
        ConditionallyRecyclable: "ConditionallyRecyclable",
        RecyclableMustinstructtorinse: "RecyclableRinse",
        ConditionallyRecyclableMustinstructtorinse: "ConditionallyRecyclableRinse",
        ConditionallyRecyclableLostValue: "ConditionallyRecyclableLostValue",
        NotRecyclable: "NotRecyclable",
        NotRecyclableNotCollectedbycouncils: "NotRecyclableNotCollected",
        UnderReview: "UnderReview",
        NotTechnicallyRecyclable: "NotTechnicallyRecyclable",
        LabTestRequired: "LabTestRequired",
        UnderTACReview: "UnderTACReview",
        Incomplete: "Incomplete",
        Unavailable: "Unavailable",
        CheckLocally: "CheckLocally",
        UrlOnly: "UrlOnly",
        UrlTextSide: "UrlTextSide",
        Values : [
            new DomainValueDto({ CodeValue: "Recyclable", NameValue: "Recyclable", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "RecyclableWithLostValue", NameValue: "Recyclable with lost value", DisplayValue: "", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "ConditionallyRecyclable", NameValue: "Conditionally Recyclable", DisplayValue: "", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "RecyclableRinse", NameValue: "Recyclable - Must instruct to rinse", DisplayValue: "", SequenceNumber: 4}),
            new DomainValueDto({ CodeValue: "ConditionallyRecyclableRinse", NameValue: "Conditionally Recyclable - Must instruct to rinse ", DisplayValue: "", SequenceNumber: 5}),
            new DomainValueDto({ CodeValue: "ConditionallyRecyclableLostValue", NameValue: "Conditionally Recyclable - Lost Value", DisplayValue: "", SequenceNumber: 6}),
            new DomainValueDto({ CodeValue: "NotRecyclable", NameValue: "Not Recyclable", DisplayValue: "", SequenceNumber: 7}),
            new DomainValueDto({ CodeValue: "NotRecyclableNotCollected", NameValue: "Not Recyclable - Not Collected by councils", DisplayValue: "", SequenceNumber: 8}),
            new DomainValueDto({ CodeValue: "UnderReview", NameValue: "Under Review", DisplayValue: "", SequenceNumber: 9}),
            new DomainValueDto({ CodeValue: "NotTechnicallyRecyclable", NameValue: "Not Technically Recyclable", DisplayValue: "", SequenceNumber: 10}),
            new DomainValueDto({ CodeValue: "LabTestRequired", NameValue: "Lab Test Required", DisplayValue: "", SequenceNumber: 11}),
            new DomainValueDto({ CodeValue: "UnderTACReview", NameValue: "Under TAC Review", DisplayValue: "", SequenceNumber: 12}),
            new DomainValueDto({ CodeValue: "Incomplete", NameValue: "Incomplete", DisplayValue: "", SequenceNumber: 13}),
            new DomainValueDto({ CodeValue: "Unavailable", NameValue: "Unavailable", DisplayValue: "", SequenceNumber: 14}),
            new DomainValueDto({ CodeValue: "CheckLocally", NameValue: "Check Locally", DisplayValue: "", SequenceNumber: 15}),
            new DomainValueDto({ CodeValue: "UrlOnly", NameValue: "Url Only", DisplayValue: "", SequenceNumber: 50}),
            new DomainValueDto({ CodeValue: "UrlTextSide", NameValue: "Url Text Side", DisplayValue: "", SequenceNumber: 51 })            
        ]
    };
	public static ARLImageText = {
        checklocally: "check_locally",
        emptytorecycle: "empty_to_recycle",
        scrunchintoball: "scrunch_into_ball",
        makeintoball: "make_into_ball",
        foldtorecycle: "fold_to_recycle",
        leaveattached: "leave_attached",
        separatetorecycle: "separate_to_recycle",
        flattentorecycle: "flatten_to_recycle",
        placeinusedenvelope: "place_in_used_envelope",
        returntostore: "return_to_store",
        storedropoff: "store_drop_off",
        removeclipreturntostore: "remove_clip_return_to_store",
        removeclipstoredropoff: "remove_clip_store_drop_off",
        rinseandreturntostore: "rinse_and_return_to_store",
        rinseandstoredropoff: "rinse_and_store_drop_off",
        crushbottleandreplacecap: "crush_bottle_and_replace_cap",
        Values : [
            new DomainValueDto({ CodeValue: "check_locally", NameValue: "check_locally", DisplayValue: "Check Locally", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "empty_to_recycle", NameValue: "empty_to_recycle", DisplayValue: "Empty to recycle", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "scrunch_into_ball", NameValue: "scrunch_into_ball", DisplayValue: "Scrunch into ball", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "make_into_ball", NameValue: "make_into_ball", DisplayValue: "Make into ball", SequenceNumber: 4}),
            new DomainValueDto({ CodeValue: "fold_to_recycle", NameValue: "fold_to_recycle", DisplayValue: "Fold to recycle", SequenceNumber: 5}),
            new DomainValueDto({ CodeValue: "leave_attached", NameValue: "leave_attached", DisplayValue: "Leave attached", SequenceNumber: 6}),
            new DomainValueDto({ CodeValue: "separate_to_recycle", NameValue: "separate_to_recycle", DisplayValue: "Separate to recycle", SequenceNumber: 7}),
            new DomainValueDto({ CodeValue: "flatten_to_recycle", NameValue: "flatten_to_recycle", DisplayValue: "Flatten to recycle", SequenceNumber: 8}),
            new DomainValueDto({ CodeValue: "place_in_used_envelope", NameValue: "place_in_used_envelope", DisplayValue: "Place in used envelope", SequenceNumber: 9}),
            new DomainValueDto({ CodeValue: "return_to_store", NameValue: "return_to_store", DisplayValue: "Return to store", SequenceNumber: 10}),
            new DomainValueDto({ CodeValue: "store_drop_off", NameValue: "store_drop_off", DisplayValue: "Store drop off", SequenceNumber: 11}),
            new DomainValueDto({ CodeValue: "remove_clip_return_to_store", NameValue: "remove_clip_return_to_store", DisplayValue: "Remove clip & return to store", SequenceNumber: 12}),
            new DomainValueDto({ CodeValue: "remove_clip_store_drop_off", NameValue: "remove_clip_store_drop_off", DisplayValue: "Remove clip & store drop off", SequenceNumber: 13}),
            new DomainValueDto({ CodeValue: "rinse_and_return_to_store", NameValue: "rinse_and_return_to_store", DisplayValue: "Rinse & return to store", SequenceNumber: 14}),
            new DomainValueDto({ CodeValue: "rinse_and_store_drop_off", NameValue: "rinse_and_store_drop_off", DisplayValue: "Rinse & store drop off", SequenceNumber: 15}),
            new DomainValueDto({ CodeValue: "crush_bottle_and_replace_cap", NameValue: "crush_bottle_and_replace_cap", DisplayValue: "Crush bottle & replace cap", SequenceNumber: 16 })            
        ]
    };
	public static ARLMProductStatus = {
        Archived: "archived",
        Active: "active",
        Values : [
            new DomainValueDto({ CodeValue: "archived", NameValue: "Archived", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "active", NameValue: "Active", DisplayValue: "", SequenceNumber: 2 })            
        ]
    };
	public static ARLMProjectStatus = {
        DesignFinalised: "design_finalised",
        Draft: "draft",
        InMarket: "in_market",
        Removed: "removed",
        RemovedFromMarket: "removed_from_market",
        Archived: "archived",
        InReview: "in_review",
        Values : [
            new DomainValueDto({ CodeValue: "design_finalised", NameValue: "Design Finalised", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "draft", NameValue: "Draft", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "in_market", NameValue: "In Market", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "removed", NameValue: "Removed", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "removed_from_market", NameValue: "Removed From Market", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "archived", NameValue: "Archived", DisplayValue: "", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "in_review", NameValue: "In Review", DisplayValue: "", SequenceNumber: 2 })            
        ]
    };
	public static ARLRequestSources = {
        CreateOrganisation: "CreateOrg",
        UpdateOrganisation: "UpdOrg",
        SuspendOrganisation: "SuspendOrg",
        ReinstateOrganisation: "ReinstateOrg",
        DeleteOrganisation: "DelOrg",
        CreateUser: "CreateUser",
        UpdateUser: "UpdUser",
        DeactivateUser: "DeactivateUser",
        ActivateUser: "ActivateUser",
        DeleteUser: "DelUser",
        CreateProject: "CreateProj",
        UpdateProject: "UpdProj",
        EvaluateProject: "EvalProj",
        ArchiveProject: "ArchiveProj",
        DeleteProject: "DelProj",
        UnarchiveProject: "UnarchiveProj",
        ARLMCheckARL: "ARLMCheckARL",
        ARLMActivateARL: "ARLMActivateARL",
        OrganisationCheckARL: "OrgCheckARL",
        OrganisationActivateARL: "OrgActivateARL",
        Values : [
            new DomainValueDto({ CodeValue: "CreateOrg", NameValue: "Create Organisation", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "UpdOrg", NameValue: "Update Organisation", DisplayValue: "", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "SuspendOrg", NameValue: "Suspend Organisation", DisplayValue: "", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "ReinstateOrg", NameValue: "Reinstate Organisation", DisplayValue: "", SequenceNumber: 4}),
            new DomainValueDto({ CodeValue: "DelOrg", NameValue: "Delete Organisation", DisplayValue: "", SequenceNumber: 5}),
            new DomainValueDto({ CodeValue: "CreateUser", NameValue: "Create User", DisplayValue: "", SequenceNumber: 10}),
            new DomainValueDto({ CodeValue: "UpdUser", NameValue: "Update User", DisplayValue: "", SequenceNumber: 11}),
            new DomainValueDto({ CodeValue: "DeactivateUser", NameValue: "Deactivate User", DisplayValue: "", SequenceNumber: 12}),
            new DomainValueDto({ CodeValue: "ActivateUser", NameValue: "Activate User", DisplayValue: "", SequenceNumber: 13}),
            new DomainValueDto({ CodeValue: "DelUser", NameValue: "Delete User", DisplayValue: "", SequenceNumber: 14}),
            new DomainValueDto({ CodeValue: "CreateProj", NameValue: "Create Project", DisplayValue: "", SequenceNumber: 20}),
            new DomainValueDto({ CodeValue: "UpdProj", NameValue: "Update Project", DisplayValue: "", SequenceNumber: 21}),
            new DomainValueDto({ CodeValue: "EvalProj", NameValue: "Evaluate Project", DisplayValue: "", SequenceNumber: 22}),
            new DomainValueDto({ CodeValue: "ArchiveProj", NameValue: "Archive Project", DisplayValue: "", SequenceNumber: 23}),
            new DomainValueDto({ CodeValue: "DelProj", NameValue: "Delete Project", DisplayValue: "", SequenceNumber: 24}),
            new DomainValueDto({ CodeValue: "UnarchiveProj", NameValue: "Unarchive Project", DisplayValue: "", SequenceNumber: 24}),
            new DomainValueDto({ CodeValue: "ARLMCheckARL", NameValue: "ARLM Check ARL", DisplayValue: "", SequenceNumber: 30}),
            new DomainValueDto({ CodeValue: "ARLMActivateARL", NameValue: "ARLM Activate ARL", DisplayValue: "", SequenceNumber: 31}),
            new DomainValueDto({ CodeValue: "OrgCheckARL", NameValue: "Organisation Check ARL", DisplayValue: "", SequenceNumber: 32}),
            new DomainValueDto({ CodeValue: "OrgActivateARL", NameValue: "Organisation Activate ARL", DisplayValue: "", SequenceNumber: 33 })            
        ]
    };
	public static AttributeDataSource = {
        ObjectAttributes: "ObjectAttributes",
        Table: "Table",
        Values : [
            new DomainValueDto({ CodeValue: "ObjectAttributes", NameValue: "Object Attributes", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "Table", NameValue: "Table", DisplayValue: "", SequenceNumber: 2 })            
        ]
    };
	public static AttributeType = {
        Group: "Group",
        Field: "Field",
        Values : [
            new DomainValueDto({ CodeValue: "Group", NameValue: "Group", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "Field", NameValue: "Field", DisplayValue: "", SequenceNumber: 2 })            
        ]
    };
	public static AuthenticationClaim = {
        UserId: "UserId",
        DisplayName: "DisplayName",
        AuthorisationLogId: "AuthorisationLogId",
        EnvironmentCode: "EnvironmentCode",
        IsDevice: "IsDevice",
        Values : [
            new DomainValueDto({ CodeValue: "UserId", NameValue: "UserId", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "DisplayName", NameValue: "DisplayName", DisplayValue: "", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "AuthorisationLogId", NameValue: "AuthorisationLogId", DisplayValue: "", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "EnvironmentCode", NameValue: "EnvironmentCode", DisplayValue: "", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "IsDevice", NameValue: "IsDevice", DisplayValue: "", SequenceNumber: 4 })            
        ]
    };
	public static AuthorisationType = {
        Login: "Login",
        AuthorisationCode: "AuthCode",
        Basecamp: "Basecamp",
        ConfirmUser: "ConfirmUser",
        ConfirmEmail: "ConfirmEmail",
        ChangePassword: "ChangePassword",
        AppLogin: "AppLogin",
        Values : [
            new DomainValueDto({ CodeValue: "Login", NameValue: "Login", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "AuthCode", NameValue: "Authorisation Code", DisplayValue: "", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "Basecamp", NameValue: "Basecamp", DisplayValue: "", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "ConfirmUser", NameValue: "Confirm User", DisplayValue: "", SequenceNumber: 4}),
            new DomainValueDto({ CodeValue: "ConfirmEmail", NameValue: "Confirm Email", DisplayValue: "", SequenceNumber: 5}),
            new DomainValueDto({ CodeValue: "ChangePassword", NameValue: "Change Password", DisplayValue: "", SequenceNumber: 6}),
            new DomainValueDto({ CodeValue: "AppLogin", NameValue: "AppLogin", DisplayValue: "Application Login", SequenceNumber: 7 })            
        ]
    };
	public static CMSPageContentType = {
        WebContent: "WebContent",
        SalesforceForm: "SalesforceForm",
        EmailContent: "EmailContent",
        Values : [
            new DomainValueDto({ CodeValue: "WebContent", NameValue: "Web Content", DisplayValue: "Web Content", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "SalesforceForm", NameValue: "Salesforce Form", DisplayValue: "Salesforcce Form", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "EmailContent", NameValue: "Email Content", DisplayValue: "Email Content", SequenceNumber: 3 })            
        ]
    };
	public static DatabaseActionStatus = {
        Create: "Create",
        Creating: "Creating",
        Activated: "Activated",
        Values : [
            new DomainValueDto({ CodeValue: "Create", NameValue: "Create", DisplayValue: "Create", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "Creating", NameValue: "Creating", DisplayValue: "Creating", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "Activated", NameValue: "Activated", DisplayValue: "Activated", SequenceNumber: 3 })            
        ]
    };
	public static DataType = {
        TextType: "Text",
        NumberType: "Number",
        BooleanType: "Boolean",
        DateType: "Date",
        JSONType: "JSON",
        Password: "Password",
        Sequence: "Sequence",
        List: "List",
        Options: "Options",
        RecordId: "RecordId",
        DomainTypeName: "DomainTypeName",
        TableName: "TableName",
        Values : [
            new DomainValueDto({ CodeValue: "Text", NameValue: "Text Type", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "Number", NameValue: "Number Type", DisplayValue: "", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "Boolean", NameValue: "Boolean Type", DisplayValue: "", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "Date", NameValue: "Date Type", DisplayValue: "", SequenceNumber: 4}),
            new DomainValueDto({ CodeValue: "JSON", NameValue: "JSON Type", DisplayValue: "", SequenceNumber: 5}),
            new DomainValueDto({ CodeValue: "Password", NameValue: "Password", DisplayValue: "", SequenceNumber: 6}),
            new DomainValueDto({ CodeValue: "Sequence", NameValue: "Sequence", DisplayValue: "", SequenceNumber: 7}),
            new DomainValueDto({ CodeValue: "List", NameValue: "List", DisplayValue: "", SequenceNumber: 8}),
            new DomainValueDto({ CodeValue: "Options", NameValue: "Options", DisplayValue: "", SequenceNumber: 9}),
            new DomainValueDto({ CodeValue: "RecordId", NameValue: "RecordId", DisplayValue: "", SequenceNumber: 10}),
            new DomainValueDto({ CodeValue: "DomainTypeName", NameValue: "Domain Type Name", DisplayValue: "", SequenceNumber: 11}),
            new DomainValueDto({ CodeValue: "TableName", NameValue: "Table Name", DisplayValue: "", SequenceNumber: 12 })            
        ]
    };
	public static EmailAccountConfiguration = {
        IsIMAP: "IsIMAP",
        IsSmtpSSL: "IsSmtpSSL",
        SmtpHost: "SmtpHost",
        SmtpPort: "SmtpPort",
        SmtpUsername: "SmtpUsername",
        SmtpPassword: "SmtpPassword",
        IsPopSSL: "IsPopSSL",
        PopHost: "PopHost",
        PopPort: "PopPort",
        PopUsername: "PopUsername",
        PopPassword: "PopPassword",
        SenderName: "SenderName",
        SenderEmail: "SenderEmail",
        TenantId: "TenantId",
        ClientId: "ClientId",
        ClientSecret: "ClientSecret",
        Values : [
            new DomainValueDto({ CodeValue: "IsIMAP", NameValue: "Is IMAP", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "IsSmtpSSL", NameValue: "Is Smtp SSL", DisplayValue: "", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "SmtpHost", NameValue: "Smtp Host", DisplayValue: "", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "SmtpPort", NameValue: "Smtp Port", DisplayValue: "", SequenceNumber: 4}),
            new DomainValueDto({ CodeValue: "SmtpUsername", NameValue: "Smtp Username", DisplayValue: "", SequenceNumber: 5}),
            new DomainValueDto({ CodeValue: "SmtpPassword", NameValue: "Smtp Password", DisplayValue: "", SequenceNumber: 6}),
            new DomainValueDto({ CodeValue: "IsPopSSL", NameValue: "Is Pop SSL", DisplayValue: "", SequenceNumber: 7}),
            new DomainValueDto({ CodeValue: "PopHost", NameValue: "Pop Host", DisplayValue: "", SequenceNumber: 8}),
            new DomainValueDto({ CodeValue: "PopPort", NameValue: "Pop Port", DisplayValue: "", SequenceNumber: 9}),
            new DomainValueDto({ CodeValue: "PopUsername", NameValue: "Pop Username", DisplayValue: "", SequenceNumber: 10}),
            new DomainValueDto({ CodeValue: "PopPassword", NameValue: "Pop Password", DisplayValue: "", SequenceNumber: 11}),
            new DomainValueDto({ CodeValue: "SenderName", NameValue: "Sender Name", DisplayValue: "", SequenceNumber: 12}),
            new DomainValueDto({ CodeValue: "SenderEmail", NameValue: "Sender Email", DisplayValue: "", SequenceNumber: 13}),
            new DomainValueDto({ CodeValue: "TenantId", NameValue: "TenantId", DisplayValue: "TenantId", SequenceNumber: 14}),
            new DomainValueDto({ CodeValue: "ClientId", NameValue: "ClientId", DisplayValue: "ClientId", SequenceNumber: 15}),
            new DomainValueDto({ CodeValue: "ClientSecret", NameValue: "ClientSecret", DisplayValue: "ClientSecret", SequenceNumber: 16 })            
        ]
    };
	public static EmailDirection = {
        Inbound: "Inbound",
        Outbound: "Outbound",
        Values : [
            new DomainValueDto({ CodeValue: "Inbound", NameValue: "Inbound", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "Outbound", NameValue: "Outbound", DisplayValue: "", SequenceNumber: 2 })            
        ]
    };
	public static EmailMessageStatus = {
        New: "New",
        Sent: "Sent",
        Posting: "Posting",
        Posted: "Posted",
        Received: "Received",
        Values : [
            new DomainValueDto({ CodeValue: "New", NameValue: "New", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "Sent", NameValue: "Sent", DisplayValue: "", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "Posting", NameValue: "Posting", DisplayValue: "", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "Posted", NameValue: "Posted", DisplayValue: "", SequenceNumber: 4}),
            new DomainValueDto({ CodeValue: "Received", NameValue: "Received", DisplayValue: "", SequenceNumber: 5 })            
        ]
    };
	public static EmailNotificationTemplate = {
        ValidateUserAccount: "ValidateUserAccount",
        UserAccountActivated: "UserAccountActivated",
        VerificationCode: "VerificationCode",
        ValidateUserEmailChange: "ValidateUserEmailChange",
        UserEmailChangeApplied: "UserEmailChangeApplied",
        ValidateUserSecondaryEmailChange: "ValidateUserSecondaryEmailChange",
        UserSecondaryEmailChangeApplied: "UserSecondaryEmailChangeApplied",
        UserPasswordReset: "UserPasswordReset",
        UserPasswordChangeApplied: "UserPasswordChangeApplied",
        InformUserAboutEmailChange: "InformUserAboutEmailChange",
        Values : [
            new DomainValueDto({ CodeValue: "ValidateUserAccount", NameValue: "Validate User Account", DisplayValue: "Validate User Account", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "UserAccountActivated", NameValue: "User Account Activated", DisplayValue: "User Account Activated", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "VerificationCode", NameValue: "Verification Code", DisplayValue: "Verification Code", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "ValidateUserEmailChange", NameValue: "Validate User Email Change", DisplayValue: "Validate User Email Change", SequenceNumber: 4}),
            new DomainValueDto({ CodeValue: "UserEmailChangeApplied", NameValue: "User Email Change Applied", DisplayValue: "User Email Change Applied", SequenceNumber: 5}),
            new DomainValueDto({ CodeValue: "ValidateUserSecondaryEmailChange", NameValue: "Validate User Secondary Email Change", DisplayValue: "Validate User Secondary Email Change", SequenceNumber: 6}),
            new DomainValueDto({ CodeValue: "UserSecondaryEmailChangeApplied", NameValue: "User Secondary Email Change Applied", DisplayValue: "User Secondary Email Change Applied", SequenceNumber: 7}),
            new DomainValueDto({ CodeValue: "UserPasswordReset", NameValue: "User Password Reset", DisplayValue: "User Password Reset", SequenceNumber: 8}),
            new DomainValueDto({ CodeValue: "UserPasswordChangeApplied", NameValue: "User Password Change Applied", DisplayValue: "User Password Change Applied", SequenceNumber: 9}),
            new DomainValueDto({ CodeValue: "InformUserAboutEmailChange", NameValue: "Inform User About Email Change", DisplayValue: "Inform User About Email Change", SequenceNumber: 10 })            
        ]
    };
	public static FileClassificationCode = {
        ImportProjects: "ImportProjects",
        ImportProjectItems: "ImportProjectItems",
        Values : [
            new DomainValueDto({ CodeValue: "ImportProjects", NameValue: "ImportProjects", DisplayValue: "ImportProjects", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "ImportProjectItems", NameValue: "ImportProjectItems", DisplayValue: "ImportProjectItems", SequenceNumber: 2 })            
        ]
    };
	public static JobTaskType = {
        ExportMemberProject: "ExportMemberProject",
        Values : [
            new DomainValueDto({ CodeValue: "ExportMemberProject", NameValue: "Export Member Project", DisplayValue: "Export Member Project", SequenceNumber: 1 })            
        ]
    };
	public static ObjectType = {
        Product: "Product",
        ProductDetail: "ProductDetail",
        ProductComponent: "ProductComponent",
        ProductComponentDetail: "ProductComponentDetail",
        ProductComponentGroup: "ProductComponentGroup",
        ProductComponentGroupDetail: "ProductComponentGroupDetail",
        Project: "Project",
        ProjectDetail: "ProjectDetail",
        ProjectItem: "ProjectItem",
        ProjectItemDetail: "ProjectItemDetail",
        ProjectItemGroup: "ProjectItemGroup",
        ProjectItemGroupDetail: "ProjectItemGroupDetail",
        SKUType: "SKUType",
        SKUSpecification: "SKUSpec",
        Values : [
            new DomainValueDto({ CodeValue: "Product", NameValue: "Product", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "ProductDetail", NameValue: "Product Detail", DisplayValue: "", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "ProductComponent", NameValue: "Product Component", DisplayValue: "", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "ProductComponentDetail", NameValue: "Product Component Detail", DisplayValue: "", SequenceNumber: 4}),
            new DomainValueDto({ CodeValue: "ProductComponentGroup", NameValue: "Product Component Group", DisplayValue: "", SequenceNumber: 5}),
            new DomainValueDto({ CodeValue: "ProductComponentGroupDetail", NameValue: "Product Component Group Detail", DisplayValue: "", SequenceNumber: 6}),
            new DomainValueDto({ CodeValue: "Project", NameValue: "Project", DisplayValue: "", SequenceNumber: 7}),
            new DomainValueDto({ CodeValue: "ProjectDetail", NameValue: "Project Detail", DisplayValue: "", SequenceNumber: 8}),
            new DomainValueDto({ CodeValue: "ProjectItem", NameValue: "Project Item", DisplayValue: "", SequenceNumber: 9}),
            new DomainValueDto({ CodeValue: "ProjectItemDetail", NameValue: "Project Item Detail", DisplayValue: "", SequenceNumber: 10}),
            new DomainValueDto({ CodeValue: "ProjectItemGroup", NameValue: "Project Item Group", DisplayValue: "", SequenceNumber: 11}),
            new DomainValueDto({ CodeValue: "ProjectItemGroupDetail", NameValue: "Project Item Group Detail", DisplayValue: "", SequenceNumber: 12}),
            new DomainValueDto({ CodeValue: "SKUType", NameValue: "SKU Type", DisplayValue: "SKU Type", SequenceNumber: 13}),
            new DomainValueDto({ CodeValue: "SKUSpec", NameValue: "SKU Specification", DisplayValue: "SKU Specification", SequenceNumber: 14 })            
        ]
    };
	public static PackagingComponentType = {
        Ampoule: "Ampoule",
        Bag: "Bag",
        Band: "Band",
        Base: "Base",
        Basket: "Basket",
        Blister: "Blister",
        Board: "Board",
        Booklet: "Booklet",
        Bottle: "Bottle",
        Bowl: "Bowl",
        Box: "Box",
        Bucket: "Bucket",
        Can: "Can",
        Cap: "Cap",
        Capsule: "Capsule",
        Card: "Card",
        Carton: "Carton",
        Case: "Case",
        Clip: "Clip",
        Closure: "Closure",
        Container: "Container",
        Core: "Core",
        Cork: "Cork",
        Cup: "Cup",
        Divider: "Divider",
        Dropper: "Dropper",
        Drum: "Drum",
        Envelope: "Envelope",
        Film: "Film",
        Foam: "Foam",
        Foil: "Foil",
        Fork: "Fork",
        Handle: "Handle",
        Hanger: "Hanger",
        Holder: "Holder",
        Hook: "Hook",
        Insert: "Insert",
        Jar: "Jar",
        Knife: "Knife",
        Label: "Label",
        Leaflet: "Leaflet",
        Lid: "Lid",
        Liner: "Liner",
        Net: "Net",
        Nozzle: "Nozzle",
        Pad: "Pad",
        PeelPack: "Peel Pack",
        Pin: "Pin",
        Pod: "Pod",
        Pot: "Pot",
        Pouch: "Pouch",
        Pump: "Pump",
        Punnet: "Punnet",
        Ribbon: "Ribbon",
        Ring: "Ring",
        Sachet: "Sachet",
        Satchel: "Satchel",
        Scoop: "Scoop",
        Screw: "Screw",
        Seal: "Seal",
        Shell: "Shell",
        Sleeve: "Sleeve",
        Spoon: "Spoon",
        Spork: "Spork",
        Spray: "Spray",
        Staple: "Staple",
        Stick: "Stick",
        Sticker: "Sticker",
        Strap: "Strap",
        Straw: "Straw",
        Strip: "Strip",
        Tab: "Tab",
        Tag: "Tag",
        Tape: "Tape",
        Tie: "Tie",
        Tin: "Tin",
        Tissue: "Tissue",
        Tray: "Tray",
        Trigger: "Trigger",
        Tub: "Tub",
        Tube: "Tube",
        Window: "Window",
        Wire: "Wire",
        Wrap: "Wrap",
        Wrapper: "Wrapper",
        Values : [
            new DomainValueDto({ CodeValue: "Ampoule", NameValue: "Ampoule", DisplayValue: "Ampoule", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "Bag", NameValue: "Bag", DisplayValue: "Bag", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "Band", NameValue: "Band", DisplayValue: "Band", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "Base", NameValue: "Base", DisplayValue: "Base", SequenceNumber: 4}),
            new DomainValueDto({ CodeValue: "Basket", NameValue: "Basket", DisplayValue: "Basket", SequenceNumber: 5}),
            new DomainValueDto({ CodeValue: "Blister", NameValue: "Blister", DisplayValue: "Blister", SequenceNumber: 6}),
            new DomainValueDto({ CodeValue: "Board", NameValue: "Board", DisplayValue: "Board", SequenceNumber: 7}),
            new DomainValueDto({ CodeValue: "Booklet", NameValue: "Booklet", DisplayValue: "Booklet", SequenceNumber: 8}),
            new DomainValueDto({ CodeValue: "Bottle", NameValue: "Bottle", DisplayValue: "Bottle", SequenceNumber: 9}),
            new DomainValueDto({ CodeValue: "Bowl", NameValue: "Bowl", DisplayValue: "Bowl", SequenceNumber: 10}),
            new DomainValueDto({ CodeValue: "Box", NameValue: "Box", DisplayValue: "Box", SequenceNumber: 11}),
            new DomainValueDto({ CodeValue: "Bucket", NameValue: "Bucket", DisplayValue: "Bucket", SequenceNumber: 12}),
            new DomainValueDto({ CodeValue: "Can", NameValue: "Can", DisplayValue: "Can", SequenceNumber: 13}),
            new DomainValueDto({ CodeValue: "Cap", NameValue: "Cap", DisplayValue: "Cap", SequenceNumber: 14}),
            new DomainValueDto({ CodeValue: "Capsule", NameValue: "Capsule", DisplayValue: "Capsule", SequenceNumber: 15}),
            new DomainValueDto({ CodeValue: "Card", NameValue: "Card", DisplayValue: "Card", SequenceNumber: 16}),
            new DomainValueDto({ CodeValue: "Carton", NameValue: "Carton", DisplayValue: "Carton", SequenceNumber: 17}),
            new DomainValueDto({ CodeValue: "Case", NameValue: "Case", DisplayValue: "Case", SequenceNumber: 18}),
            new DomainValueDto({ CodeValue: "Clip", NameValue: "Clip", DisplayValue: "Clip", SequenceNumber: 19}),
            new DomainValueDto({ CodeValue: "Closure", NameValue: "Closure", DisplayValue: "Closure", SequenceNumber: 20}),
            new DomainValueDto({ CodeValue: "Container", NameValue: "Container", DisplayValue: "Container", SequenceNumber: 21}),
            new DomainValueDto({ CodeValue: "Core", NameValue: "Core", DisplayValue: "Core", SequenceNumber: 22}),
            new DomainValueDto({ CodeValue: "Cork", NameValue: "Cork", DisplayValue: "Cork", SequenceNumber: 23}),
            new DomainValueDto({ CodeValue: "Cup", NameValue: "Cup", DisplayValue: "Cup", SequenceNumber: 24}),
            new DomainValueDto({ CodeValue: "Divider", NameValue: "Divider", DisplayValue: "Divider", SequenceNumber: 25}),
            new DomainValueDto({ CodeValue: "Dropper", NameValue: "Dropper", DisplayValue: "Dropper", SequenceNumber: 26}),
            new DomainValueDto({ CodeValue: "Drum", NameValue: "Drum", DisplayValue: "Drum", SequenceNumber: 27}),
            new DomainValueDto({ CodeValue: "Envelope", NameValue: "Envelope", DisplayValue: "Envelope", SequenceNumber: 28}),
            new DomainValueDto({ CodeValue: "Film", NameValue: "Film", DisplayValue: "Film", SequenceNumber: 29}),
            new DomainValueDto({ CodeValue: "Foam", NameValue: "Foam", DisplayValue: "Foam", SequenceNumber: 30}),
            new DomainValueDto({ CodeValue: "Foil", NameValue: "Foil", DisplayValue: "Foil", SequenceNumber: 31}),
            new DomainValueDto({ CodeValue: "Fork", NameValue: "Fork", DisplayValue: "Fork", SequenceNumber: 32}),
            new DomainValueDto({ CodeValue: "Handle", NameValue: "Handle", DisplayValue: "Handle", SequenceNumber: 33}),
            new DomainValueDto({ CodeValue: "Hanger", NameValue: "Hanger", DisplayValue: "Hanger", SequenceNumber: 34}),
            new DomainValueDto({ CodeValue: "Holder", NameValue: "Holder", DisplayValue: "Holder", SequenceNumber: 35}),
            new DomainValueDto({ CodeValue: "Hook", NameValue: "Hook", DisplayValue: "Hook", SequenceNumber: 36}),
            new DomainValueDto({ CodeValue: "Insert", NameValue: "Insert", DisplayValue: "Insert", SequenceNumber: 37}),
            new DomainValueDto({ CodeValue: "Jar", NameValue: "Jar", DisplayValue: "Jar", SequenceNumber: 38}),
            new DomainValueDto({ CodeValue: "Knife", NameValue: "Knife", DisplayValue: "Knife", SequenceNumber: 39}),
            new DomainValueDto({ CodeValue: "Label", NameValue: "Label", DisplayValue: "Label", SequenceNumber: 40}),
            new DomainValueDto({ CodeValue: "Leaflet", NameValue: "Leaflet", DisplayValue: "Leaflet", SequenceNumber: 41}),
            new DomainValueDto({ CodeValue: "Lid", NameValue: "Lid", DisplayValue: "Lid", SequenceNumber: 42}),
            new DomainValueDto({ CodeValue: "Liner", NameValue: "Liner", DisplayValue: "Liner", SequenceNumber: 43}),
            new DomainValueDto({ CodeValue: "Net", NameValue: "Net", DisplayValue: "Net", SequenceNumber: 44}),
            new DomainValueDto({ CodeValue: "Nozzle", NameValue: "Nozzle", DisplayValue: "Nozzle", SequenceNumber: 45}),
            new DomainValueDto({ CodeValue: "Pad", NameValue: "Pad", DisplayValue: "Pad", SequenceNumber: 46}),
            new DomainValueDto({ CodeValue: "Peel Pack", NameValue: "Peel Pack", DisplayValue: "Peel Pack", SequenceNumber: 47}),
            new DomainValueDto({ CodeValue: "Pin", NameValue: "Pin", DisplayValue: "Pin", SequenceNumber: 48}),
            new DomainValueDto({ CodeValue: "Pod", NameValue: "Pod", DisplayValue: "Pod", SequenceNumber: 49}),
            new DomainValueDto({ CodeValue: "Pot", NameValue: "Pot", DisplayValue: "Pot", SequenceNumber: 50}),
            new DomainValueDto({ CodeValue: "Pouch", NameValue: "Pouch", DisplayValue: "Pouch", SequenceNumber: 51}),
            new DomainValueDto({ CodeValue: "Pump", NameValue: "Pump", DisplayValue: "Pump", SequenceNumber: 52}),
            new DomainValueDto({ CodeValue: "Punnet", NameValue: "Punnet", DisplayValue: "Punnet", SequenceNumber: 53}),
            new DomainValueDto({ CodeValue: "Ribbon", NameValue: "Ribbon", DisplayValue: "Ribbon", SequenceNumber: 54}),
            new DomainValueDto({ CodeValue: "Ring", NameValue: "Ring", DisplayValue: "Ring", SequenceNumber: 55}),
            new DomainValueDto({ CodeValue: "Sachet", NameValue: "Sachet", DisplayValue: "Sachet", SequenceNumber: 56}),
            new DomainValueDto({ CodeValue: "Satchel", NameValue: "Satchel", DisplayValue: "Satchel", SequenceNumber: 57}),
            new DomainValueDto({ CodeValue: "Scoop", NameValue: "Scoop", DisplayValue: "Scoop", SequenceNumber: 58}),
            new DomainValueDto({ CodeValue: "Screw", NameValue: "Screw", DisplayValue: "Screw", SequenceNumber: 59}),
            new DomainValueDto({ CodeValue: "Seal", NameValue: "Seal", DisplayValue: "Seal", SequenceNumber: 60}),
            new DomainValueDto({ CodeValue: "Shell", NameValue: "Shell", DisplayValue: "Shell", SequenceNumber: 61}),
            new DomainValueDto({ CodeValue: "Sleeve", NameValue: "Sleeve", DisplayValue: "Sleeve", SequenceNumber: 62}),
            new DomainValueDto({ CodeValue: "Spoon", NameValue: "Spoon", DisplayValue: "Spoon", SequenceNumber: 63}),
            new DomainValueDto({ CodeValue: "Spork", NameValue: "Spork", DisplayValue: "Spork", SequenceNumber: 64}),
            new DomainValueDto({ CodeValue: "Spray", NameValue: "Spray", DisplayValue: "Spray", SequenceNumber: 65}),
            new DomainValueDto({ CodeValue: "Staple", NameValue: "Staple", DisplayValue: "Staple", SequenceNumber: 66}),
            new DomainValueDto({ CodeValue: "Stick", NameValue: "Stick", DisplayValue: "Stick", SequenceNumber: 67}),
            new DomainValueDto({ CodeValue: "Sticker", NameValue: "Sticker", DisplayValue: "Sticker", SequenceNumber: 68}),
            new DomainValueDto({ CodeValue: "Strap", NameValue: "Strap", DisplayValue: "Strap", SequenceNumber: 69}),
            new DomainValueDto({ CodeValue: "Straw", NameValue: "Straw", DisplayValue: "Straw", SequenceNumber: 70}),
            new DomainValueDto({ CodeValue: "Strip", NameValue: "Strip", DisplayValue: "Strip", SequenceNumber: 71}),
            new DomainValueDto({ CodeValue: "Tab", NameValue: "Tab", DisplayValue: "Tab", SequenceNumber: 72}),
            new DomainValueDto({ CodeValue: "Tag", NameValue: "Tag", DisplayValue: "Tag", SequenceNumber: 73}),
            new DomainValueDto({ CodeValue: "Tape", NameValue: "Tape", DisplayValue: "Tape", SequenceNumber: 74}),
            new DomainValueDto({ CodeValue: "Tie", NameValue: "Tie", DisplayValue: "Tie", SequenceNumber: 75}),
            new DomainValueDto({ CodeValue: "Tin", NameValue: "Tin", DisplayValue: "Tin", SequenceNumber: 76}),
            new DomainValueDto({ CodeValue: "Tissue", NameValue: "Tissue", DisplayValue: "Tissue", SequenceNumber: 77}),
            new DomainValueDto({ CodeValue: "Tray", NameValue: "Tray", DisplayValue: "Tray", SequenceNumber: 78}),
            new DomainValueDto({ CodeValue: "Trigger", NameValue: "Trigger", DisplayValue: "Trigger", SequenceNumber: 79}),
            new DomainValueDto({ CodeValue: "Tub", NameValue: "Tub", DisplayValue: "Tub", SequenceNumber: 80}),
            new DomainValueDto({ CodeValue: "Tube", NameValue: "Tube", DisplayValue: "Tube", SequenceNumber: 81}),
            new DomainValueDto({ CodeValue: "Window", NameValue: "Window", DisplayValue: "Window", SequenceNumber: 82}),
            new DomainValueDto({ CodeValue: "Wire", NameValue: "Wire", DisplayValue: "Wire", SequenceNumber: 83}),
            new DomainValueDto({ CodeValue: "Wrap", NameValue: "Wrap", DisplayValue: "Wrap", SequenceNumber: 84}),
            new DomainValueDto({ CodeValue: "Wrapper", NameValue: "Wrapper", DisplayValue: "Wrapper", SequenceNumber: 85 })            
        ]
    };
	public static PackagingItemType = {
        Separable: "Separable",
        Secondary: "Secondary",
        SoftPlastic: "SoftPlastic",
        Attachment: "Attachment",
        Label: "Label",
        Values : [
            new DomainValueDto({ CodeValue: "Separable", NameValue: "Separable", DisplayValue: "Separable", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "Secondary", NameValue: "Secondary", DisplayValue: "Secondary", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "SoftPlastic", NameValue: "Soft Plastic", DisplayValue: "Soft Plastic", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "Attachment", NameValue: "Attachment", DisplayValue: "Attachment", SequenceNumber: 4}),
            new DomainValueDto({ CodeValue: "Label", NameValue: "Label", DisplayValue: "Label", SequenceNumber: 5 })            
        ]
    };
	public static PlasticAdditive = {
        OpticalBrighteners: "OpticalBrighteners",
        DegradableAdditives: "DegradableAdditives",
        HalogenatedFireRetardants: "HalogenatedFireRetardants",
        MineralOrOtherFillers: "MineralOrOtherFillers",
        Values : [
            new DomainValueDto({ CodeValue: "OpticalBrighteners", NameValue: "Optical Brighteners", DisplayValue: "Optical brighteners", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "DegradableAdditives", NameValue: "Degradable Additives", DisplayValue: "Degradable additives", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "HalogenatedFireRetardants", NameValue: "Halogenated Fire Retardants", DisplayValue: "Halogenated fire retardants", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "MineralOrOtherFillers", NameValue: "Mineral Or Other Fillers", DisplayValue: "Mineral or other fillers", SequenceNumber: 4 })            
        ]
    };
	public static PREPBondingType = {
        Foiling: "Foiling",
        Laminated: "Laminated",
        Laminateddoublesided: "Laminateddoublesided",
        Laminatedsinglesided: "Laminatedsinglesided",
        None: "None",
        Nonwatersolubleglue: "Nonwatersolubleglue",
        Plasticwelded: "Plasticwelded",
        PressureSensitiveAdhesive: "PressureSensitiveAdhesive",
        Pushfit: "Pushfit",
        Shrinkfit: "Shrinkfit",
        Threadedconnection: "Threadedconnection",
        Vacuummetallisation: "Vacuummetallisation",
        Watersolubleglue: "Watersolubleglue",
        Values : [
            new DomainValueDto({ CodeValue: "Foiling", NameValue: "Foiling", DisplayValue: "Foiling", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "Laminated", NameValue: "Laminated", DisplayValue: "Laminated", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "Laminateddoublesided", NameValue: "Laminated (double sided)", DisplayValue: "Laminated (double sided)", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "Laminatedsinglesided", NameValue: "Laminated (single sided)", DisplayValue: "Laminated (single sided)", SequenceNumber: 4}),
            new DomainValueDto({ CodeValue: "None", NameValue: "None", DisplayValue: "None", SequenceNumber: 5}),
            new DomainValueDto({ CodeValue: "Nonwatersolubleglue", NameValue: "Non-water soluble glue", DisplayValue: "Non-water soluble glue", SequenceNumber: 6}),
            new DomainValueDto({ CodeValue: "Plasticwelded", NameValue: "Plastic welded", DisplayValue: "Plastic welded", SequenceNumber: 7}),
            new DomainValueDto({ CodeValue: "PressureSensitiveAdhesive", NameValue: "Pressure Sensitive Adhesive", DisplayValue: "Pressure Sensitive Adhesive", SequenceNumber: 8}),
            new DomainValueDto({ CodeValue: "Pushfit", NameValue: "Push fit ", DisplayValue: "Push fit ", SequenceNumber: 9}),
            new DomainValueDto({ CodeValue: "Shrinkfit", NameValue: "Shrink fit", DisplayValue: "Shrink fit", SequenceNumber: 10}),
            new DomainValueDto({ CodeValue: "Threadedconnection", NameValue: "Threaded connection", DisplayValue: "Threaded connection", SequenceNumber: 11}),
            new DomainValueDto({ CodeValue: "Vacuummetallisation", NameValue: "Vacuum metallisation ", DisplayValue: "Vacuum metallisation ", SequenceNumber: 12}),
            new DomainValueDto({ CodeValue: "Watersolubleglue", NameValue: "Water soluble glue", DisplayValue: "Water soluble glue", SequenceNumber: 13 })            
        ]
    };
	public static PREPCarbonBlack = {
        Yes: "Yes",
        No: "No",
        DontKnow: "DontKnow",
        Values : [
            new DomainValueDto({ CodeValue: "Yes", NameValue: "Yes", DisplayValue: "Yes", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "No", NameValue: "No", DisplayValue: "No", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "DontKnow", NameValue: "Dont Know", DisplayValue: "dont_know", SequenceNumber: 3 })            
        ]
    };
	public static PREPGaugeType = {
        Thickness: "Thickness",
        Weight: "Weight",
        Values : [
            new DomainValueDto({ CodeValue: "Thickness", NameValue: "Thickness", DisplayValue: "Thickness", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "Weight", NameValue: "Weight", DisplayValue: "Weight", SequenceNumber: 2 })            
        ]
    };
	public static PREPInkType = {
        None: "None",
        Nonwatersolubleink: "Nonwatersolubleink",
        UVinksandcoatings: "UVinksandcoatings",
        Watersolubleink: "Watersolubleink",
        Values : [
            new DomainValueDto({ CodeValue: "None", NameValue: "None", DisplayValue: "None", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "Nonwatersolubleink", NameValue: "Non-water soluble ink", DisplayValue: "Non-water soluble ink", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "UVinksandcoatings", NameValue: "UV inks and coatings", DisplayValue: "UV inks and coatings", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "Watersolubleink", NameValue: "Water soluble ink", DisplayValue: "Water soluble ink", SequenceNumber: 4 })            
        ]
    };
	public static PREPPrimaryMaterial = {
        Aluminium: "Aluminium",
        AluminiumAerosolCan: "AluminiumAerosolCan",
        AluminiumFoil: "AluminiumFoil",
        AluminiumRigid: "AluminiumRigid",
        Steel: "Steel",
        SteelAerosolCan: "SteelAerosolCan",
        SteelRigid: "SteelRigid",
        ContainerGlass: "ContainerGlass",
        Glass: "Glass",
        OtherMaterial: "OtherMaterial",
        Alternativefibres: "Alternativefibres",
        Cardboard: "Cardboard",
        GableTopCartonPolycoated: "GableTopCartonPolycoated",
        Glassinetreatedandcoated: "Glassinetreatedandcoated",
        Glassineuntreatedanduncoated: "Glassineuntreatedanduncoated",
        LiquidPaperBoardAsepticfoillined: "LiquidPaperBoardAsepticfoillined",
        Paper: "Paper",
        PaperBeverageCup: "PaperBeverageCup",
        SimplyCupsapprovedcup: "SimplyCupsapprovedcup",
        WaxedPaper: "WaxedPaper",
        WetStrengthPaper: "WetStrengthPaper",
        Biopolymer: "Biopolymer",
        CPETPigmented: "CPETPigmented",
        CPETUnpigmented: "CPETUnpigmented",
        ExpandedPS: "ExpandedPS",
        HDPE2: "HDPE2",
        HDPEcoloured: "HDPEcoloured",
        HDPEnatural: "HDPEnatural",
        LDPE4: "LDPE4",
        LDPErigid: "LDPErigid",
        LDPEsoft: "LDPEsoft",
        OtherPlastic: "OtherPlastic",
        PET1colouredbrownpurpletransparent: "PET1colouredbrownpurple,transparent",
        PET1colouredopaque: "PET1coloured,opaque",
        PET1colouredtransparent: "PET1coloured,transparent",
        PET1Natural: "PET1Natural",
        PETG: "PETG",
        PLA: "PLA",
        PP5: "PP5",
        PS6: "PS6",
        PVC3: "PVC3",
        Values : [
            new DomainValueDto({ CodeValue: "Aluminium", NameValue: "Aluminium", DisplayValue: "Aluminium", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "AluminiumAerosolCan", NameValue: "Aluminium - Aerosol Can", DisplayValue: "Aluminium - Aerosol Can", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "AluminiumFoil", NameValue: "Aluminium - Foil", DisplayValue: "Aluminium - Foil", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "AluminiumRigid", NameValue: "Aluminium - Rigid", DisplayValue: "Aluminium - Rigid", SequenceNumber: 4}),
            new DomainValueDto({ CodeValue: "Steel", NameValue: "Steel", DisplayValue: "Steel", SequenceNumber: 5}),
            new DomainValueDto({ CodeValue: "SteelAerosolCan", NameValue: "Steel - Aerosol Can", DisplayValue: "Steel - Aerosol Can", SequenceNumber: 6}),
            new DomainValueDto({ CodeValue: "SteelRigid", NameValue: "Steel - Rigid", DisplayValue: "Steel - Rigid", SequenceNumber: 7}),
            new DomainValueDto({ CodeValue: "ContainerGlass", NameValue: "Container Glass", DisplayValue: "Container Glass", SequenceNumber: 8}),
            new DomainValueDto({ CodeValue: "Glass", NameValue: "Glass", DisplayValue: "Glass", SequenceNumber: 9}),
            new DomainValueDto({ CodeValue: "OtherMaterial", NameValue: "Other Material", DisplayValue: "Other Material", SequenceNumber: 10}),
            new DomainValueDto({ CodeValue: "Alternativefibres", NameValue: "Alternative fibres", DisplayValue: "Alternative fibres", SequenceNumber: 11}),
            new DomainValueDto({ CodeValue: "Cardboard", NameValue: "Cardboard", DisplayValue: "Cardboard", SequenceNumber: 12}),
            new DomainValueDto({ CodeValue: "GableTopCartonPolycoated", NameValue: "Gable Top Carton (Polycoated)", DisplayValue: "Gable Top Carton (Polycoated)", SequenceNumber: 13}),
            new DomainValueDto({ CodeValue: "Glassinetreatedandcoated", NameValue: "Glassine - treated and coated", DisplayValue: "Glassine - treated and coated", SequenceNumber: 14}),
            new DomainValueDto({ CodeValue: "Glassineuntreatedanduncoated", NameValue: "Glassine - untreated and uncoated", DisplayValue: "Glassine - untreated and uncoated", SequenceNumber: 15}),
            new DomainValueDto({ CodeValue: "LiquidPaperBoardAsepticfoillined", NameValue: "Liquid Paper Board - Aseptic (foil lined)", DisplayValue: "Liquid Paper Board - Aseptic (foil lined)", SequenceNumber: 16}),
            new DomainValueDto({ CodeValue: "Paper", NameValue: "Paper", DisplayValue: "Paper", SequenceNumber: 17}),
            new DomainValueDto({ CodeValue: "PaperBeverageCup", NameValue: "Paper Beverage Cup", DisplayValue: "Paper Beverage Cup", SequenceNumber: 18}),
            new DomainValueDto({ CodeValue: "SimplyCupsapprovedcup", NameValue: "Simply Cups approved cup", DisplayValue: "Simply Cups approved cup", SequenceNumber: 19}),
            new DomainValueDto({ CodeValue: "WaxedPaper", NameValue: "Waxed Paper", DisplayValue: "Waxed Paper", SequenceNumber: 20}),
            new DomainValueDto({ CodeValue: "WetStrengthPaper", NameValue: "Wet Strength Paper", DisplayValue: "Wet Strength Paper", SequenceNumber: 21}),
            new DomainValueDto({ CodeValue: "Biopolymer", NameValue: "Biopolymer", DisplayValue: "Biopolymer", SequenceNumber: 22}),
            new DomainValueDto({ CodeValue: "CPETPigmented", NameValue: "C-PET Pigmented", DisplayValue: "C-PET Pigmented", SequenceNumber: 23}),
            new DomainValueDto({ CodeValue: "CPETUnpigmented", NameValue: "CPET Unpigmented", DisplayValue: "CPET Unpigmented", SequenceNumber: 24}),
            new DomainValueDto({ CodeValue: "ExpandedPS", NameValue: "Expanded PS", DisplayValue: "Expanded PS", SequenceNumber: 25}),
            new DomainValueDto({ CodeValue: "HDPE2", NameValue: "HDPE (2)", DisplayValue: "HDPE (2)", SequenceNumber: 26}),
            new DomainValueDto({ CodeValue: "HDPEcoloured", NameValue: "HDPE (coloured)", DisplayValue: "HDPE (coloured)", SequenceNumber: 27}),
            new DomainValueDto({ CodeValue: "HDPEnatural", NameValue: "HDPE (natural)", DisplayValue: "HDPE (natural)", SequenceNumber: 28}),
            new DomainValueDto({ CodeValue: "LDPE4", NameValue: "LDPE (4)", DisplayValue: "LDPE (4)", SequenceNumber: 29}),
            new DomainValueDto({ CodeValue: "LDPErigid", NameValue: "LDPE (rigid)", DisplayValue: "LDPE (rigid)", SequenceNumber: 30}),
            new DomainValueDto({ CodeValue: "LDPEsoft", NameValue: "LDPE (soft)", DisplayValue: "LDPE (soft)", SequenceNumber: 31}),
            new DomainValueDto({ CodeValue: "OtherPlastic", NameValue: "Other Plastic", DisplayValue: "Other Plastic", SequenceNumber: 32}),
            new DomainValueDto({ CodeValue: "PET1colouredbrownpurple,transparent", NameValue: "PET (1) coloured (brown & purple), transparent", DisplayValue: "PET (1) coloured (brown & purple), transparent", SequenceNumber: 33}),
            new DomainValueDto({ CodeValue: "PET1coloured,opaque", NameValue: "PET (1) coloured, opaque", DisplayValue: "PET (1) coloured, opaque", SequenceNumber: 34}),
            new DomainValueDto({ CodeValue: "PET1coloured,transparent", NameValue: "PET (1) coloured, transparent", DisplayValue: "PET (1) coloured, transparent", SequenceNumber: 35}),
            new DomainValueDto({ CodeValue: "PET1Natural", NameValue: "PET (1) Natural", DisplayValue: "PET (1) Natural", SequenceNumber: 36}),
            new DomainValueDto({ CodeValue: "PETG", NameValue: "PET-G", DisplayValue: "PET-G", SequenceNumber: 37}),
            new DomainValueDto({ CodeValue: "PLA", NameValue: "PLA", DisplayValue: "PLA", SequenceNumber: 38}),
            new DomainValueDto({ CodeValue: "PP5", NameValue: "PP (5)", DisplayValue: "PP (5)", SequenceNumber: 39}),
            new DomainValueDto({ CodeValue: "PS6", NameValue: "PS  (6)", DisplayValue: "PS  (6)", SequenceNumber: 40}),
            new DomainValueDto({ CodeValue: "PVC3", NameValue: "PVC (3)", DisplayValue: "PVC (3)", SequenceNumber: 41 })            
        ]
    };
	public static PREPPrimaryMaterialType = {
        Aluminium: "Aluminium",
        Glass: "Glass",
        PaperCardboard: "PaperCardboard",
        Plastic: "Plastic",
        Steel: "Steel",
        Other: "Other",
        Values : [
            new DomainValueDto({ CodeValue: "Aluminium", NameValue: "Aluminium", DisplayValue: "Aluminium", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "Glass", NameValue: "Glass", DisplayValue: "Glass", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "PaperCardboard", NameValue: "Paper / Cardboard", DisplayValue: "Paper/Cardboard", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "Plastic", NameValue: "Plastic", DisplayValue: "Plastic", SequenceNumber: 4}),
            new DomainValueDto({ CodeValue: "Steel", NameValue: "Steel", DisplayValue: "Steel", SequenceNumber: 5}),
            new DomainValueDto({ CodeValue: "Other", NameValue: "Other", DisplayValue: "Other", SequenceNumber: 6 })            
        ]
    };
	public static PREPProductResidue = {
        Fixed: "Fixed",
        Loose: "Loose",
        Values : [
            new DomainValueDto({ CodeValue: "Fixed", NameValue: "Fixed", DisplayValue: "Fixed", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "Loose", NameValue: "Loose", DisplayValue: "Loose/Surface Stain", SequenceNumber: 2 })            
        ]
    };
	public static PREPProjectCategory = {
        Apparel: "Apparel",
        Dairy: "Dairy",
        Downlight: "Downlight",
        Floodlight: "Floodlight",
        FoodandBeverage: "FoodAndBeverage",
        FreshProduce: "FreshProduce",
        Homewares: "Homewares",
        PersonalCare: "PersonalCare",
        Pharmaceutical: "Pharmaceutical",
        Stationary: "Stationary",
        Values : [
            new DomainValueDto({ CodeValue: "Apparel", NameValue: "Apparel", DisplayValue: "Apparel", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "Dairy", NameValue: "Dairy", DisplayValue: "Dairy", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "Downlight", NameValue: "Downlight", DisplayValue: "Downlight", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "Floodlight", NameValue: "Floodlight", DisplayValue: "Floodlight", SequenceNumber: 4}),
            new DomainValueDto({ CodeValue: "FoodAndBeverage", NameValue: "Food and Beverage", DisplayValue: "Food and Beverage", SequenceNumber: 5}),
            new DomainValueDto({ CodeValue: "FreshProduce", NameValue: "Fresh Produce", DisplayValue: "Fresh Produce", SequenceNumber: 6}),
            new DomainValueDto({ CodeValue: "Homewares", NameValue: "Homewares", DisplayValue: "Homewares", SequenceNumber: 7}),
            new DomainValueDto({ CodeValue: "PersonalCare", NameValue: "Personal Care", DisplayValue: "Personal Care", SequenceNumber: 8}),
            new DomainValueDto({ CodeValue: "Pharmaceutical", NameValue: "Pharmaceutical", DisplayValue: "Pharmaceutical", SequenceNumber: 9}),
            new DomainValueDto({ CodeValue: "Stationary", NameValue: "Stationary", DisplayValue: "Stationary", SequenceNumber: 10 })            
        ]
    };
	public static PREPProjectMarkets = {
        Australia: "Aust",
        NewZealand: "NZ",
        Values : [
            new DomainValueDto({ CodeValue: "Aust", NameValue: "Australia", DisplayValue: "AU", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "NZ", NameValue: "New Zealand", DisplayValue: "NZ", SequenceNumber: 2 })            
        ]
    };
	public static PREPRigidity = {
        Rigid: "Rigid",
        Soft: "Soft",
        Values : [
            new DomainValueDto({ CodeValue: "Rigid", NameValue: "Rigid", DisplayValue: "Rigid", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "Soft", NameValue: "Soft", DisplayValue: "Soft", SequenceNumber: 2 })            
        ]
    };
	public static PREPSecondaryMaterial = {
        Glass: "Glass",
        BatchInk: "BatchInk",
        Aluminium: "Aluminium",
        Steel: "Steel",
        OtherMaterial: "OtherMaterial",
        PFAS: "PFAS",
        Cardboard: "Cardboard",
        GableTopCartonPolycoated: "GableTopCartonPolycoated",
        Glassine: "Glassine",
        Glassinetreatedandcoated: "Glassinetreatedandcoated",
        Glassineuntreateduncoated: "Glassineuntreateduncoated",
        HighWetStrengthPaper: "HighWetStrengthPaper",
        LiquidPaperBoardAsepticfoillined: "LiquidPaperBoardAsepticfoillined",
        Paper: "Paper",
        WaxedPaper: "WaxedPaper",
        WetStrengthPaper: "WetStrengthPaper",
        Biopolymer: "Biopolymer",
        EVOH: "EVOH",
        ExpandedPS: "ExpandedPS",
        HDPE2: "HDPE2",
        LDPE4: "LDPE4",
        OtherPlastic: "OtherPlastic",
        PET1: "PET1",
        PETG: "PETG",
        PLA: "PLA",
        PP5: "PP5",
        PS6: "PS6",
        PVC3: "PVC3",
        PVDC: "PVDC",
        Silicone: "Silicone",
        Waterdissolvablebioplastic: "Waterdissolvablebioplastic",
        Values : [
            new DomainValueDto({ CodeValue: "Glass", NameValue: "Glass", DisplayValue: "Glass", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "BatchInk", NameValue: "Batch Ink", DisplayValue: "Batch Ink", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "Aluminium", NameValue: "Aluminium", DisplayValue: "Aluminium", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "Steel", NameValue: "Steel", DisplayValue: "Steel", SequenceNumber: 4}),
            new DomainValueDto({ CodeValue: "OtherMaterial", NameValue: "Other Material", DisplayValue: "Other Material", SequenceNumber: 5}),
            new DomainValueDto({ CodeValue: "PFAS", NameValue: "PFAS", DisplayValue: "PFAS", SequenceNumber: 6}),
            new DomainValueDto({ CodeValue: "Cardboard", NameValue: "Cardboard", DisplayValue: "Cardboard", SequenceNumber: 7}),
            new DomainValueDto({ CodeValue: "GableTopCartonPolycoated", NameValue: "Gable Top Carton (Polycoated)", DisplayValue: "Gable Top Carton (Polycoated)", SequenceNumber: 8}),
            new DomainValueDto({ CodeValue: "Glassine", NameValue: "Glassine", DisplayValue: "Glassine", SequenceNumber: 9}),
            new DomainValueDto({ CodeValue: "Glassinetreatedandcoated", NameValue: "Glassine - treated and coated", DisplayValue: "Glassine - treated and coated", SequenceNumber: 10}),
            new DomainValueDto({ CodeValue: "Glassineuntreateduncoated", NameValue: "Glassine - untreated & uncoated", DisplayValue: "Glassine - untreated & uncoated", SequenceNumber: 11}),
            new DomainValueDto({ CodeValue: "HighWetStrengthPaper", NameValue: "High Wet Strength Paper", DisplayValue: "High Wet Strength Paper", SequenceNumber: 12}),
            new DomainValueDto({ CodeValue: "LiquidPaperBoardAsepticfoillined", NameValue: "Liquid Paper Board - Aseptic (foil lined)", DisplayValue: "Liquid Paper Board - Aseptic (foil lined)", SequenceNumber: 13}),
            new DomainValueDto({ CodeValue: "Paper", NameValue: "Paper", DisplayValue: "Paper", SequenceNumber: 14}),
            new DomainValueDto({ CodeValue: "WaxedPaper", NameValue: "Waxed Paper", DisplayValue: "Waxed Paper", SequenceNumber: 15}),
            new DomainValueDto({ CodeValue: "WetStrengthPaper", NameValue: "Wet Strength Paper", DisplayValue: "Wet Strength Paper", SequenceNumber: 16}),
            new DomainValueDto({ CodeValue: "Biopolymer", NameValue: "Biopolymer", DisplayValue: "Biopolymer", SequenceNumber: 17}),
            new DomainValueDto({ CodeValue: "EVOH", NameValue: "EVOH", DisplayValue: "EVOH", SequenceNumber: 18}),
            new DomainValueDto({ CodeValue: "ExpandedPS", NameValue: "Expanded PS", DisplayValue: "Expanded PS", SequenceNumber: 19}),
            new DomainValueDto({ CodeValue: "HDPE2", NameValue: "HDPE (2)", DisplayValue: "HDPE (2)", SequenceNumber: 20}),
            new DomainValueDto({ CodeValue: "LDPE4", NameValue: "LDPE (4)", DisplayValue: "LDPE (4)", SequenceNumber: 21}),
            new DomainValueDto({ CodeValue: "OtherPlastic", NameValue: "Other Plastic", DisplayValue: "Other Plastic", SequenceNumber: 22}),
            new DomainValueDto({ CodeValue: "PET1", NameValue: "PET (1)", DisplayValue: "PET (1)", SequenceNumber: 23}),
            new DomainValueDto({ CodeValue: "PETG", NameValue: "PET-G", DisplayValue: "PET-G", SequenceNumber: 24}),
            new DomainValueDto({ CodeValue: "PLA", NameValue: "PLA", DisplayValue: "PLA", SequenceNumber: 25}),
            new DomainValueDto({ CodeValue: "PP5", NameValue: "PP (5)", DisplayValue: "PP (5)", SequenceNumber: 26}),
            new DomainValueDto({ CodeValue: "PS6", NameValue: "PS  (6)", DisplayValue: "PS  (6)", SequenceNumber: 27}),
            new DomainValueDto({ CodeValue: "PVC3", NameValue: "PVC (3)", DisplayValue: "PVC (3)", SequenceNumber: 28}),
            new DomainValueDto({ CodeValue: "PVDC", NameValue: "PVDC", DisplayValue: "PVDC", SequenceNumber: 29}),
            new DomainValueDto({ CodeValue: "Silicone", NameValue: "Silicone", DisplayValue: "Silicone", SequenceNumber: 30}),
            new DomainValueDto({ CodeValue: "Waterdissolvablebioplastic", NameValue: "Water dissolvable bioplastic", DisplayValue: "Water dissolvable bioplastic", SequenceNumber: 31 })            
        ]
    };
	public static PREPSecondaryMaterialType = {
        Glass: "Glass",
        Ink: "Ink",
        Metal: "Metal",
        PaperCardboard: "PaperCardboard",
        Plastic: "Plastic",
        Other: "Other",
        Values : [
            new DomainValueDto({ CodeValue: "Glass", NameValue: "Glass", DisplayValue: "Glass", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "Ink", NameValue: "Ink", DisplayValue: "Ink", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "Metal", NameValue: "Metal", DisplayValue: "Metal", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "PaperCardboard", NameValue: "Paper / Cardboard", DisplayValue: "Paper/Cardboard", SequenceNumber: 4}),
            new DomainValueDto({ CodeValue: "Plastic", NameValue: "Plastic", DisplayValue: "Plastic", SequenceNumber: 5}),
            new DomainValueDto({ CodeValue: "Other", NameValue: "Other", DisplayValue: "Other", SequenceNumber: 6 })            
        ]
    };
	public static PREPSoftPlasticPolymer = {
        Acrylic: "Acrylic",
        AlOx: "AlOx",
        Aluminium: "Aluminium",
        Bioplastic: "Bioplastic",
        BOPP: "BOPP",
        EVOH: "EVOH",
        HDPE: "HDPE",
        LDPE: "LDPE",
        Metallisation: "Metallisation",
        Nylon: "Nylon",
        OtherMaterial: "OtherMaterial",
        OtherPlastic: "OtherPlastic",
        Paper: "Paper",
        PET: "PET",
        PP: "PP",
        PS: "PS",
        PVC: "PVC",
        PVDC: "PVDC",
        PVOH: "PVOH",
        SiOx: "SiOx",
        Values : [
            new DomainValueDto({ CodeValue: "Acrylic", NameValue: "Acrylic", DisplayValue: "Acrylic", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "AlOx", NameValue: "AlOx", DisplayValue: "AlOx", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "Aluminium", NameValue: "Aluminium", DisplayValue: "Aluminium", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "Bioplastic", NameValue: "Bioplastic", DisplayValue: "Bioplastic", SequenceNumber: 4}),
            new DomainValueDto({ CodeValue: "BOPP", NameValue: "BOPP", DisplayValue: "BOPP", SequenceNumber: 5}),
            new DomainValueDto({ CodeValue: "EVOH", NameValue: "EVOH", DisplayValue: "EVOH", SequenceNumber: 6}),
            new DomainValueDto({ CodeValue: "HDPE", NameValue: "HDPE", DisplayValue: "HDPE", SequenceNumber: 7}),
            new DomainValueDto({ CodeValue: "LDPE", NameValue: "LDPE", DisplayValue: "LDPE", SequenceNumber: 8}),
            new DomainValueDto({ CodeValue: "Metallisation", NameValue: "Metallisation", DisplayValue: "Metallisation", SequenceNumber: 9}),
            new DomainValueDto({ CodeValue: "Nylon", NameValue: "Nylon", DisplayValue: "Nylon", SequenceNumber: 10}),
            new DomainValueDto({ CodeValue: "OtherMaterial", NameValue: "OtherMaterial", DisplayValue: "OtherMaterial", SequenceNumber: 11}),
            new DomainValueDto({ CodeValue: "OtherPlastic", NameValue: "OtherPlastic", DisplayValue: "OtherPlastic", SequenceNumber: 12}),
            new DomainValueDto({ CodeValue: "Paper", NameValue: "Paper", DisplayValue: "Paper", SequenceNumber: 13}),
            new DomainValueDto({ CodeValue: "PET", NameValue: "PET", DisplayValue: "PET", SequenceNumber: 14}),
            new DomainValueDto({ CodeValue: "PP", NameValue: "PP", DisplayValue: "PP", SequenceNumber: 15}),
            new DomainValueDto({ CodeValue: "PS", NameValue: "PS", DisplayValue: "PS", SequenceNumber: 16}),
            new DomainValueDto({ CodeValue: "PVC", NameValue: "PVC", DisplayValue: "PVC", SequenceNumber: 17}),
            new DomainValueDto({ CodeValue: "PVDC", NameValue: "PVDC", DisplayValue: "PVDC", SequenceNumber: 18}),
            new DomainValueDto({ CodeValue: "PVOH", NameValue: "PVOH", DisplayValue: "PVOH", SequenceNumber: 19}),
            new DomainValueDto({ CodeValue: "SiOx", NameValue: "SiOx", DisplayValue: "SiOx", SequenceNumber: 20 })            
        ]
    };
	public static PREPSoftPlasticType = {
        Primary: "Primary",
        Secondary: "Secondary",
        Values : [
            new DomainValueDto({ CodeValue: "Primary", NameValue: "Primary", DisplayValue: "Primary", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "Secondary", NameValue: "Secondary", DisplayValue: "Secondary", SequenceNumber: 2 })            
        ]
    };
	public static PREPStatusCode = {
        Active: "Active",
        Inactive: "Inactive",
        Enable: "Enable",
        Create: "Create",
        Activate: "Activate",
        Deactivate: "Deactivate",
        Delete: "Delete",
        Values : [
            new DomainValueDto({ CodeValue: "Active", NameValue: "Active", DisplayValue: "Active", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "Inactive", NameValue: "Inactive", DisplayValue: "Inactive", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "Enable", NameValue: "Enable", DisplayValue: "Enable", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "Create", NameValue: "Create", DisplayValue: "Create", SequenceNumber: 4}),
            new DomainValueDto({ CodeValue: "Activate", NameValue: "Activate", DisplayValue: "Activate", SequenceNumber: 5}),
            new DomainValueDto({ CodeValue: "Deactivate", NameValue: "Deactivate", DisplayValue: "Deactivate", SequenceNumber: 6}),
            new DomainValueDto({ CodeValue: "Delete", NameValue: "Delete", DisplayValue: "Delete", SequenceNumber: 7 })            
        ]
    };
	public static PrimaryMeansOfElimination = {
        Directelimination: "Directelimination",
        Innovativeelimination: "Innovativeelimination",
        Reuserefillmodel: "Reuserefillmodel",
        Reusereturnmodel: "Reusereturn'model",
        Substitutiontomonomaterialplastic: "Substitutiontomono-materialplastic",
        Substitutiontocompostableplastic: "Substitutiontocompostableplastic",
        Substitutiontoanotherplastic: "Substitutiontoanotherplastic",
        Substitutiontopaper: "Substitutiontopaper",
        Substitutiontoaluminiumglass: "Substitutiontoaluminiumglass",
        Substitutiontoanothermaterial: "Substitutiontoanothermaterial",
        Removalofapigmentadditive: "Removalofapigmentadditive",
        Lightweighting: "Lightweighting",
        Othermethodsofelimination: "Othermethodsofelimination",
        Values : [
            new DomainValueDto({ CodeValue: "Directelimination", NameValue: "Direct elimination", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "Innovativeelimination", NameValue: "Innovative elimination", DisplayValue: "", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "Reuserefillmodel", NameValue: "Reuse 'refill' model", DisplayValue: "", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "Reusereturn'model", NameValue: "Reuse 'return' model", DisplayValue: "", SequenceNumber: 4}),
            new DomainValueDto({ CodeValue: "Substitutiontomono-materialplastic", NameValue: "Substitution to mono-material plastic", DisplayValue: "", SequenceNumber: 5}),
            new DomainValueDto({ CodeValue: "Substitutiontocompostableplastic", NameValue: "Substitution to compostable plastic", DisplayValue: "", SequenceNumber: 6}),
            new DomainValueDto({ CodeValue: "Substitutiontoanotherplastic", NameValue: "Substitution to another plastic", DisplayValue: "", SequenceNumber: 7}),
            new DomainValueDto({ CodeValue: "Substitutiontopaper", NameValue: "Substitution to paper", DisplayValue: "", SequenceNumber: 8}),
            new DomainValueDto({ CodeValue: "Substitutiontoaluminiumglass", NameValue: "Substitution to aluminium/glass", DisplayValue: "", SequenceNumber: 9}),
            new DomainValueDto({ CodeValue: "Substitutiontoanothermaterial", NameValue: "Substitution to another material", DisplayValue: "", SequenceNumber: 10}),
            new DomainValueDto({ CodeValue: "Removalofapigmentadditive", NameValue: "Removal of a pigment/additive", DisplayValue: "", SequenceNumber: 11}),
            new DomainValueDto({ CodeValue: "Lightweighting", NameValue: "Lightweighting", DisplayValue: "", SequenceNumber: 12}),
            new DomainValueDto({ CodeValue: "Othermethodsofelimination", NameValue: "Other methods of elimination", DisplayValue: "", SequenceNumber: 13 })            
        ]
    };
	public static ProjectActionStatus = {
        Requested: "Requested",
        Downloaded: "Downloaded",
        Values : [
            new DomainValueDto({ CodeValue: "Requested", NameValue: "Requested", DisplayValue: "Requested", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "Downloaded", NameValue: "Downloaded", DisplayValue: "Downloaded", SequenceNumber: 2 })            
        ]
    };
	public static ProjectImportExportColumns = {
        projectImport: "projectImport",
        projectItemImport: "projectItemImport",
        ARLAssessmentResultImport: "ARLAssessmentResultImport",
        Values : [
            new DomainValueDto({ CodeValue: "projectImport", NameValue: "projectImport", DisplayValue: "projectImport", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "projectItemImport", NameValue: "projectItemImport", DisplayValue: "projectItemImport", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "ARLAssessmentResultImport", NameValue: "ARLAssessmentResultImport", DisplayValue: "ARLAssessmentResultImport", SequenceNumber: 3 })            
        ]
    };
	public static ProjectStatus = {
        Completed: "Completed",
        Incomplete: "Incomplete",
        Error: "Error",
        Values : [
            new DomainValueDto({ CodeValue: "Completed", NameValue: "Completed", DisplayValue: "Completed", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "Incomplete", NameValue: "Incomplete", DisplayValue: "Incomplete", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "Error", NameValue: "Error", DisplayValue: "Error", SequenceNumber: 3 })            
        ]
    };
	public static ScheduledTask = {
        ARLAdministration: "ARLAdministration",
        ARLProject: "ARLProject",
        ARLAssessment: "ARLAssessment",
        ARLDataDownload: "ARLDataDownload",
        OrganisationResources: "OrganisationResources",
        UpdateMemberDb: "UpdateMemberDb",
        SyncEnvMaster: "SyncEnvMaster",
        MailServerIntegration: "MailServerIntegration",
        CollectEmailMessages: "CollectEmailMessage",
        SfEmailIntegrationUpdate: "SfEmailIntegrationUpdate",
        ProcessJobTasks: "ProcessJobTasks",
        ProcessLogs: "ProcessLogs",
        SalesforceMasterDataHistory: "SFMDataHist",
        SalesforceTransactionalDataHistory: "SFTDataHist",
        SalesforceUpstreamUpdate: "SFUpstreamUpdate",
        ExtractCovenantAnnualReport: "ProcessAPCOAnnualReport",
        Values : [
            new DomainValueDto({ CodeValue: "ARLAdministration", NameValue: "ARL Administration", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "ARLProject", NameValue: "ARL Project", DisplayValue: "", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "ARLAssessment", NameValue: "ARL Assessment", DisplayValue: "", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "ARLDataDownload", NameValue: "ARL Data Download", DisplayValue: "", SequenceNumber: 4}),
            new DomainValueDto({ CodeValue: "OrganisationResources", NameValue: "Organisation Resources", DisplayValue: "", SequenceNumber: 10}),
            new DomainValueDto({ CodeValue: "UpdateMemberDb", NameValue: "Update Member Db", DisplayValue: "", SequenceNumber: 12}),
            new DomainValueDto({ CodeValue: "SyncEnvMaster", NameValue: "SyncEnvMaster", DisplayValue: "", SequenceNumber: 13}),
            new DomainValueDto({ CodeValue: "MailServerIntegration", NameValue: "Mail Server Integration", DisplayValue: "Mail Server Integration", SequenceNumber: 30}),
            new DomainValueDto({ CodeValue: "CollectEmailMessage", NameValue: "Collect Email Messages", DisplayValue: "", SequenceNumber: 31}),
            new DomainValueDto({ CodeValue: "SfEmailIntegrationUpdate", NameValue: "SfEmailIntegrationUpdate", DisplayValue: "", SequenceNumber: 32}),
            new DomainValueDto({ CodeValue: "ProcessJobTasks", NameValue: "Process Job Tasks", DisplayValue: "Process Job Tasks", SequenceNumber: 40}),
            new DomainValueDto({ CodeValue: "ProcessLogs", NameValue: "Process Logs", DisplayValue: "", SequenceNumber: 100}),
            new DomainValueDto({ CodeValue: "SFMDataHist", NameValue: "Salesforce Master Data History", DisplayValue: "", SequenceNumber: 110}),
            new DomainValueDto({ CodeValue: "SFTDataHist", NameValue: "Salesforce Transactional Data History", DisplayValue: "", SequenceNumber: 111}),
            new DomainValueDto({ CodeValue: "SFUpstreamUpdate", NameValue: "Salesforce Upstream Update", DisplayValue: "", SequenceNumber: 112}),
            new DomainValueDto({ CodeValue: "ProcessAPCOAnnualReport", NameValue: "Extract Covenant Annual Report", DisplayValue: "", SequenceNumber: 120 })            
        ]
    };
	public static ScheduledTaskAction = {
        Load: "Load",
        Start: "Start",
        Refresh: "Refresh",
        Stop: "Stop",
        Disabled: "Disabled",
        Enabled: "Enabled",
        Values : [
            new DomainValueDto({ CodeValue: "Load", NameValue: "Load", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "Start", NameValue: "Start", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "Refresh", NameValue: "Refresh", DisplayValue: "", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "Stop", NameValue: "Stop", DisplayValue: "", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "Disabled", NameValue: "Disabled", DisplayValue: "", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "Enabled", NameValue: "Enabled", DisplayValue: "", SequenceNumber: 3 })            
        ]
    };
	public static ScheduleOccursEveryType = {
        Hours: "Hrs",
        Minutes: "Mins",
        Seconds: "Secs",
        Values : [
            new DomainValueDto({ CodeValue: "Hrs", NameValue: "Hours", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "Mins", NameValue: "Minutes", DisplayValue: "", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "Secs", NameValue: "Seconds", DisplayValue: "", SequenceNumber: 3 })            
        ]
    };
	public static ScheduleRecurDayOfWeek = {
        Monday: "Mon",
        Tuesday: "Tue",
        Wednesday: "Wed",
        Thursday: "Thu",
        Friday: "Fri",
        Saturday: "Sat",
        Sunday: "Sun",
        Day: "day",
        Weekday: "wkday",
        WeekendDay: "wkendday",
        Values : [
            new DomainValueDto({ CodeValue: "Mon", NameValue: "Monday", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "Tue", NameValue: "Tuesday", DisplayValue: "", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "Wed", NameValue: "Wednesday", DisplayValue: "", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "Thu", NameValue: "Thursday", DisplayValue: "", SequenceNumber: 4}),
            new DomainValueDto({ CodeValue: "Fri", NameValue: "Friday", DisplayValue: "", SequenceNumber: 5}),
            new DomainValueDto({ CodeValue: "Sat", NameValue: "Saturday", DisplayValue: "", SequenceNumber: 6}),
            new DomainValueDto({ CodeValue: "Sun", NameValue: "Sunday", DisplayValue: "", SequenceNumber: 7}),
            new DomainValueDto({ CodeValue: "day", NameValue: "Day", DisplayValue: "", SequenceNumber: 8}),
            new DomainValueDto({ CodeValue: "wkday", NameValue: "Weekday", DisplayValue: "", SequenceNumber: 9}),
            new DomainValueDto({ CodeValue: "wkendday", NameValue: "Weekend Day", DisplayValue: "", SequenceNumber: 10 })            
        ]
    };
	public static ScheduleRecurType = {
        Daily: "Day",
        Weekly: "Wky",
        Monthly: "Mth",
        Values : [
            new DomainValueDto({ CodeValue: "Day", NameValue: "Daily", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "Wky", NameValue: "Weekly", DisplayValue: "", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "Mth", NameValue: "Monthly", DisplayValue: "", SequenceNumber: 3 })            
        ]
    };
	public static ScheduleRecurWeekType = {
        First: "First",
        Second: "Second",
        Third: "Third",
        Fourth: "Fourth",
        Last: "Last",
        Values : [
            new DomainValueDto({ CodeValue: "First", NameValue: "First", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "Second", NameValue: "Second", DisplayValue: "", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "Third", NameValue: "Third", DisplayValue: "", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "Fourth", NameValue: "Fourth", DisplayValue: "", SequenceNumber: 4}),
            new DomainValueDto({ CodeValue: "Last", NameValue: "Last", DisplayValue: "", SequenceNumber: 5 })            
        ]
    };
	public static ScheduleStatusCode = {
        Active: "Active",
        ToExecute: "ToExec",
        Executing: "Execg",
        Executed: "Execd",
        Values : [
            new DomainValueDto({ CodeValue: "Active", NameValue: "Active", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "ToExec", NameValue: "To Execute", DisplayValue: "", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "Execg", NameValue: "Executing", DisplayValue: "", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "Execd", NameValue: "Executed", DisplayValue: "", SequenceNumber: 4 })            
        ]
    };
	public static ScheduleType = {
        OneTime: "OneT",
        Recurring: "Recur",
        Values : [
            new DomainValueDto({ CodeValue: "OneT", NameValue: "One Time", DisplayValue: "One Time", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "Recur", NameValue: "Recurring", DisplayValue: "", SequenceNumber: 2 })            
        ]
    };
	public static SQLConnectionString = {
        DBInstanceName: "DBInstanceName",
        DBName: "DBName",
        Username: "Username",
        Password: "Password",
        TrustServerCertificate: "TrustServerCertificate",
        Values : [
            new DomainValueDto({ CodeValue: "DBInstanceName", NameValue: "DB Instance Name", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "DBName", NameValue: "DB Name", DisplayValue: "", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "Username", NameValue: "Username", DisplayValue: "", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "Password", NameValue: "Password", DisplayValue: "", SequenceNumber: 4}),
            new DomainValueDto({ CodeValue: "TrustServerCertificate", NameValue: "Trust Server Certificate", DisplayValue: "", SequenceNumber: 5 })            
        ]
    };
	public static SystemConfigurations = {
        WebRootPath: "WebRootPath",
        IpInfoUri: "IpInfoUri",
        IpInfoToken: "IpInfoToken",
        BasecampTokenUri: "BasecampTokenUri",
        BasecampUserUri: "BasecampUserUri",
        BasecampClientIdProd: "BasecampClientId_Prod",
        BasecampClientSecretProd: "BasecampClientSecret_Prod",
        BasecampRedirectUriProd: "BasecampRedirectUri_Prod",
        BasecampRedirectAppUriProd: "BasecampRedirectAppUri_Prod",
        BasecampClientIdTest: "BasecampClientId_Test",
        BasecampClientSecretTest: "BasecampClientSecret_Test",
        BasecampRedirectUriTest: "BasecampRedirectUri_Test",
        BasecampRedirectAppUriTest: "BasecampRedirectAppUri_Test",
        BasecampClientIdDev: "BasecampClientId_Dev",
        BasecampClientSecretDev: "BasecampClientSecret_Dev",
        BasecampRedirectUriDev: "BasecampRedirectUri_Dev",
        BasecampRedirectAppUriDev: "BasecampRedirectAppUri_Dev",
        PREPAPIUriProd: "PREP_APIUri_Prod",
        PREPAPIKeyProd: "PREP_APIKey_Prod",
        PREPAPIUriTest: "PREP_APIUri_Test",
        PREPAPIKeyTest: "PREP_APIKey_Test",
        PREPAPIUriDev: "PREP_APIUri_Dev",
        PREPAPIKeyDev: "PREP_APIKey_Dev",
        SalesForceOAuthUriProd: "SalesForceOAuthUri_Prod",
        SalesForceWebhookUriProd: "SalesForceWebhookUri_Prod",
        SalesForceUsernameProd: "SalesForceUsername_Prod",
        SalesForcePasswordProd: "SalesForcePassword_Prod",
        SalesForceClientIdProd: "SalesForceClientId_Prod",
        SalesForceClientSecretProd: "SalesForceClientSecret_Prod",
        SalesForceOAuthUriTest: "SalesForceOAuthUri_Test",
        SalesForceWebhookUriTest: "SalesForceWebhookUri_Test",
        SalesForceUsernameTest: "SalesForceUsername_Test",
        SalesForcePasswordTest: "SalesForcePassword_Test",
        SalesForceClientIdTest: "SalesForceClientId_Test",
        SalesForceClientSecretTest: "SalesForceClientSecret_Test",
        AmazonS3AccessKey: "AmazonS3AccessKey",
        AmazonS3SecretAccessKey: "AmazonS3SecretAccessKey",
        AmazonS3MemberFilesBucket: "AmazonS3MemberFilesBucket",
        MembersNoOfRecordsToProcess: "MembersNoOfRecordsToProcess",
        Values : [
            new DomainValueDto({ CodeValue: "WebRootPath", NameValue: "Web Root Path", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "IpInfoUri", NameValue: "IpInfoUri", DisplayValue: "", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "IpInfoToken", NameValue: "IpInfoToken", DisplayValue: "", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "BasecampTokenUri", NameValue: "BasecampTokenUri", DisplayValue: "", SequenceNumber: 10}),
            new DomainValueDto({ CodeValue: "BasecampUserUri", NameValue: "BasecampUserUri", DisplayValue: "", SequenceNumber: 11}),
            new DomainValueDto({ CodeValue: "BasecampClientId_Prod", NameValue: "BasecampClientId_Prod", DisplayValue: "", SequenceNumber: 12}),
            new DomainValueDto({ CodeValue: "BasecampClientSecret_Prod", NameValue: "BasecampClientSecret_Prod", DisplayValue: "", SequenceNumber: 13}),
            new DomainValueDto({ CodeValue: "BasecampRedirectUri_Prod", NameValue: "BasecampRedirectUri_Prod", DisplayValue: "", SequenceNumber: 14}),
            new DomainValueDto({ CodeValue: "BasecampRedirectAppUri_Prod", NameValue: "BasecampRedirectAppUri_Prod", DisplayValue: "", SequenceNumber: 15}),
            new DomainValueDto({ CodeValue: "BasecampClientId_Test", NameValue: "BasecampClientId_Test", DisplayValue: "", SequenceNumber: 16}),
            new DomainValueDto({ CodeValue: "BasecampClientSecret_Test", NameValue: "BasecampClientSecret_Test", DisplayValue: "", SequenceNumber: 17}),
            new DomainValueDto({ CodeValue: "BasecampRedirectUri_Test", NameValue: "BasecampRedirectUri_Test", DisplayValue: "", SequenceNumber: 18}),
            new DomainValueDto({ CodeValue: "BasecampRedirectAppUri_Test", NameValue: "BasecampRedirectAppUri_Test", DisplayValue: "", SequenceNumber: 19}),
            new DomainValueDto({ CodeValue: "BasecampClientId_Dev", NameValue: "BasecampClientId_Dev", DisplayValue: "", SequenceNumber: 20}),
            new DomainValueDto({ CodeValue: "BasecampClientSecret_Dev", NameValue: "BasecampClientSecret_Dev", DisplayValue: "", SequenceNumber: 21}),
            new DomainValueDto({ CodeValue: "BasecampRedirectUri_Dev", NameValue: "BasecampRedirectUri_Dev", DisplayValue: "", SequenceNumber: 22}),
            new DomainValueDto({ CodeValue: "BasecampRedirectAppUri_Dev", NameValue: "BasecampRedirectAppUri_Dev", DisplayValue: "", SequenceNumber: 23}),
            new DomainValueDto({ CodeValue: "PREP_APIUri_Prod", NameValue: "PREP_APIUri_Prod", DisplayValue: "", SequenceNumber: 30}),
            new DomainValueDto({ CodeValue: "PREP_APIKey_Prod", NameValue: "PREP_APIKey_Prod", DisplayValue: "", SequenceNumber: 31}),
            new DomainValueDto({ CodeValue: "PREP_APIUri_Test", NameValue: "PREP_APIUri_Test", DisplayValue: "", SequenceNumber: 32}),
            new DomainValueDto({ CodeValue: "PREP_APIKey_Test", NameValue: "PREP_APIKey_Test", DisplayValue: "", SequenceNumber: 33}),
            new DomainValueDto({ CodeValue: "PREP_APIUri_Dev", NameValue: "PREP_APIUri_Dev", DisplayValue: "", SequenceNumber: 34}),
            new DomainValueDto({ CodeValue: "PREP_APIKey_Dev", NameValue: "PREP_APIKey_Dev", DisplayValue: "", SequenceNumber: 35}),
            new DomainValueDto({ CodeValue: "SalesForceOAuthUri_Prod", NameValue: "SalesForceOAuthUri_Prod", DisplayValue: "", SequenceNumber: 40}),
            new DomainValueDto({ CodeValue: "SalesForceWebhookUri_Prod", NameValue: "SalesForceWebhookUri_Prod", DisplayValue: "", SequenceNumber: 41}),
            new DomainValueDto({ CodeValue: "SalesForceUsername_Prod", NameValue: "SalesForceUsername_Prod", DisplayValue: "", SequenceNumber: 42}),
            new DomainValueDto({ CodeValue: "SalesForcePassword_Prod", NameValue: "SalesForcePassword_Prod", DisplayValue: "", SequenceNumber: 43}),
            new DomainValueDto({ CodeValue: "SalesForceClientId_Prod", NameValue: "SalesForceClientId_Prod", DisplayValue: "", SequenceNumber: 44}),
            new DomainValueDto({ CodeValue: "SalesForceClientSecret_Prod", NameValue: "SalesForceClientSecret_Prod", DisplayValue: "", SequenceNumber: 45}),
            new DomainValueDto({ CodeValue: "SalesForceOAuthUri_Test", NameValue: "SalesForceOAuthUri_Test", DisplayValue: "", SequenceNumber: 46}),
            new DomainValueDto({ CodeValue: "SalesForceWebhookUri_Test", NameValue: "SalesForceWebhookUri_Test", DisplayValue: "", SequenceNumber: 47}),
            new DomainValueDto({ CodeValue: "SalesForceUsername_Test", NameValue: "SalesForceUsername_Test", DisplayValue: "", SequenceNumber: 48}),
            new DomainValueDto({ CodeValue: "SalesForcePassword_Test", NameValue: "SalesForcePassword_Test", DisplayValue: "", SequenceNumber: 49}),
            new DomainValueDto({ CodeValue: "SalesForceClientId_Test", NameValue: "SalesForceClientId_Test", DisplayValue: "", SequenceNumber: 50}),
            new DomainValueDto({ CodeValue: "SalesForceClientSecret_Test", NameValue: "SalesForceClientSecret_Test", DisplayValue: "", SequenceNumber: 51}),
            new DomainValueDto({ CodeValue: "AmazonS3AccessKey", NameValue: "AmazonS3AccessKey", DisplayValue: "", SequenceNumber: 52}),
            new DomainValueDto({ CodeValue: "AmazonS3SecretAccessKey", NameValue: "AmazonS3SecretAccessKey", DisplayValue: "", SequenceNumber: 53}),
            new DomainValueDto({ CodeValue: "AmazonS3MemberFilesBucket", NameValue: "AmazonS3MemberFilesBucket", DisplayValue: "", SequenceNumber: 54}),
            new DomainValueDto({ CodeValue: "MembersNoOfRecordsToProcess", NameValue: "MembersNoOfRecordsToProcess", DisplayValue: "", SequenceNumber: 55 })            
        ]
    };
	public static SystemDatabaseType = {
        SQLServer: "SQLServer",
        AzureSQLServer: "AzureSQLServer",
        Values : [
            new DomainValueDto({ CodeValue: "SQLServer", NameValue: "SQL Server", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "AzureSQLServer", NameValue: "Azure SQL Server", DisplayValue: "", SequenceNumber: 2 })            
        ]
    };
	public static SystemDataIntegrations = {
        CSVMemberProjectsExport: "CSVMemberProjectsExport",
        CSVMemberProjectItemsExport: "CSVMemberProjectItemsExport",
        CSVMemberARLAssessmentResultsExport: "CSVMemberARLAssessmentResultsExport",
        CSVProjectsExport: "CSVProjectsExport",
        CSVProjectItemsExport: "CSVProjectItemsExport",
        CSVARLAssessmentResultsExport: "CSVARLAssessmentResultsExport",
        Values : [
            new DomainValueDto({ CodeValue: "CSVMemberProjectsExport", NameValue: "CSVMemberProjectsExport", DisplayValue: "CSVMemberProjectsExport", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "CSVMemberProjectItemsExport", NameValue: "CSVMemberProjectItemsExport", DisplayValue: "CSVMemberProjectItemsExport", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "CSVMemberARLAssessmentResultsExport", NameValue: "CSVMemberARLAssessmentResultsExport", DisplayValue: "CSVMemberARLAssessmentResultsExport", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "CSVProjectsExport", NameValue: "CSVProjectsExport", DisplayValue: "CSVProjectsExport", SequenceNumber: 4}),
            new DomainValueDto({ CodeValue: "CSVProjectItemsExport", NameValue: "CSVProjectItemsExport", DisplayValue: "CSVProjectItemsExport", SequenceNumber: 5}),
            new DomainValueDto({ CodeValue: "CSVARLAssessmentResultsExport", NameValue: "CSVARLAssessmentResultsExport", DisplayValue: "CSVARLAssessmentResultsExport", SequenceNumber: 6 })            
        ]
    };
	public static SystemEnvironment = {
        Production: "Prod",
        Test: "Test",
        Development: "Dev",
        Values : [
            new DomainValueDto({ CodeValue: "Prod", NameValue: "Production", DisplayValue: "Production", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "Test", NameValue: "Test", DisplayValue: "Test", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "Dev", NameValue: "Development", DisplayValue: "Development", SequenceNumber: 3 })            
        ]
    };
	public static SystemModelType = {
        Physical: "Physical",
        PhysicalExtension: "PhysicalExt",
        PhysicalExtensionLongText: "PhysicalExtLongText",
        Dynamic: "Dynamic",
        Dto: "Dto",
        Values : [
            new DomainValueDto({ CodeValue: "Physical", NameValue: "Physical", DisplayValue: "Physical", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "PhysicalExt", NameValue: "Physical Extension", DisplayValue: "Physical Extension", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "PhysicalExtLongText", NameValue: "Physical Extension Long Text", DisplayValue: "Physical Extension Long Text", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "Dynamic", NameValue: "Dynamic", DisplayValue: "Dynamic", SequenceNumber: 4}),
            new DomainValueDto({ CodeValue: "Dto", NameValue: "Dto", DisplayValue: "Dto", SequenceNumber: 5 })            
        ]
    };
	public static SystemResourceType = {
        Database: "Database",
        FileStore: "FileStore",
        EmailAccount: "EmailAccount",
        Values : [
            new DomainValueDto({ CodeValue: "Database", NameValue: "Database", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "FileStore", NameValue: "FileStore", DisplayValue: "", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "EmailAccount", NameValue: "EmailAccount", DisplayValue: "", SequenceNumber: 3 })            
        ]
    };
	public static SystemStorageType = {
        UNCPath: "UNCPath",
        AzureBlobStorage: "AzureBlobStorage",
        AmazonS3: "AmazonS3",
        Values : [
            new DomainValueDto({ CodeValue: "UNCPath", NameValue: "UNC Path", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "AzureBlobStorage", NameValue: "Azure Blob Storage", DisplayValue: "", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "AmazonS3", NameValue: "Amazon S3", DisplayValue: "", SequenceNumber: 3 })            
        ]
    };
	public static UOMArea = {
        SquareMillimeters: "sqmm",
        SquareMicrometers: "sqµm",
        SquareCentimeters: "sqcm",
        SquareMeters: "sqm",
        Values : [
            new DomainValueDto({ CodeValue: "sqmm", NameValue: "Square Millimeters", DisplayValue: "sqmm", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "sqµm", NameValue: "Square Micrometers", DisplayValue: "sqµm", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "sqcm", NameValue: "Square Centimeters", DisplayValue: "sqcm", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "sqm", NameValue: "Square Meters", DisplayValue: "sqm", SequenceNumber: 4 })            
        ]
    };
	public static UOMCapacity = {
        Millilitres: "ml",
        Litre: "ltr",
        Values : [
            new DomainValueDto({ CodeValue: "ml", NameValue: "Millilitres", DisplayValue: "ml", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "ltr", NameValue: "Litre", DisplayValue: "ltr", SequenceNumber: 2 })            
        ]
    };
	public static UOMLength = {
        Millimeters: "mm",
        Micrometers: "µm",
        Centimeters: "cm",
        Meters: "m",
        Values : [
            new DomainValueDto({ CodeValue: "mm", NameValue: "Millimeters", DisplayValue: "mm", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "µm", NameValue: "Micrometers", DisplayValue: "µm", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "cm", NameValue: "Centimeters", DisplayValue: "cm", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "m", NameValue: "Meters", DisplayValue: "m", SequenceNumber: 4 })            
        ]
    };
	public static UOMWeight = {
        grams: "g",
        milligrams: "mg",
        kilograms: "kg",
        Values : [
            new DomainValueDto({ CodeValue: "g", NameValue: "grams", DisplayValue: "g", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "mg", NameValue: "milligrams", DisplayValue: "mg", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "kg", NameValue: "kilograms", DisplayValue: "kg", SequenceNumber: 3 })            
        ]
    };
	public static UserRole = {
        Administrator: "ADMIN",
        ApplicationUser: "APP",
        Public: "AU",
        ScheduledTaskUser: "SCHUSER",
        SystemAdministrator: "SA",
        SupportUser: "SUPP",
        User: "USER",
        Values : [
            new DomainValueDto({ CodeValue: "ADMIN", NameValue: "Administrator", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "APP", NameValue: "Application User", DisplayValue: "", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "AU", NameValue: "Public", DisplayValue: "", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "SCHUSER", NameValue: "Scheduled Task User", DisplayValue: "", SequenceNumber: 4}),
            new DomainValueDto({ CodeValue: "SA", NameValue: "System Administrator", DisplayValue: "", SequenceNumber: 4}),
            new DomainValueDto({ CodeValue: "SUPP", NameValue: "Support User", DisplayValue: "", SequenceNumber: 5}),
            new DomainValueDto({ CodeValue: "USER", NameValue: "User", DisplayValue: "", SequenceNumber: 5 })            
        ]
    };
	public static UserRoleType = {
        APCOSystem: "APCOSystem",
        ARLMSystem: "ARLMSystem",
        MemberSystem: "MemberSystem",
        PREPSystem: "PREPSystem",
        Salesforce: "Salesforce",
        ARLSystem: "ARLSystem",
        ANZPACSystem: "ANZPACSystem",
        Values : [
            new DomainValueDto({ CodeValue: "APCOSystem", NameValue: "APCO System", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "ARLMSystem", NameValue: "ARLM System", DisplayValue: "", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "MemberSystem", NameValue: "Member System", DisplayValue: "", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "PREPSystem", NameValue: "PREP System", DisplayValue: "", SequenceNumber: 4}),
            new DomainValueDto({ CodeValue: "Salesforce", NameValue: "Salesforce", DisplayValue: "", SequenceNumber: 5}),
            new DomainValueDto({ CodeValue: "ARLSystem", NameValue: "ARL System", DisplayValue: "", SequenceNumber: 6}),
            new DomainValueDto({ CodeValue: "ANZPACSystem", NameValue: "ANZPAC System", DisplayValue: "", SequenceNumber: 7 })            
        ]
    };
	public static WProgramMaterialType = {
        Flexibleplastics: "FlexiblePlastics",
        Rigidplastics: "RigidPlastics",
        Fibre: "Fibre",
        Glass: "Glass",
        Metals: "Metals",
        Labels: "Labels",
        Allmaterials: "AllMaterials",
        Values : [
            new DomainValueDto({ CodeValue: "FlexiblePlastics", NameValue: "Flexible plastics", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "RigidPlastics", NameValue: "Rigid plastics", DisplayValue: "", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "Fibre", NameValue: "Fibre", DisplayValue: "", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "Glass", NameValue: "Glass", DisplayValue: "", SequenceNumber: 4}),
            new DomainValueDto({ CodeValue: "Metals", NameValue: "Metals", DisplayValue: "", SequenceNumber: 5}),
            new DomainValueDto({ CodeValue: "Labels", NameValue: "Labels", DisplayValue: "", SequenceNumber: 6}),
            new DomainValueDto({ CodeValue: "AllMaterials", NameValue: "All materials", DisplayValue: "", SequenceNumber: 7 })            
        ]
    };
	public static WProgramRegion = {
        AUSNZ: "AUSNZ",
        AUS: "AUS",
        NZ: "NZ",
        Values : [
            new DomainValueDto({ CodeValue: "AUSNZ", NameValue: "AUS,NZ", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "AUS", NameValue: "AUS", DisplayValue: "", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "NZ", NameValue: "NZ", DisplayValue: "", SequenceNumber: 3 })            
        ]
    };
	public static WProgramStatus = {
        Inprogress: "InProgress",
        Upcoming: "Upcoming",
        Closed: "Closed",
        Approved: "Approved",
        Values : [
            new DomainValueDto({ CodeValue: "InProgress", NameValue: "In progress", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "Upcoming", NameValue: "Upcoming", DisplayValue: "Upcoming", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "Closed", NameValue: "Closed", DisplayValue: "", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "Approved", NameValue: "Approved", DisplayValue: "", SequenceNumber: 4 })            
        ]
    };
	public static WSubmissionStatus = {
        Openforpubliccomment: "OpenForPublicComment",
        PendingCommitteereview: "PendingCommitteeReview",
        Approved: "Approved",
        Withdrawn: "Withdrawn",
        Onhold: "Onhold",
        Values : [
            new DomainValueDto({ CodeValue: "OpenForPublicComment", NameValue: "Open for public comment", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "PendingCommitteeReview", NameValue: "Pending Committee review", DisplayValue: "", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "Approved", NameValue: "Approved", DisplayValue: "", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "Withdrawn", NameValue: "Withdrawn", DisplayValue: "", SequenceNumber: 4}),
            new DomainValueDto({ CodeValue: "Onhold", NameValue: "On hold", DisplayValue: "", SequenceNumber: 5 })            
        ]
    };
	public static WSubmissionType = {
        Thresholdupdate: "Thresholdupdate",
        RecyclabilityEvaluationSubmission: "RecyclabilityEvaluationSubmission",
        ConsumerBehaviourSubmission: "ConsumerBehaviourSubmission",
        AlternativeDestinationSubmission: "AlternativeDestinationSubmission",
        SoftPlasticsSubmission: "SoftPlasticsSubmission",
        FibreSubmission: "FibreSubmission",
        Recyclabilityclassification: "RecyclabilityClassification",
        Programupdate: "ProgramUpdate",
        Values : [
            new DomainValueDto({ CodeValue: "Thresholdupdate", NameValue: "Threshold update", DisplayValue: "", SequenceNumber: 1}),
            new DomainValueDto({ CodeValue: "RecyclabilityEvaluationSubmission", NameValue: "Recyclability Evaluation Submission", DisplayValue: "", SequenceNumber: 2}),
            new DomainValueDto({ CodeValue: "ConsumerBehaviourSubmission", NameValue: "Consumer Behaviour Submission", DisplayValue: "", SequenceNumber: 3}),
            new DomainValueDto({ CodeValue: "AlternativeDestinationSubmission", NameValue: "Alternative Destination Submission", DisplayValue: "", SequenceNumber: 4}),
            new DomainValueDto({ CodeValue: "SoftPlasticsSubmission", NameValue: "Soft Plastics Submission", DisplayValue: "", SequenceNumber: 5}),
            new DomainValueDto({ CodeValue: "FibreSubmission", NameValue: "Fibre Submission", DisplayValue: "", SequenceNumber: 6}),
            new DomainValueDto({ CodeValue: "RecyclabilityClassification", NameValue: "Recyclability classification", DisplayValue: "", SequenceNumber: 7}),
            new DomainValueDto({ CodeValue: "ProgramUpdate", NameValue: "Program update", DisplayValue: "", SequenceNumber: 8 })            
        ]
    };
}
