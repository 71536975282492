import { BaseDto } from "../baseDto"
/* ExtLngTbl01BaseDto generated at 2025-01-13 11:53:04.733 +11:00*/
export class ExtLngTbl01BaseDto extends BaseDto {
	ExtRecordId?: string;
	UUIDFld001?: string;
	UUIDFld002?: string;
	UUIDFld003?: string;
	UUIDFld004?: string;
	UUIDFld005?: string;
	UUIDFld006?: string;
	UUIDFld007?: string;
	UUIDFld008?: string;
	UUIDFld009?: string;
	UUIDFld010?: string;
	UUIDFld011?: string;
	UUIDFld012?: string;
	UUIDFld013?: string;
	UUIDFld014?: string;
	UUIDFld015?: string;
	UUIDFld016?: string;
	UUIDFld017?: string;
	UUIDFld018?: string;
	UUIDFld019?: string;
	UUIDFld020?: string;
	UUIDFld021?: string;
	UUIDFld022?: string;
	UUIDFld023?: string;
	UUIDFld024?: string;
	UUIDFld025?: string;
	UUIDFld026?: string;
	UUIDFld027?: string;
	UUIDFld028?: string;
	UUIDFld029?: string;
	UUIDFld030?: string;
	UUIDFld031?: string;
	UUIDFld032?: string;
	UUIDFld033?: string;
	UUIDFld034?: string;
	UUIDFld035?: string;
	UUIDFld036?: string;
	UUIDFld037?: string;
	UUIDFld038?: string;
	UUIDFld039?: string;
	UUIDFld040?: string;
	UUIDFld041?: string;
	UUIDFld042?: string;
	UUIDFld043?: string;
	UUIDFld044?: string;
	UUIDFld045?: string;
	UUIDFld046?: string;
	UUIDFld047?: string;
	UUIDFld048?: string;
	UUIDFld049?: string;
	UUIDFld050?: string;
	TxtLFld001?: string;
	TxtLFld002?: string;
	TxtLFld003?: string;
	TxtLFld004?: string;
	TxtLFld005?: string;
	TxtLFld006?: string;
	TxtLFld007?: string;
	TxtLFld008?: string;
	TxtLFld009?: string;
	TxtLFld010?: string;
	TxtLFld011?: string;
	TxtLFld012?: string;
	TxtLFld013?: string;
	TxtLFld014?: string;
	TxtLFld015?: string;
	TxtLFld016?: string;
	TxtLFld017?: string;
	TxtLFld018?: string;
	TxtLFld019?: string;
	TxtLFld020?: string;
	TxtLFld021?: string;
	TxtLFld022?: string;
	TxtLFld023?: string;
	TxtLFld024?: string;
	TxtLFld025?: string;
	TxtLFld026?: string;
	TxtLFld027?: string;
	TxtLFld028?: string;
	TxtLFld029?: string;
	TxtLFld030?: string;
	TxtLFld031?: string;
	TxtLFld032?: string;
	TxtLFld033?: string;
	TxtLFld034?: string;
	TxtLFld035?: string;
	TxtLFld036?: string;
	TxtLFld037?: string;
	TxtLFld038?: string;
	TxtLFld039?: string;
	TxtLFld040?: string;
	TxtLFld041?: string;
	TxtLFld042?: string;
	TxtLFld043?: string;
	TxtLFld044?: string;
	TxtLFld045?: string;
	TxtLFld046?: string;
	TxtLFld047?: string;
	TxtLFld048?: string;
	TxtLFld049?: string;
	TxtLFld050?: string;
	TxtMaxFld001?: string;
	TxtMaxFld002?: string;
	TxtMaxFld003?: string;
	TxtMaxFld004?: string;
	TxtMaxFld005?: string;
	TxtMaxFld006?: string;
	TxtMaxFld007?: string;
	TxtMaxFld008?: string;
	TxtMaxFld009?: string;
	TxtMaxFld010?: string;
	TxtMaxFld011?: string;
	TxtMaxFld012?: string;
	TxtMaxFld013?: string;
	TxtMaxFld014?: string;
	TxtMaxFld015?: string;
	TxtMaxFld016?: string;
	TxtMaxFld017?: string;
	TxtMaxFld018?: string;
	TxtMaxFld019?: string;
	TxtMaxFld020?: string;
	TxtMaxFld021?: string;
	TxtMaxFld022?: string;
	TxtMaxFld023?: string;
	TxtMaxFld024?: string;
	TxtMaxFld025?: string;
	TxtMaxFld026?: string;
	TxtMaxFld027?: string;
	TxtMaxFld028?: string;
	TxtMaxFld029?: string;
	TxtMaxFld030?: string;
}
