import { BaseDto } from "../baseDto"
/* ContactBaseDto generated at 2025-01-13 11:53:04.690 +11:00*/
export class ContactBaseDto extends BaseDto {
	ContactId?: string;
	UserId?: string;
	SF_ContactId?: string;
	ARLM_UserId?: string;
	FirstName?: string;
	LastName?: string;
	Email?: string;
	CompanyPosition?: string;
	StatusCode?: string;
}
