import { BaseDto } from "../baseDto"
/* AuthorisationLogBaseDto generated at 2025-01-13 11:53:04.685 +11:00*/
export class AuthorisationLogBaseDto extends BaseDto {
	AuthorisationLogId?: string;
	UserId?: string;
	IpAddress?: string;
	Hostname?: string;
	City?: string;
	Region?: string;
	CountryCode?: string;
	PostCode?: string;
	TimezoneCode?: string;
	TimezoneName?: string;
	DeviceId?: string;
	IsDevice?: boolean;
	AuthType?: string;
	AuthCode?: string;
	AccessToken?: string;
	RefreshToken?: string;
	DateTokenExpire?: Date;
	DateLastSignedIn?: Date;
	StatusCode?: string;
}
