import { BaseDto } from "../baseDto"
/* APCOAnnualReportBaseDto generated at 2025-01-13 11:53:04.623 +11:00*/
export class APCOAnnualReportBaseDto extends BaseDto {
	APCOAnnualReportId?: string;
	OrganisationId?: string;
	SubmissionYear?: string;
	DomainTypeName?: string;
	FileURL?: string;
	ReportingPeriod?: string;
	TextData?: string;
	LastErrorMessage?: string;
	StatusCode?: string;
}
