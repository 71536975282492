import { BaseDto } from "../baseDto"
/* SystemDataIntegrationBaseDto generated at 2025-01-13 11:53:05.090 +11:00*/
export class SystemDataIntegrationBaseDto extends BaseDto {
	SystemDataIntegrationId?: string;
	IntegrationName?: string;
	IntegrationDescription?: string;
	IsInbound?: boolean;
	IsOutbound?: boolean;
	IsFile?: boolean;
	IsFirstRowHeader?: boolean;
	IsAPI?: boolean;
	ExcludedFields?: string;
	FileTypeCode?: string;
	SystemModelId?: string;
	StatusCode?: string;
}
