import { BaseDto } from "../baseDto"
/* SystemModelRelationshipBaseDto generated at 2025-01-13 11:53:05.099 +11:00*/
export class SystemModelRelationshipBaseDto extends BaseDto {
	SystemModelRelationshipId?: string;
	SystemModelId?: string;
	IsExtensionModel?: boolean;
	ForeignSystemModelId?: string;
	ForeignSystemModelFieldId?: string;
	OrgDbVersion?: number;
	StatusCode?: string;
}
