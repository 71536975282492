import { BaseDto } from "../baseDto"
/* ProductSKUBaseDto generated at 2025-01-13 11:53:04.907 +11:00*/
export class ProductSKUBaseDto extends BaseDto {
	ProductSKUId?: string;
	ProductId?: string;
	ProductVersionNo?: number;
	Sequence?: number;
	PartNo?: string;
	SKU?: string;
	UPC?: string;
	MOQ?: number;
	SKUDescription?: string;
	Org_ProductId?: string;
	Org_ProductSKUId?: string;
	StatusCode?: string;
}
