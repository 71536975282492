import { BaseDto } from "../baseDto"
/* PageContentBaseDto generated at 2025-01-13 11:53:04.857 +11:00*/
export class PageContentBaseDto extends BaseDto {
	PageContentId?: string;
	PageContentSetId?: string;
	PageTitle?: string;
	ContentType?: string;
	ModuleName?: string;
	ContentCode?: string;
	ContentName?: string;
	ContentDescription?: string;
	ContentValue?: string;
	PublishedContent?: string;
	PreviousContent?: string;
	DateActiveFrom?: Date;
	LevelNo?: number;
	SequenceNo?: number;
	ParentPageContentId?: string;
	StatusCode?: string;
}
