import { BaseDto } from "../baseDto"
/* WARLResourceBaseDto generated at 2025-01-13 11:53:05.140 +11:00*/
export class WARLResourceBaseDto extends BaseDto {
	WebARLResourceId?: string;
	ResourceName?: string;
	ResourceDescription?: string;
	ImageURL?: string;
	ResourceURL?: string;
	Sequence?: number;
	StatusCode?: string;
}
