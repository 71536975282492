import { BaseDto } from "../baseDto"
/* JobTaskBaseDto generated at 2025-01-13 11:53:04.797 +11:00*/
export class JobTaskBaseDto extends BaseDto {
	JobTaskId?: string;
	TaskTypeCode?: string;
	EnvironmentCode?: string;
	OrganisationId?: string;
	UserId?: string;
	DateRequested?: Date;
	DateCompleted?: Date;
	ProcessStartDate?: Date;
	ProcessEndDate?: Date;
	ReferenceId1?: string;
	ReferenceId2?: string;
	ReferenceId3?: string;
	ReferenceVersionNo?: number;
	ExternalReferenceId1?: string;
	ExternalReferenceId2?: string;
	ExternalReferenceId3?: string;
	InterfaceReferenceId1?: string;
	InterfaceReferenceId2?: string;
	InterfaceReferenceId3?: string;
	InterfaceRequestJSON?: string;
	InterfaceResponseJSON?: string;
	RequestJSON?: string;
	ResponseJSON?: string;
	ResponseURL?: string;
	WorkingJSON?: string;
	ExceptionJSON?: string;
	RetryCount?: number;
	StatusCode?: string;
}
