import { BaseDto } from "../baseDto"
/* IpAddressLocationBaseDto generated at 2025-01-13 11:53:04.781 +11:00*/
export class IpAddressLocationBaseDto extends BaseDto {
	IpAddressLocationId?: string;
	IpAddress?: string;
	Hostname?: string;
	City?: string;
	Region?: string;
	PostCode?: string;
	CountryCode?: string;
	TimezoneCode?: string;
	TimezoneName?: string;
	DateLastRefreshed?: Date;
	DateLastSeen?: Date;
}
